import {
  Box,
  Button,
  Dialog,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography
} from '@mui/material';
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import { lowercaseKeys } from '../../utils/hermitConfigUtils';
import { CommitConfigForm } from './CommitConfigForm';
import { Edit, Download, ContentCopy } from "@mui/icons-material";

export const JobSpecForm: React.FC<any> = ({ hermitConf, apis, environments, analyzers, environmentName, apiName, hasError, source, cloneLink }: any) => {
  const history = useHistory();
  const [dialogOpen, setDialogOpen] = useState(false);

  if (!hermitConf || !Object.keys(hermitConf).length || hasError) {
    return (<></>);
  }

  const handleDialogClose = () => {
    setDialogOpen(false);
  }

  const hermitConfig = lowercaseKeys(hermitConf); // inconsistent case may come from Sift

  const hermitTarget = lowercaseKeys(hermitConfig.targets[0]);

  const configVersion = hermitConfig?.version || 1;
  
  const analyzerSet = (configVersion === 1)
    ? hermitConfig.analysis.analyzers.map((analyzer: any) => typeof analyzer === 'object' ? analyzer.kind : analyzer)
    : hermitConfig.testprofile.analyzers.map((analyzer: any) => analyzer.kind);
  
  const analyzerList = analyzers.filter((analyzer: any) => analyzerSet.includes(analyzer.name)).map((analyzer: any) => analyzer.displayName);

  console.log({id: hermitConfig.environmentid}, environments);
  return (<>
    <Grid
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
      >
        <TableContainer component={Paper}>
          <Table size='small'>
            <TableBody>

              {(hermitConfig.labels && hermitConfig.labels.jobspec) && (
                <TableRow
                  key={'Name'}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Typography variant="overline" sx={{ fontSize: "12px" }} color="text.secondary">Name</Typography>
                  </TableCell>
                  <TableCell>{hermitConfig.labels.jobspec}</TableCell>
                </TableRow>
              )}

              {(hermitTarget.api) && (
                <TableRow
                  key={'API'}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Typography variant="overline" sx={{ fontSize: "12px" }} color="text.secondary">API</Typography>
                  </TableCell>
                  <TableCell>{apiName || apis.filter((api: any) => api.id === hermitTarget.api).map((api: any) => api.name)[0] || hermitTarget.api}</TableCell>
                </TableRow>
              )}

              {(hermitTarget.api) && (
                <TableRow
                  key={'TargetURL'}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Typography variant="overline" sx={{ fontSize: "12px" }} color="text.secondary">Target URL</Typography>
                  </TableCell>
                  <TableCell>{hermitTarget.url}</TableCell>
                </TableRow>
              )}

              {(hermitTarget.definition && !hermitTarget.definition.revision) && (
                <TableRow
                  key={'OpenAPIDefinition'}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Typography variant="overline" sx={{ fontSize: "12px" }} color="text.secondary">OpenAPI definition</Typography>
                  </TableCell>
                  <TableCell>{hermitTarget.definition ? (hermitTarget.definition.file || hermitTarget.definition.path || '') : '' }</TableCell>
                </TableRow>
              )}

              {(hermitTarget.definition && hermitTarget.definition.revision) && (
                <TableRow
                  key={'OpenAPIRevision'}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Typography variant="overline" sx={{ fontSize: "12px" }} color="text.secondary">OpenAPI definition revision</Typography>
                  </TableCell>
                  <TableCell>{hermitTarget.definition.revision}</TableCell>
                </TableRow>
              )}

              {(hermitConfig.environmentid && environmentName) && (
                <TableRow 
                  key={'Environment'}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Typography variant="overline" sx={{ fontSize: "12px" }} color="text.secondary">Environment</Typography>
                  </TableCell>
                  <TableCell>{environmentName}</TableCell>
                </TableRow>
              )}

              {(hermitTarget.traceheader) && (
                <TableRow 
                  key={'TraceHeader'}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    <Typography variant="overline" sx={{ fontSize: "12px" }} color="text.secondary">Trace header</Typography>
                  </TableCell>
                  <TableCell>{hermitTarget.traceheader}</TableCell>
                </TableRow>
              )}

              {analyzerList.map((analyzer: any, idx: number) => (
                <TableRow
                  key={analyzer}
                  sx={{ border: 0 }}
                > 
                  {(idx === 0) && (
                  <TableCell component="th" scope="row" sx={{ borderBottom: 0 }}>
                    <Typography variant="overline" sx={{ fontSize: "12px" }} color="text.secondary">Analyzers</Typography>
                  </TableCell>
                  )}
                  {(idx === 1) && (
                  <TableCell rowSpan={analyzerList.length - 1} component="td" scope="row" />
                  )}
                  <TableCell>{analyzer}</TableCell>
                </TableRow>
              ))}

              {hermitTarget.operations && hermitTarget.operations.includeid && hermitTarget.operations.includeid.map((operation: any, idx: number) => (
                <TableRow
                  key={`include-${operation}`}
                > 
                  {(idx === 0) && (
                  <TableCell component="th" scope="row" sx={{ borderBottom: 0 }}>
                    <Typography variant="overline" sx={{ fontSize: "12px" }} color="text.secondary">Included operations</Typography>
                  </TableCell>
                  )}
                  {(idx === 1) && (
                  <TableCell rowSpan={hermitTarget.operations.includeid.length - 1} component="td" scope="row" />
                  )}
                  <TableCell>{operation}</TableCell>
                </TableRow>
              ))}

              {hermitTarget.operations && hermitTarget.operations.excludeid && hermitTarget.operations.excludeid.map((operation: any, idx: number) => (
                <TableRow
                key={`exclude-${operation}`}
                > 
                  {(idx === 0) && (
                  <TableCell component="th" scope="row" sx={{ borderBottom: 0 }}>
                    <Typography variant="overline" sx={{ fontSize: "12px" }} color="text.secondary">Excluded operations</Typography>
                  </TableCell>
                  )}
                  {(idx === 1) && (
                  <TableCell rowSpan={hermitTarget.operations.excludeid.length - 1} component="td" scope="row" />
                  )}
                  <TableCell>{operation}</TableCell>
                </TableRow>
              ))}

            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      
    </Grid>
    <Dialog fullWidth maxWidth="md" onClose={handleDialogClose} open={dialogOpen}>
        <Box sx={{ m: 3 }}>
          <CommitConfigForm hermitConf={hermitConf} configId={cloneLink} onSubmit={handleDialogClose} />
        </Box>
    </Dialog>
  </>)
}
