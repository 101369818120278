import create from "zustand";
import { devtools } from "zustand/middleware";


export interface IAppState {
    globalError: string | null
    setGlobalError: (text: string) => void
    overrideBreadcrumb: any
    setOverrideBreadcrumb: (data: any) => void
    breadcrumb: any
    setBreadcrumb: (data: any) => void
    context: any
    setContext: (data: any) => void
    clearGlobalError: () => void
}

export const useAppState = create(devtools(set => ({
    globalError: null,
    setGlobalError: (text: string) => {
        set((state: IAppState) => {
            return { ...state, globalError: text }
        })
    },
    overrideBreadcrumb: { group:null, project: null },
    setOverrideBreadcrumb: (data: any) => {
        set((state: IAppState) => {
            
            let newBreadcrumb:any = {}   
            newBreadcrumb.project = data?.project||null
            newBreadcrumb.group = data?.group||null
            return { ...state, overrideBreadcrumb: { ...newBreadcrumb } }
        })
    },
    breadcrumb: { group:null, project: null },
    setBreadcrumb: (data: any) => {
        set((state: IAppState) => {
            
            let newBreadcrumb:any = {  }   
            newBreadcrumb.project = data?.project||null
            newBreadcrumb.group = data?.group||null
            return { ...state, breadcrumb: { ...newBreadcrumb } }
        })
    },
    context: { project: null, service: null, api: null },
    setContext: (data: any) => {
        set((state: IAppState) => {
            let newContext = { ...state.context }
            if (data?.project) {
                newContext.service = null
                newContext.api = null
            }
            if (data?.service) {
                newContext.api = null
            }
            return { ...state, context: { ...newContext, ...data } }
        })
    },
    filtersToHide: [],
    setFiltersToHide: (data: string[]) => {
        set((state: IAppState) => {
            return { ...state, filtersToHide: data }
        })
    },
    clearGlobalError: () => {
        set((state: IAppState) => {
            return { ...state, globalError: null }
        })
    },

} as IAppState)))