import { PlayCircle } from "@mui/icons-material";
import {
  Box, Chip
} from "@mui/material";
import React from "react";
import { withRouter } from "react-router-dom";
import usePermissionChecker from "../../hooks/process/usePermissionChecker";
import { PermissionTargets, PermissionTypes } from "../../store/userState";
import ModalWrapper from "../common/modal/wrapper";
import PermissionBlocker from "../common/permissionBlocker";
import ConfigTable from "../jobs/ListConfigs/ConfigTable";

const ConfigTablePopup: React.FC<any> = ({ assetId, history, sx, defaultProjectID = undefined, trigger = undefined }) => {
  const { hasPermission } = usePermissionChecker(
    PermissionTargets.configuration,
    PermissionTypes.execute
  );


  return (
    <Box sx={{ display: "inline", textAlign: "left", ...sx }}>
      <ModalWrapper
        title={"Configurations"}
        maxWidth="lg"
        trigger={
          trigger ? trigger :
            <Chip
              sx={{ mt: 2, border: 0 }}
              color="primary"
              variant="outlined"

              icon={<PlayCircle />}
              label="Analyze"

            />
        }
        child={<Box sx={{ p: 2, minWidth: "50em" }}>
          {hasPermission ? (
            <>

              <ConfigTable assetId={assetId} defaultProjectID={defaultProjectID} />
            </>
          ) : (
            <PermissionBlocker featureName="Analyze" />
          )}
        </Box>}

      />

    </Box>
  );
};

export default withRouter(ConfigTablePopup);
