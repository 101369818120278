import { useEffect, useState } from "react";
import { API_SLUGS } from "../../constants";
import useFetch from "../http/useFetch";
var format = require("string-template");

const FetchByRoutes: any = {
  api: API_SLUGS.GET_OPERATIONS,
  revision: API_SLUGS.GET_OPERATIONS_BY_REVISION,
  result: API_SLUGS.GET_OPERATIONS_BY_RESULT,
};

const useFetchOperations = (
  fetchBy: "api" | "revision" | "result",
  assetId: string,
  resultId?: string,
  revisionId?: string
) => {
  const {
    data: operationsData,
    loading: isOperationsLoading,
    error: operationsError,
    fetchData: fetchOperations,
  } = useFetch(format(FetchByRoutes[fetchBy], { assetId, resultId, revisionId }));
  const {
    data: userSettingsData,
    loading: isUserSettingsLoading,
    error: userSettingsError,
    fetchData: fetchUserSettings,
  } = useFetch(
   assetId? format(API_SLUGS.GET_USER_OPERATIONS_SETTINGS, {
      assetId,
      resultId,
      revisionId,
    }): undefined
  );

  const [operations, setOperations] = useState<any>(null);
  const [operationsWithUserSettings, setOperationsWithUserSettings] =
    useState<any>(null);

  useEffect(() => {
    if (operationsData?.operations) {
      let newOperations = [...operationsData.operations];
      for (let index in newOperations) {
        newOperations[index]["id"] = newOperations[index]?.["operationID"];
      }
      setOperations(newOperations);
    }
  }, [operationsData]);

  useEffect(() => {
    let map: any = {};
    if (operations && userSettingsData?.operations) {
      let userSettings = [...userSettingsData.operations];
      for (const index in userSettings) {
        map[userSettings[index].operation] = userSettings[index];
      }
    }
    if (operations) {
      let newOperations = [...operations];
      for (let index in newOperations) {
        newOperations[index].userSettings =
          map[newOperations[index]?.operationID];
        newOperations[index].settingsToUse = {
          ...newOperations[index].resourceProfile,
          ...map[newOperations[index]?.operationID],
        };
      }
      setOperationsWithUserSettings(newOperations);
      
    }
  }, [userSettingsData, operations]);

  return {
    operations: operationsWithUserSettings,
    loading: isOperationsLoading,
    error: { ...operationsError, ...userSettingsError },
    reFetchOperations: fetchOperations,
    reFetchUserOperationsSettings: fetchUserSettings,
  };
};

export default useFetchOperations;
