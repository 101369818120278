import { Autocomplete, TextField } from "@mui/material";
import { filter, find, isEmpty, map } from "lodash";
import { useEffect, useState } from "react";

interface IOperationSearch {
  assetId: string;
  label: string;
  size?: "small" | "medium" | undefined;
  multiple?: boolean;
  defaultValue: string[];
  operations: any[];
  helperText?: string;
  onChange: (selectedOperationIds: string[]) => void;
}

const OperationSearch: React.FC<IOperationSearch> = ({
  assetId,
  label,
  size = "small",
  multiple = false,
  defaultValue,
  operations,
  helperText = "",
  onChange,
}) => {
  const [selectedOperations, setSelectedOperations] = useState([] as any[]);

  useEffect(() => {
    if (!isEmpty(operations) && !isEmpty(defaultValue)) {
      let newSelectedOperations = [];
      for (let id of defaultValue) {
        let filteredOperation = find(operations, { operationID: id });
        if (!isEmpty(filteredOperation)) {
          newSelectedOperations.push(filteredOperation);
        }
      }
      setSelectedOperations(newSelectedOperations);
    }
  }, [operations, defaultValue]);

  const handleChange = (value: any) => {
    let newSelectedOperations = [];
    if (multiple) {
      newSelectedOperations = value;
    } else {
      newSelectedOperations = [value];
    }

    setSelectedOperations(newSelectedOperations);
    const newSelectedOperationIds = map(newSelectedOperations, "operationID");

    onChange(newSelectedOperationIds);
  };

  return (
    <>
      {true ? (
        <Autocomplete
          sx={{ maxWidth: "90%", textAlign: "left", mt: 2 }}
          size={size}
          getOptionLabel={(option): any => option?.operationID}
          options={operations}
          multiple={multiple}
          value={selectedOperations}
          onChange={(e, value) => {
            handleChange(value);
          }}
          clearOnBlur
          disableCloseOnSelect
          filterOptions={(options, params) => {
            const filtered = filter(options, function (item: any) {
              return item.operationID.indexOf(params.inputValue) > -1;
            });

            return filtered;
          }}
          renderInput={(params): JSX.Element => {
            return (
              <TextField
                name={label}
                label={label}
                placeholder={label}
                variant="outlined"
                helperText={helperText}
                {...params}
              />
            );
          }}
        />
      ) : null}
    </>
  );
};

export default OperationSearch;
