import { Circle } from "@mui/icons-material";
import { Chip, Link, Tooltip, Typography } from "@mui/material";
import { isEmpty, lowerCase, upperCase } from "lodash";
import MaterialReactTable from "material-react-table";
import moment from "moment";
import { FC, UIEvent, useEffect, useMemo, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { TOOL_TYPES } from "../../../constants";
import useFetch from "../../../hooks/http/useFetch";
import { usePageContext } from "../../../hooks/process/usePageContext";
import { useTableInfinity } from "../../../hooks/process/useTableInfinity";
import { IAppState, useAppState } from "../../../store/appState";
import CommonPopper from "../../common/commonPopper";
import { Labels } from "../ViewFindings/runComponents/labels";
import { ColumnHeaderFilter } from "../ViewIssues/IssuesTable";

const LIMIT = 60;
const ResultsTable: FC<any> = ({ history, match, limit = LIMIT,
  toolInfo = undefined,
  environmentId = undefined,
  disableInfiniteScroll = false,
  disableFilters = false }) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const { data: apiData } = useFetch("/apis");

  const [columnHeaderFilter, setColumnHeaderFilter] = useState<any>({});
  const { groups, projects, changeGroup, changeProject, selectedGroup, selectedProject, groupsLoading, projectsLoading } = usePageContext();
  const { breadcrumb } = useAppState() as IAppState
  const resultsUrl = useMemo(() => {
    if (groupsLoading || projectsLoading || isEmpty(projects)) {
      return undefined
    }
    let url = `/results?limit=${limit}&offset={offset}`;
    if (breadcrumb?.group?.id) {
      url += `&groupId=${breadcrumb?.group?.id}`;
    }
    if (breadcrumb?.project?.id) {
      url += `&projectId=${breadcrumb?.project?.id}`;
    }
    if (toolInfo) {
      url += `&toolName=${toolInfo?.name}`
    }

    if (toolInfo) {
      url += `&type=${lowerCase(toolInfo?.type)}`
    }

    if (environmentId) {
      url += `&environmentId=${environmentId}`
    }

    if (!isEmpty(columnHeaderFilter)) {
      for (let key in columnHeaderFilter) {
        const columnFilterValues = columnHeaderFilter[key];
        for (let value of columnFilterValues) {
          if (value !== null) {
            url += `&${key}=${value}`;
          }
        }
      }
    }



    return url;
  }
    , [groupsLoading, projectsLoading, projects, limit, breadcrumb?.group?.id, breadcrumb?.project?.id, toolInfo, environmentId, columnHeaderFilter])

  const environmentsURL = useMemo(() => {
    let url = "/environments?"
    if (groupsLoading || projectsLoading || isEmpty(projects)) {
      return url
    }

    if (breadcrumb?.group?.id) {
      url += `&groupId=${breadcrumb?.group?.id}`;
    }
    if (breadcrumb?.project?.id) {
      url += `&projectId=${breadcrumb?.project?.id}`;
    }



    return url;
  }
    , [groupsLoading, projectsLoading, projects, breadcrumb?.group?.id, breadcrumb?.project?.id])

  const assetsURL = useMemo(() => {
    let url = "/assets?"

    if (groupsLoading || projectsLoading || isEmpty(projects)) {
      return undefined
    }


    if (breadcrumb?.group?.id) {
      url += `groupId=${breadcrumb?.group?.id}`;
    }
    if (breadcrumb?.project?.id) {
      url += `&projectId=${breadcrumb?.project?.id}`;
    } else {
      return undefined
    }



    return url;
  }
    , [groupsLoading, projectsLoading, projects, breadcrumb?.group?.id, breadcrumb?.project?.id])

  const { data: assetsData } = useFetch(assetsURL);
  const { data: environmentData } = useFetch(environmentsURL);

  const { rows, fetchMoreOnBottomReached, isFetching } = useTableInfinity(
    "results",
    resultsUrl,
    tableContainerRef,
    limit
  );



  const handleColumnHeaderFilterChange = (key: string, value: any[] | null) => {
    if (!isEmpty(value)) {
      setColumnHeaderFilter({ ...columnHeaderFilter, [key]: value! });
    } else {
      let newFilter = { ...columnHeaderFilter };
      delete newFilter[key];
      setColumnHeaderFilter({ ...newFilter });
    }
  };


  useEffect(() => {
    if (!disableInfiniteScroll) {
      fetchMoreOnBottomReached(tableContainerRef.current);
    }
  }, [columnHeaderFilter, fetchMoreOnBottomReached])

  return (
    <>

      <MaterialReactTable muiTablePaperProps={{
        elevation: 0
      }} enableGlobalFilter={false}
        columns={[
          {
            accessorKey: "toolInfo.type",
            header: "Type",

            Header: (
              <ColumnHeaderFilter
                columnKey="type"
                name="Type"
                options={TOOL_TYPES}
                setColumnHeaderFilter={handleColumnHeaderFilterChange}
              />
            ),
            Cell: (params: any) => (
              <Chip
                label={upperCase(params.row?.original?.toolInfo?.type)}
                size="small"
              />
            ),
            size: 100,

          },
          {
            accessorKey: "id",
            header: "ID",
            Cell: (params: any) => (
              <Link
                onClick={() => {
                  history.push(
                    `/findings?resultId=${params.row?.original?.id}`
                  );
                }}
              >
                {params.row?.original?.id?.split("-")[0]}
                {params.row?.original?.metrics?.issuesCountNew > 0 ?
                  <CommonPopper
                    trigger={<Tooltip title="New Issues Found">
                      <Circle
                        fontSize="inherit"
                        sx={{ ml: 1, mt: 1 }}
                        color="error"


                      />
                    </Tooltip>}
                    child={<Typography variant="caption" color="secondary">
                      New Issues Found</Typography>}
                  /> : null}
              </Link>
            ),
            size: 100,
          },

          {
            accessorKey: "name",
            header: "Checkpoint",
            size: 50,
          },
          {
            accessorKey: "metrics.issuesCountNew",
            header: "Labels",
            Cell: (params: any) => (
              <>
                <Labels labels={params.row?.original?.labels} />
                {params.row?.newIssues > 0 ? (
                  <Chip size="small" color="success" label="New"></Chip>
                ) : null}
              </>
            ),
            size: 50,
          },
          {
            accessorKey: "projectName",
            header: "Project",
            size: 100,
          },
          {
            accessorKey: "assetName",
            header: "Asset",
            size: 100,
            Header: (
              assetsData?.assets ? <ColumnHeaderFilter
                columnKey="assetId"
                name="Asset"
                options={assetsData?.assets?.map(
                  (asset: any) => ({
                    key: asset?.id,
                    label: asset?.name,
                  })
                )}
                setColumnHeaderFilter={handleColumnHeaderFilterChange}
              /> : null
            ),
          },
          {
            accessorKey: "metrics.issuesCountCriticalSeverity", header: "Critical", size: 40
            , Cell: (params: any) => (
              <Chip
                label={params?.row?.original?.metrics?.issuesCountCriticalSeverity}
                size="small"
                variant={params?.row?.original?.metrics?.issuesCountCriticalSeverity > 0 ? "filled" : "outlined"}
                //@ts-ignore
                color="critical"
              />
            ),
          },
          {
            accessorKey: "metrics.issuesCountHighSeverity", header: "High",
            size: 40
            , Cell: (params: any) => (
              <Chip
                label={params?.row?.original?.metrics?.issuesCountHighSeverity}
                size="small"
                variant={params?.row?.original?.metrics?.issuesCountHighSeverity > 0 ? "filled" : "outlined"}
                color="error"
              />
            )
          },
          {
            accessorKey: "metrics.issuesCountMediumSeverity",
            header: "Medium",
            size: 40
            , Cell: (params: any) => (
              <Chip
                label={params?.row?.original?.metrics?.issuesCountMediumSeverity}
                size="small"
                variant={params?.row?.original?.metrics?.issuesCountMediumSeverity > 0 ? "filled" : "outlined"}
                color="warning"
              />
            )
          },
          {
            accessorKey: "metrics.issuesCountLowSeverity", header: "Low", size: 40
            , Cell: (params: any) => (
              <Chip
                label={params?.row?.original?.metrics?.issuesCountLowSeverity}
                size="small"
                variant={params?.row?.original?.metrics?.issuesCountLowSeverity > 0 ? "filled" : "outlined"}
                color="info"
              />
            )
          },
          {
            accessorKey: "metrics.issuesCountInformationSeverity", header: "Information", size: 40
            , Cell: (params: any) => (
              <Chip
                label={params?.row?.original?.metrics?.issuesCountInformationSeverity}
                size="small"
                variant={params?.row?.original?.metrics?.issuesCountInformationSeverity > 0 ? "filled" : "outlined"}
                //@ts-ignore
                color="information"
              />
            )
          },

          {
            accessorKey: "toolInfo.name",
            header: "Tool",
            size: 50,
          },

          {
            accessorKey: "environmentName",
            header: "Environment",
            size: 50,
            Header: (
              <ColumnHeaderFilter
                columnKey="environmentId"
                name="Environment"
                options={environmentData?.environments.map(
                  (environment: any) => ({
                    key: environment.id,
                    label: environment.name,
                  })
                )}
                setColumnHeaderFilter={handleColumnHeaderFilterChange}
              />
            ),
          },
          {
            accessorKey: "state",
            header: "Status",
            size: 30,
          },
          {
            accessorKey: "metrics.issuesCount",
            header: "Issues",
            size: 20,
          },


          {
            accessorKey: "metrics.duration",
            header: "Duration (s) ",
            size: 30,
            Cell: (params: any) => (
              <> {Math.round(params?.row?.original?.metrics?.duration / 1000)} </>
            ),

          },
          {
            accessorKey: "updatedAt",
            header: "Time",
            size: 30,
            Cell: (params: any) => (
              <> {moment(params?.row?.original?.updatedAt).format("DD MMM YYYY H:mm ")} </>
            ),
          },
        ]}
        data={rows}
        initialState={{
          density: "compact",
          columnPinning: { left: ['id'] },

        }}
        enableColumnActions={false}
        enableSorting={false}
        enableStickyHeader



        enableTopToolbar={false}
        enablePagination={false}
        enableDensityToggle={false}
        enableColumnFilters={false}
        // enableRowVirtualization
        state={{
          showProgressBars: isFetching || projectsLoading || groupsLoading,
        }}
        muiTableContainerProps={{
          ref: tableContainerRef, //get access to the table container element
          sx: { maxHeight: "calc(100vh - 270px)" }, //give the table a max height
          onScroll: (
            event: UIEvent<HTMLDivElement> //add an event listener to the table container element
          ) => {
            if (!disableInfiniteScroll) {
              fetchMoreOnBottomReached(event.target as HTMLDivElement)
            }

          }
        }}
      />

    </>
  );
};

export default withRouter(ResultsTable);
