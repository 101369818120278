import {
  Accordion, AccordionDetails, AccordionSummary, Autocomplete,
  Box,
  Button,
  FormHelperText,
  Grid,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import { isEmpty, merge } from 'lodash';
import moment from "moment";
import { FC, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getConfigs } from '../../../configs';
import { API_SLUGS, HELPER_TEXTS, STRINGS } from '../../../constants';
import useFetch from '../../../hooks/http/useFetch';
import { ChevronDown } from '../../../icons/chevron-down';
import { axiosInstance } from '../../../services/axios';
import { extractEnvVar, lowercaseKeys, parseHermitConfig } from '../../../utils/hermitConfigUtils';
import Loaders from "../../dashboard/Loaders";
import OperationSearch from "../../generators/OperationSearch";
import { operationFunctions } from '../../generators/operationFunctions';
import { AnalyzerPicker } from "./AnalyzerPicker";
import { AuthorizationWidget } from "./AuthorizationWidget";
import blankSpec from './blankSpec.json';
const format = require("string-template");

interface IAddJobSpecificationsFormValues {
  name: string,
  apiId: string,
  environmentId?: string,
  target: string,
  definition: string,
  revision: string,
  analyzers: string[],
  includeID: string[],
  excludeID: string[],
  traceHeader: string,
  analyzerDetails?: any,
  authorization?: any,
  useGenerators?: boolean,
  additionalSettings?: any
}

interface MatchParams {
  source: string,
  id: string
}

interface IAddForm extends RouteComponentProps<MatchParams> {
  defaultFormValues?: any
  preventRedirect?: boolean
  callback?: () => void
  quickstart?: boolean
  apiId?: string
  configId?: string | undefined
  source: "wizard" | "listJobs" | "new" | "editConfig"
}

const AddJobSpecificationsForm: FC<IAddForm> = ({ configId = undefined, source, history, match, location, defaultFormValues, preventRedirect = false, callback = undefined, quickstart = false, apiId = undefined }) => {
  
  const [formValues, setFormValues] = useState(defaultFormValues as IAddJobSpecificationsFormValues)
  const [isInvalid, setIsInvalid] = useState(true);
  const [apis, setApis] = useState([{ label: STRINGS.EMPTY, id: STRINGS.EMPTY }]);
  const [environments, setEnvironments] = useState([{ name: STRINGS.EMPTY, id: STRINGS.EMPTY }]);
  const [analyzers, setAnalyzers] = useState([]);
  const [operations, setOperations] = useState([] as any[]);
  const [revisions, setRevisions] = useState([] as any[]);
  const [sourcePath, setSourcePath] = useState('/jobs');
  const [isLoading, setIsLoading] = useState(true);
  const useQuery = () => new URLSearchParams(location.search);
  const query = useQuery();
  const presetApiId = query.get("apiId") || apiId;
  const { data: presetApiDetails } = useFetch(presetApiId ? `/apis/${presetApiId}` : undefined);

  const fetchJobSpec = async (source: string) => {
    const wizardSource = ['wizard', 'listJobs', 'editConfig'].includes(source);
    const jobResponse = await axiosInstance.get(wizardSource ? `${API_SLUGS.RUN_CONFIGURATIONS}/${configId}` : `/results/${configId}/config`);
    if (wizardSource) {
      const job = jobResponse.data;
      const jobConfig: any = parseHermitConfig(job.config);
      const additionalSettings = job.additionalSettings;
      const analyzerDetails: any = {};
      jobConfig.analysis.analyzers
        .filter((analyzer: any) => {
          const { kind, ...details } = analyzer;
          if (!isEmpty(details)) {
            return true;
          }
          return false;
        })
        .forEach((analyzer: any) => {
          const { kind, ...details } = analyzer;
          analyzerDetails[kind] = details;
        })
      const authorization: any = [];
      if (jobConfig.targets[0].authorization && !isEmpty(jobConfig.targets[0].authorization)) {
        Object.entries(jobConfig.targets[0].authorization).forEach(([key, value]) => {
          const envVars: any = []; // detect values containing env vars
          // @ts-ignore
          Object.entries(value).forEach(([ckey, cvalue]) => {
            // @ts-ignore
            if (cvalue !== extractEnvVar(cvalue)) {
              envVars.push(ckey);
            }
          })
          envVars.forEach((it: string) => {
            // @ts-ignore
            value[it] = extractEnvVar(value[it]);
          });
          // @ts-ignore
          authorization.push({ key, ...(envVars.length && { envVars }), ...value });
        });
      }
      jobConfig.analysis.analyzers
        .filter((analyzer: any) => {
          const { kind, ...details } = analyzer;
          if (!isEmpty(details)) {
            return true;
          }
          return false;
        })
        .forEach((analyzer: any) => {
          const { kind, ...details } = analyzer;
          analyzerDetails[kind] = details;
        })

      return {
        formValues: {
          ...formValues,
          name: editing ? job.name : `Copy of ${job.name}`,
          apiId: job.apiId,
          environmentId: jobConfig.environmentid,
          target: jobConfig.targets[0].url,
          traceHeader: jobConfig.targets[0].traceHeader || '',
          definition: jobConfig.targets[0].definition.file || jobConfig.targets[0].definition.path,
          revision: jobConfig.targets[0].definition.revision || 'custom',
          analyzers: jobConfig.analysis.analyzers.map((analyzer: any) => typeof analyzer === 'string' ? analyzer : analyzer.kind),
          includeID: jobConfig.targets[0].operations ? jobConfig.targets[0].operations.includeID : [],
          excludeID: jobConfig.targets[0].operations ? jobConfig.targets[0].operations.excludeID : [],
          analyzerDetails: reviseAnalyzerDetails(analyzerDetails),
          ...(authorization.length && { authorization }),
          additionalSettings
        }
      };
    }
    const job = lowercaseKeys(jobResponse.data);
    const hermitTarget = lowercaseKeys(job.config.targets[0]);
    return {
      ...formValues,
      name: (job.config.labels && (job.config.labels.jobspec || job.config.labels.configurationName)) ? `Copy of ${job.config.labels.jobspec || job.config.labels.configurationName}` : 'Untitled',
      apiId: job.apiId,
      environmentId: job.config.environmentid,
      target: hermitTarget.url,
      traceHeader: hermitTarget.traceHeader || '',
      definition: hermitTarget.definition.file || hermitTarget.definition.path,
      analyzers: job.config.analysis.analyzers.map((analyzer: any) => typeof analyzer === 'string' ? analyzer : analyzer.kind)
    };
  }

  const fetchRevisions = async (uuid: string) => {
    const response = await axiosInstance.get(format(API_SLUGS.LIST_REVISIONS, { uuid }));
    if (response && response.data && response.data.revisions) {
      const formattedResult = response.data?.revisions.map((revision: any) => ({
        id: revision.id,
        name: `${revision.name || revision.id} (${moment(revision.createdAt).fromNow()})`,
      }));
      formattedResult.unshift({
        id: 'latest',
        name: 'Latest'
      });
      formattedResult.push({
        id: 'custom',
        name: 'Custom definition'
      });
      return formattedResult;
    };
    return [];
  }

  const fetchData = async () => {
    setIsLoading(true);
    try {
      let savedFormValues;
    console.log({presetApiDetails})
    const apisResponse = await axiosInstance.get(`/apis?projectId=${presetApiDetails?.projectId}`);
    setApis(apisResponse.data?.apis)
    const defaultApi = apisResponse.data?.apis && apisResponse.data?.apis.length
      ? apisResponse.data?.apis[0].id
      : STRINGS.EMPTY

    const environmentsResponse = await axiosInstance.get(`/projects/${presetApiDetails?.projectId}/environments`);
    setEnvironments([{ name: '<none>', id: STRINGS.EMPTY }, ...environmentsResponse.data?.environments]);

    const analyzersResponse = await axiosInstance.get(API_SLUGS.FAULT_ANALYZERS);
    setAnalyzers(analyzersResponse.data?.faultAnalyzers);

    if (source) { // if called from wizard or runs page
      if (['new'].includes(source)) {
        setSourcePath('/configurations');
      } else {
        try {
          const fetchedValues = await fetchJobSpec(source);
          savedFormValues = fetchedValues.formValues;
          console.log({savedFormValues})
          // @ts-ignore
          setFormValues(savedFormValues);
          setSourcePath(source === 'wizard' ? '/configurations' : `/${source}`);
        } catch (error: any) {
          console.log(error);
        }
      }
    }

    if (!savedFormValues) {
      const newDefaultFormValues = {
        ...formValues,
        name: quickstart ? 'Quickstart config' : '',
        apiId: presetApiId || defaultApi,
        environmentId: '',
        target: '',
        traceHeader: '',
        definition: '',
        analyzers: analyzersResponse.data?.faultAnalyzers
          .filter((analyzer: any) => !!analyzer.basicConfig)
          .map((analyzer: any) => analyzer.name),
        includeID: [],
        excludeID: [],
        analyzerDetails: {},
        additionalSettings: { generators: true }
      };
      setFormValues(newDefaultFormValues);
    }

    const currentApi = (formValues && formValues?.apiId) || (savedFormValues && savedFormValues.apiId) || presetApiId || defaultApi;

    const operationsResponse = await operationFunctions.getOperations(currentApi);
    setOperations(operationsResponse);

    const revisionsResponse = await fetchRevisions(currentApi);
    setRevisions(revisionsResponse)
    }
    catch(e) {

    }

    setIsLoading(false);
  };


  useEffect(() => {
    if (presetApiDetails) {
      fetchData();
      return () => {
        setApis([{ label: STRINGS.EMPTY, id: STRINGS.EMPTY }]);
        setEnvironments([{ name: STRINGS.EMPTY, id: STRINGS.EMPTY }]);
        setAnalyzers([]);
        setOperations([]);
        setRevisions([]);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [presetApiDetails]);

  const editing: boolean = source.includes('editConfig');

  const updateOACPUsers = (policy: any, values: any) => {
    const updated: any = [];
    if (!policy.length) {
      return updated;
    }
    policy.forEach((item: any) => {
      const newPolicy: any = { ...item };
      if (item.owner) {
        if (!values.map((item: any) => item.key).includes(item.owner)) {
          newPolicy.owner = values[0].key;
        }
      } else {
        delete newPolicy.owner;
      }
      newPolicy.permissions = item.permissions.map((permission: any, idx: number) => {
        return {
          ...permission,
          user: values[idx].key
        }
      });
      updated.push(newPolicy);
    });
    return updated;
  }

  const handleChange = async (value: any, type: any) => {
    const newValues = {
      ...formValues, [type]: (['analyzers', 'includeID', 'excludeID'].includes(type))
        ? Array.from(new Set(value))
        : value
    };
    if (type === 'apiId' && value !== formValues?.apiId) {
      const operationsResponse = await operationFunctions.getOperations(value);
      setOperations(operationsResponse);
      const revisionsResponse = await fetchRevisions(value);
      setRevisions(revisionsResponse);
    }
    if (type === 'authorization' && formValues?.analyzerDetails && formValues?.analyzerDetails?.ObjectAccessControlPolicy) {
      newValues.analyzerDetails.ObjectAccessControlPolicy.policy = updateOACPUsers(formValues?.analyzerDetails?.ObjectAccessControlPolicy.policy, value);
    }
    if ((newValues?.name?.trim() !== '')
      && (newValues?.apiId?.trim() !== '')
      && (newValues?.target?.trim() !== '')
      && (newValues?.analyzers?.length)) {
      setIsInvalid(false);
    } else {
      setIsInvalid(true);
    }
    setFormValues(newValues);
  }

  const addAnalyzerDetails = (analyzer: string) => {
    if (formValues?.analyzerDetails && formValues?.analyzerDetails[analyzer]) {
      return merge({ kind: analyzer }, formValues?.analyzerDetails[analyzer]);
    }
    return { kind: analyzer };
  }

  const reviseAnalyzerDetails = (details: any) => {
    // process deprecated details
    const updated: any = [];
    Object.entries(details).forEach(([key, value]) => {

      if (key === 'ObjectAccessControlPolicy') {
        if ((value as any)?.policy && (value as any)?.policy.length) {
          (value as any).policy.forEach((policy: any) => {
            if ((policy as any)?.resource) {
              const resource = (policy as any)?.resource;
              delete policy.resource;
              policy.resources = [resource];
              updated.push(policy)
            }
          })
        }
      }
    })

    if (updated.length) {
      return { ObjectAccessControlPolicy: { policy: updated }, ...details }
    }
    return details;
  }

  const wrapEnvVar = (input: string) => `{{.Env.${input}}}`;

  const processAuth = (auth: any) => {
    switch (auth?.kind) {
      case 'basic':
        return {
          kind: auth?.kind,
          username: auth?.envVars && auth?.envVars.includes('username') ? wrapEnvVar(auth?.username) : auth?.username,
          password: auth?.envVars && auth?.envVars.includes('password') ? wrapEnvVar(auth?.password) : auth?.password
        }
      case 'dynamic':
        return {
          kind: auth?.kind,
          location: 'header',
          name: auth?.name || '',
          command: auth?.command || '',
          timeout: auth?.timeout || ''
        }
      case 'static':
        return {
          kind: auth?.kind,
          location: 'header',
          name: auth?.name || '',
          value: auth?.envVars && auth?.envVars.includes('value') ? wrapEnvVar(auth?.value) : auth?.value
        }
      case 'dynamic-http-header':
        return {
          kind: auth?.kind,
          command: auth?.command || '',
          timeout: auth?.timeout || ''
        }
      default:
        return auth;
    }
  }

  const handleSubmit = async () => {
    const authorization: any = {};
    if (formValues?.authorization && formValues?.authorization.length) {
      formValues?.authorization.forEach((item: any) => {
        const { key, ...rest } = item;
        authorization[key] = processAuth(rest);
      })
    }

    let definition: any = {};
    if (formValues?.revision === 'custom') {
      definition = formValues?.definition?.trim().startsWith('http')
        ? { path: formValues?.definition?.trim() }
        : { file: formValues?.definition?.trim() }
    } else {
      definition = { revision: formValues?.revision || 'latest' };
    }

    const payload = merge(
      {},
      blankSpec,
      {
        targets: [{
          api: formValues?.apiId?.trim(),
          url: formValues?.target?.trim(),
          definition,
          ...((formValues?.includeID.length || formValues?.excludeID.length) && {
            operations: {
              includeID: formValues?.includeID,
              excludeID: formValues?.excludeID
            }
          }),
          ...(formValues?.traceHeader && { traceHeader: formValues?.traceHeader }),
          ...(!isEmpty(authorization) && { authorization })
        }],
        ...(formValues?.environmentId && { environmentID: formValues?.environmentId }),
        analysis: {
          platformURL: getConfigs().baseApiUrl,
          analyzers: formValues?.analyzers.map(analyzer => (addAnalyzerDetails(analyzer)))
        },
        labels: {
          configurationName: formValues?.name.trim()
        }
      }
    );
    await updateHermitConf({
      name: formValues?.name.trim(),
      apiId: formValues?.apiId.trim(),
      config: btoa(JSON.stringify(payload)),
      additionalSettings: { generators: formValues?.additionalSettings?.generators }
    });

  };

  const updateHermitConf = async (payload: any) => {
    try {
      if (editing) {
        await axiosInstance.patch(`${API_SLUGS.RUN_CONFIGURATIONS}/${configId}`, payload);
      } else {
        await axiosInstance.post(API_SLUGS.RUN_CONFIGURATIONS, payload);
      }
      if (!preventRedirect) {
        history.push(sourcePath);
      }
      if (callback) {
        callback()
      }
    } catch (error: any) {
      console.log(error);
    }

  };

  const validAnalyzerNames = analyzers.map((it: any) => it.name); // remove any deprecated names loaded from saved config

  if (isLoading) {
    return <Loaders />;
  }
  return (
    <Box sx={{ textAlign: "left" }}>
      <Grid
        container
        rowSpacing={3}
      >
        {!quickstart && (
          <Grid item xs={12}>
            <Typography
              color="textPrimary"
              sx={{ mb: 1, textAlign: "left" }}
              variant="h6"
            >
              Configuration Name
            </Typography>
            <TextField
              sx={{ maxWidth: "90%", textAlign: 'left' }}
              size="medium"
              fullWidth
              name="NameInput"
              value={(formValues && formValues?.name) || ''}
              onChange={(e: any) => { handleChange(e.target.value, 'name'); }}
              variant="outlined"
              helperText={HELPER_TEXTS?.add_job_configuration?.name}
              required
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography
            color="textPrimary"
            sx={{ mb: 1, textAlign: "left" }}
            variant="h6"
          >
            {quickstart ? 'Pick the API' : 'API'}
          </Typography>
          <Autocomplete
            options={apis.map((api: any) => api.id)}
            getOptionLabel={option => apis.filter((api: any) => api.id === option).map((api: any) => `${api.projectName} / ${api.name}`).join()}
            sx={{ maxWidth: "90%", textAlign: 'left' }}
            size="medium"
            disabled={editing}
            onChange={(e, value) => {
              handleChange(value || STRINGS.EMPTY, 'apiId');
            }}
            value={(formValues && formValues?.apiId) || (apis.length && apis[0].id) || ''}
            renderInput={(params): JSX.Element => {
              return <TextField
                name="apiId"
                variant="outlined"
                helperText={!apis.length ? 'No APIs defined' : HELPER_TEXTS?.add_job_configuration?.api}
                {...params}
              />
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            color="textPrimary"
            sx={{ mb: 1, textAlign: "left" }}
            variant="h6"
          >
            {quickstart ? 'Where is the Service Running?' : 'Target URL'}
          </Typography>
          <TextField
            sx={{ maxWidth: "90%", textAlign: 'left' }}
            size="medium"

            fullWidth
            name="TargetInput"
            helperText={HELPER_TEXTS?.add_job_configuration?.target_url}
            value={(formValues && formValues?.target) || ''}
            onChange={(e: any) => { handleChange(e.target.value, 'target'); }}
            variant="outlined"
            required
          />
        </Grid>

        {!quickstart && (
          <Grid item xs={12}>
            <Typography
              color="textPrimary"
              sx={{ mb: 1, textAlign: "left" }}
              variant="h6"
            >
              API definition
            </Typography>
            <Autocomplete
              options={revisions.map((revision: any) => revision.id)}
              getOptionLabel={option => revisions.filter((revision: any) => revision.id === option).map((revision: any) => revision.name).join()}
              sx={{ maxWidth: "90%", textAlign: 'left' }}
              size="medium"
              onChange={(e, value) => {
                handleChange(value || STRINGS.EMPTY, 'revision');
              }}
              value={(formValues && formValues?.revision) || (revisions.length && revisions[0].id) || ''}
              renderInput={(params): JSX.Element => {
                return <TextField
                  name="RevisionInput"
                  variant="outlined"
                  helperText={HELPER_TEXTS?.add_job_configuration?.revision}
                  {...params}
                />
              }}
            />
            {formValues && formValues?.revision === 'custom' && (
              <TextField
                sx={{ maxWidth: "90%", textAlign: 'left' }}
                size="medium"
                fullWidth
                name="DefinitionInput"
                helperText={HELPER_TEXTS?.add_job_configuration?.definition}
                value={(formValues && formValues?.definition) || ''}
                onChange={(e: any) => { handleChange(e.target.value, 'definition'); }}
                variant="outlined"
                required
              />
            )}
          </Grid>
        )}

        <Box sx={{ mt: 3 }}>
          <Typography
            color="textPrimary"
            sx={{ mt: 2, textAlign: "left" }}
            variant="h6"
          >
            {quickstart ? 'Does your API need authentication?' : 'Authorization'}
          </Typography>
          <FormHelperText sx={{ ml: 2 }}>{HELPER_TEXTS?.authorization?.title}</FormHelperText>
          <AuthorizationWidget
            authorizations={formValues && formValues?.authorization}
            onChange={(authorizations: any[]) => {
              handleChange(authorizations, 'authorization')
            }}
          />
        </Box>

        {!quickstart && (
          <Grid item xs={12}>
            <Accordion
              defaultExpanded={formValues && !!(formValues?.traceHeader || formValues?.includeID?.length || formValues?.excludeID?.length || formValues?.authorization?.length)}
              sx={{ maxWidth: "90%", textAlign: 'left', mt: 2 }}
            >
              <AccordionSummary expandIcon={<ChevronDown />}>
                <Typography
                  color="textPrimary"
                  sx={{ mb: 1, textAlign: "left" }}
                  variant="h6"
                >
                  Optional settings
                </Typography>
              </AccordionSummary>
              <AccordionDetails>


                <Typography
                  color="textPrimary"
                  sx={{ mb: 1, textAlign: "left" }}
                  variant="h6"
                >
                  Trace header
                </Typography>
                <TextField
                  sx={{ maxWidth: "90%", textAlign: 'left' }}
                  size="medium"
                  fullWidth
                  name="TraceHeaderInput"
                  helperText={HELPER_TEXTS?.add_job_configuration?.trace_header}
                  value={(formValues && formValues?.traceHeader) || ''}
                  onChange={(e: any) => { handleChange(e.target.value, 'traceHeader'); }}
                  variant="outlined"
                />

                <Typography
                  color="textPrimary"
                  sx={{ mb: 1, textAlign: "left" }}
                  variant="h6"
                >
                  Environment
                </Typography>
                <Autocomplete
                  options={environments.map((environment: any) => (environment.id))}
                  getOptionLabel={option => environments.filter((environment: any) => environment.id === option).map((environment: any) => environment.name).join()}
                  sx={{ maxWidth: "90%", textAlign: 'left', mt: 2 }}
                  size="medium"
                  onChange={(e, value) => {
                    handleChange(value, 'environmentId');
                  }}
                  value={(formValues && formValues?.environmentId) || (environments.length && environments[0].id) || ''}
                  renderInput={(params): JSX.Element => {
                    return <TextField
                      name="environmentId"
                      variant="outlined"
                      helperText={!environments.length ? 'No environments defined' : HELPER_TEXTS?.add_job_configuration?.environment}
                      {...params}
                    />
                  }}
                />

                {operations.length ? (
                  <Box sx={{ mt: 3 }}>
                    <Typography
                      color="textPrimary"
                      sx={{ mb: 1, textAlign: "left" }}
                      variant="h6"
                    >
                      Operations
                    </Typography>
                    <OperationSearch
                      assetId={formValues && formValues?.apiId}
                      label="Operations to Include"
                      size="medium"
                      multiple
                      operations={operations}
                      helperText={HELPER_TEXTS?.add_job_configuration?.included_operations}
                      defaultValue={formValues ? formValues?.includeID : []}
                      onChange={(selectedOperationIds) => {
                        handleChange(selectedOperationIds, 'includeID')
                      }}
                    />
                    <OperationSearch
                      assetId={formValues && formValues?.apiId}
                      label="Operations to Exclude"
                      size="medium"
                      multiple
                      operations={operations}
                      helperText={HELPER_TEXTS?.add_job_configuration?.excluded_operations}
                      defaultValue={formValues ? formValues?.excludeID : []}
                      onChange={(selectedOperationIds) => {
                        handleChange(selectedOperationIds, 'excludeID')
                      }}
                    />
                  </Box>
                ) : null}

                <Box sx={{ pt: 2, display: "flex", justifyContent: "start" }}>
                  <Typography variant="h6" sx={{ pt: 1 }}> Include generators</Typography>
                  <Switch
                    checked={formValues && formValues?.additionalSettings?.generators}
                    onChange={({ target }) => {
                      handleChange({ generators: target.checked }, 'additionalSettings');
                    }}
                  />
                </Box>
                <Box>
                  <FormHelperText sx={{ ml: 2 }}>{HELPER_TEXTS?.generators?.switch}</FormHelperText>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box
            sx={{
              mt: 3
            }}
          >
            <Typography
              color="textPrimary"
              sx={{ mt: 2, textAlign: "left" }}
              variant="h6"
            >
              {quickstart ? 'What would you like to Analyze?' : 'Analyzers'}
            </Typography>
            <AnalyzerPicker
              analyzerDefinitions={analyzers}
              resources={Array.from(new Set(operations.map((it: any) => it?.resourceProfile?.resource || it.resource)))}
              selectedAnalyzers={(formValues && formValues?.analyzers.filter((analyzerName: any) =>
                validAnalyzerNames.includes(analyzerName)
              )) || []}
              onChange={(selectedAnalyzers: any[]) => {
                handleChange(selectedAnalyzers, 'analyzers')
              }}
              selectedDetails={(formValues && formValues?.analyzerDetails) || {}}
              onDetailsChange={(selectedDetails: any) => {
                handleChange(selectedDetails, 'analyzerDetails')
              }}
              users={(formValues && formValues?.authorization) || []}
              quickstart={quickstart}
            />
          </Box>
        </Grid>
      </Grid>

      <Box
        sx={{
          display: 'flex',
          mt: 3
        }}
      >
        <Button
          color="primary"
          size="large"
          variant="contained"
          disabled={isInvalid}
          onClick={handleSubmit}
        >
          Save
        </Button>
        {!quickstart && (
          <Button
            sx={{ ml: 2 }}
            size="large"
            variant="outlined"
            //@ts-ignore
            onClick={() => preventRedirect ? callback() : history.push(sourcePath)}
          >
            Cancel
          </Button>
        )}
      </Box>
    </Box>
  );
};


export default withRouter(AddJobSpecificationsForm);
