import {
  Autocomplete,
  Box, Chip,
  Link,
  TextField,
  Typography
} from "@mui/material";
import { isEmpty, map } from "lodash";
import MaterialReactTable from "material-react-table";
import moment from "moment";
import { FC, UIEvent, useEffect, useMemo, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { STATUS_OPTIONS, TOOL_TYPES } from "../../../constants";
import useFetch from "../../../hooks/http/useFetch";
import { usePageContext } from "../../../hooks/process/usePageContext";
import { useTableInfinity } from "../../../hooks/process/useTableInfinity";
import { IAppState, useAppState } from "../../../store/appState";
import { IUserStoreState, useUserStore } from "../../../store/userState";
import ActionButton, {
  RISK_STATE_OPTIONS
} from "../ViewFindings/shared/button";

export const ColumnHeaderFilter: FC<any> = ({
  columnKey,
  name,
  options,
  setColumnHeaderFilter,
}) => {
  return (
    <Typography variant="overline">
      {!isEmpty(options) ? (
        <Autocomplete
          getOptionLabel={(option: any) => option?.label}
          options={options}
          size="small"
          sx={{ fontSize: "12px", minWidth: "10em" }}
          multiple
          // value={
          //   columnHeaderFilter[column.key]
          //     ? getColumnHeaderValues(column)
          //     : []
          // }
          onChange={(e, value) => {
            setColumnHeaderFilter(columnKey, map(value, "key"));
          }}
          renderInput={(params): JSX.Element => {
            return (
              <TextField
                name="severity"
                variant="outlined"
                placeholder={name}
                {...params}
                InputLabelProps={{
                  style: { fontSize: "12px", fontWeight: 700 },
                }}
                InputProps={{
                  ...params.InputProps,
                  style: { fontSize: "12px" },
                }}
              />
            );
          }}
        />
      ) : null}
    </Typography>
  );
};

const LIMIT = 20;
const IssuesTable: FC<any> = ({
  history,
  location,
  limit = LIMIT,
  currentUserOnly = false,
  disableInfiniteScroll = false,
  disableFilters = false
}) => {
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const { currentUser } = useUserStore((state) => state) as IUserStoreState;



  const useQuery = () => new URLSearchParams(location.search);
  const query = useQuery();
  const categoryName = query.get("categoryName");

  const [columnHeaderFilter, setColumnHeaderFilter] = useState<any>({});


  const { projects, groupsLoading, projectsLoading } = usePageContext();
  const { breadcrumb } = useAppState() as IAppState
  const issuesUrl = useMemo(() => {

    if (groupsLoading || projectsLoading || isEmpty(projects)) {
      return undefined
    }

    let url = `/issues?limit={limit}&offset={offset}`;
    if (breadcrumb?.group?.id) {
      url += `&groupId=${breadcrumb?.group?.id}`;
    }
    if (currentUserOnly) {
      url += `&assigneeIds=${currentUser.uuid}`
    }
    if (breadcrumb?.project?.id) {
      url += `&projectId=${breadcrumb?.project?.id}`;
    }
    if (!disableFilters) {
      if (categoryName) {
        url += `&categoryName=${encodeURIComponent(categoryName)}`;
      }
    }

    if (!isEmpty(columnHeaderFilter)) {
      for (let key in columnHeaderFilter) {
        const columnFilterValues = columnHeaderFilter[key];
        for (let value of columnFilterValues) {
          if (value !== null) {
            url += `&${key}=${value}`;
          }
        }
      }
    }




    return url;
  }
    , [groupsLoading, projectsLoading, projects, breadcrumb, currentUserOnly, disableFilters, columnHeaderFilter, currentUser.uuid, categoryName])

  const environmentsURL = useMemo(() => {
    let url = "/environments?"
    if (groupsLoading || projectsLoading || isEmpty(projects)) {
      return url
    }

    if (breadcrumb?.group?.id) {
      url += `&groupId=${breadcrumb?.group?.id}`;
    }
    if (breadcrumb?.project?.id) {
      url += `&projectId=${breadcrumb?.project?.id}`;
    }


    return url;
  }
    , [groupsLoading, projectsLoading, projects, breadcrumb?.group?.id, breadcrumb?.project?.id])

  const { data: environmentData } = useFetch(environmentsURL);
  const { data: faultGroupsData } = useFetch("/faultgroups");

  const { rows, fetchMoreOnBottomReached, isFetching } = useTableInfinity("issues",
    issuesUrl
    ,
    tableContainerRef,
    limit
  );



  const handleColumnHeaderFilterChange = (key: string, value: any[] | null) => {
    if (!isEmpty(value)) {
      setColumnHeaderFilter({ ...columnHeaderFilter, [key]: value! });
    } else {
      let newFilter = { ...columnHeaderFilter };
      delete newFilter[key];
      setColumnHeaderFilter({ ...newFilter });
    }
  };

  useEffect(() => {

    if (!disableInfiniteScroll) {
      fetchMoreOnBottomReached(tableContainerRef.current);
    }

  }, [columnHeaderFilter, fetchMoreOnBottomReached])





  return (
    <Box sx={{ mt: 2 }}>

      <MaterialReactTable muiTablePaperProps={{
        elevation: 0
      }} enableGlobalFilter={false}
        columns={[
          {
            accessorKey: "findingType",
            header: "Type",
            Header: (
              <ColumnHeaderFilter
                columnKey="findingType"
                name="Type"
                options={TOOL_TYPES}
                setColumnHeaderFilter={handleColumnHeaderFilterChange}
              />
            ),
            Cell: (params: any) => (
              <Chip
                label={params?.row?.original?.findingType}
                size="small"
              />
            ),

            size: 100,

          },
          {
            accessorKey: "id",
            header: "ID",
            Cell: (params: any) => (
              <Link
                onClick={() => {
                  history.push(
                    `/findings?issueId=${params.row?.original?.id}`
                  );
                }}
              >
                #{params.row?.original?.index}
              </Link>
            ),
            size: 30,
          },
          {
            accessorKey: "risk",
            header: "Risk",
            Cell: (params: any) => (
              <ActionButton
                issueId={params?.row?.original?.id}
                buttons="RISK"
                risk={params?.row?.original?.risk}
                state={params?.row?.original?.state}
                user={params?.row?.original?.assigneeId}
                jira={{
                  url: params?.row?.original?.jiraUrl,
                  number: params?.row?.original?.jiraNumber,
                }}
              />
            ),
            Header: (
              <ColumnHeaderFilter
                columnKey="risk"
                name="Risk"
                options={RISK_STATE_OPTIONS}
                setColumnHeaderFilter={handleColumnHeaderFilterChange}
              />
            ),
            size: 30,
          },
          {
            accessorKey: "projectName",
            header: "Project",
            size: 100,
          },


          {
            accessorKey: "assetName",
            header: "Asset",
            size: 100,
            Cell: (params: any) => (
              <Box>
                <Typography variant="body2" sx={{ fontWeight: "bold" }}>
                  {params?.row?.original?.assetName}
                </Typography>
                <Typography variant="caption" sx={{ color: "text.secondary", fontSize: "12px" }}>
                  {params?.row?.original?.operationId}
                </Typography>
              </Box>
            )


          },


          { accessorKey: "findingName", header: "Name", size: 30 },
          {
            accessorKey: "duration",
            header: "Open for",


          },

          {
            accessorKey: "faultGroup",
            header: "Fault Group",
            size: 50,
            Header: (
              <ColumnHeaderFilter
                columnKey="faultGroupId"
                name="Fault Group"
                options={faultGroupsData?.faultGroups.map(
                  (group: any) => ({
                    key: group.id,
                    label: group.name,
                  })
                )}
                setColumnHeaderFilter={handleColumnHeaderFilterChange}
              />
            ),
          },
          {
            accessorKey: "environmentName",
            header: "Environment",
            size: 50,
            Header: (
              <ColumnHeaderFilter
                columnKey="environmentId"
                name="Environment"
                options={environmentData?.environments.map(
                  (environment: any) => ({
                    key: environment.id,
                    label: environment.name,
                  })
                )}
                setColumnHeaderFilter={handleColumnHeaderFilterChange}
              />
            ),
          },
          {
            accessorKey: "status",

            header: "Status",
            size: 30,
            Header: (
              <ColumnHeaderFilter
                columnKey="status"
                name="Status"
                options={STATUS_OPTIONS}
                setColumnHeaderFilter={handleColumnHeaderFilterChange}
              />
            ),
          },

          {
            accessorKey: "state",

            header: "State",
            size: 60,
            Cell: (params: any) => (
              <ActionButton
                issueId={params?.row?.original?.id}
                buttons="STATE"
                risk={params?.row?.original?.risk}
                state={params?.row?.original?.state}
                user={params?.row?.original?.assigneeId}
                jira={{
                  url: params?.row?.original?.jiraUrl,
                  number: params?.row?.original?.jiraNumber,
                }}
              />
            ),


          },


          {
            accessorKey: "updatedAt",
            header: "Last Seen",
            size: 30,
            Cell: (params: any) => (
              <> {moment(params?.row?.original?.updatedAt).format("H:mm DD MMM YYYY ")} </>
            ),
          },
        ]}
        data={rows}


        initialState={{
          density: "compact",
          columnPinning: { left: ['id'] },
        }}
        enableColumnActions={false}
        enableSorting={false}
        enableStickyHeader
        // enableBottomToolbar={false}
        enablePagination={false}
        enableDensityToggle={false}
        enableColumnFilters={false}
        enableTopToolbar={false}
        // enableRowVirtualization
        state={{

          showProgressBars: isFetching || projectsLoading || groupsLoading,
        }}

        muiTableContainerProps={{
          ref: tableContainerRef, //get access to the table container element
          sx: { maxHeight: "calc(100vh - 270px)" }, //give the table a max height
          onScroll: (
            event: UIEvent<HTMLDivElement> //add an event listener to the table container element
          ) => {


            if (!disableInfiniteScroll) {
              fetchMoreOnBottomReached(event.target as HTMLDivElement)
            }

          }

        }}
      />
    </Box>
  );
};


export const GroupProjectBreadcrumb: FC<any> = ({ disableEdit = false , filtersToHide=[]}) => {
  const { groups, projects, changeGroup, changeProject, selectedGroup, selectedProject, groupsLoading, projectsLoading } = usePageContext();
  const {setOverrideBreadcrumb} = useAppState() as IAppState

  useEffect(() => {
    setOverrideBreadcrumb(null)
  }, [setOverrideBreadcrumb])
  
  /**
   * If the edit is disabled, we will not show the dropdowns
   */
  if (disableEdit && false) {
    return <Box sx={{ display: "flex", justifyContent: "start", width: "fit-content", mt: 2 }}>
      {!groupsLoading && filtersToHide?.indexOf("group")<0 &&  !isEmpty(groups) && <Chip
        size="small"
        label={<Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="caption" sx={{ fontWeight: 700 }}>
            Group:
          </Typography>
          <Typography variant="caption" sx={{ ml: 1 }}>
            {selectedGroup?.name}
          </Typography>
        </Box>}
      />
      }
      {!projectsLoading && filtersToHide?.indexOf("project")<0 && !isEmpty(projects) && <Chip
        size="small"
        sx={{ ml: 1 }}
        label={<Box sx={{ display: "flex", alignItems: "center", }}>
          <Typography variant="caption" sx={{ fontWeight: 700 }}>
            Project:
          </Typography>
          <Typography variant="caption" sx={{ ml: 1 }}>
            {selectedProject?.name}
          </Typography>
        </Box>}
      />
      }
    </Box>
  }

  /**
   * If the edit is enabled, we will show the dropdowns
   * and allow the user to change the group and project
   */
  return <Box sx={{ display: "flex", justifyContent: "start", width: "fit-content", mt: 2 }}>
    {!groupsLoading && filtersToHide?.indexOf("group")<0 && !isEmpty(groups) && <Autocomplete
      id="group"
      className="hand"
      options={groups || []}
      getOptionLabel={(option) => option.name}
      value={selectedGroup}
      size="small"
      disabled={disableEdit}
      disableClearable
      onChange={(event, newValue) => {
        changeGroup(newValue?.id)
      }}
      sx={{
        minWidth: "10em",
        width: "fit-content",
        mb: 2,
        textAlign: "left",
      }}
      renderInput={(params) => (
        <TextField {...params} label="Group" sx={{ border: "1px solid #e5e5e5" }} />
      )}
    />}
    {!projectsLoading && filtersToHide?.indexOf("project")<0&& !isEmpty(projects) && <Autocomplete
      id="project"
      options={projects || []}
      className="hand"
      getOptionLabel={(option) => option.name}
      value={selectedProject}
      size="small"
      disabled={disableEdit}
      onChange={(event, newValue) => {
        console.log("CHANGINE")
        if (newValue) {
          
          changeProject(newValue?.id)
        } else {
          
          changeProject(undefined, true)
        }
      }}
      sx={{
        minWidth: "10em",
        width: "fit-content",
        pl: 1,
        mb: 2,

        textAlign: "left",
      }}
      renderInput={(params) => (
        <TextField {...params} label="Project" sx={{ border: "1px solid #e5e5e5" }} />
      )}
    />}
  </Box>
}

export default withRouter(IssuesTable);
