// App.js
import { MenuOpenSharp } from '@mui/icons-material';
import { Box, Divider, Fab, Tooltip } from '@mui/material';
import { filter } from 'lodash';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import LazyLoad from 'react-lazy-load';
import { API_SLUGS } from '../../constants';
import { axiosInstance } from '../../services/axios';
import { useAppState } from '../../store/appState';
import CommonPopper from '../common/commonPopper';
import { formatUrlForContextFilter } from '../dashboard';
import ProjectDetails from './ProjectDetails';
import ProjectsList from './ProjectsList';
import { fi } from 'date-fns/locale';



const Projects: FC<any> = ({ groupId , projectId}) => {
    console.log({groupId, projectId})
    const [selectedProject, setSelectedProject] = useState<any>(1);
    const [projects, setProjects] = useState([] as any);
    const [showHidden, setShowHidden] = useState(true);
    const [isFetchingProjects, setIsFetchingProjects] = useState(false);
    

    useEffect(() => {
        if (groupId) {
            fetchProjects();
        }
    }, [groupId, projectId]);

    const fetchProjects = async () => {
        setIsFetchingProjects(true);
        try {
            let projectsResponse = await axiosInstance.get(
                formatUrlForContextFilter(API_SLUGS.PROJECTS, groupId || "")
            );
            if(projectId){
                setSelectedProject(projectId);
                setProjects(filter(projectsResponse.data?.projects, (project: any) => project?.id === projectId));
                return
            }

            setProjects(projectsResponse.data?.projects);
            setSelectedProject(projectsResponse.data?.projects[0]?.id);
        } catch (error: any) { }
        setIsFetchingProjects(false);
    };

    const rightPanelRef = useRef(null);

    const handleProjectSelect = (projectId: any) => {
        scrollToProjectById(projectId)
        setSelectedProject(projectId);
    };

    const handleRightPanelScroll = () => {
        // Get the scroll position of the right panel
        //@ts-ignore
        const scrollPosition = rightPanelRef?.current?.scrollTop;


        // Determine the project based on the cumulative height of project details
        const projectDetailsBlocks = document.querySelectorAll('.project-details-block');
        let cumulativeHeight = 0;
        let projectId: any = null;

        projectDetailsBlocks.forEach((block, index) => {
            cumulativeHeight += block.clientHeight;

            if (cumulativeHeight > scrollPosition + 100 && projectId === null) {
                projectId = projects[index]?.id;
            }
        });

        // Update the selected project
        setSelectedProject(projectId);
    };



    const scrollToProjectById = (projectId: any) => {
        // Scroll to the selected project
        const projectDetailsBlocks = document.querySelectorAll('.project-details-block');
        const index = projects.findIndex((project: any) => project.id === projectId);

        const projectDetailsBlock = projectDetailsBlocks[index];
        if (projectDetailsBlock) {
            //@ts-ignore
            const scrollPosition = projectDetailsBlock?.offsetTop;
            //@ts-ignore
            rightPanelRef?.current?.scrollTo({ top: scrollPosition - 300, behavior: 'smooth' });
        }
    };


    useEffect(() => {
        // Sync the left panel with the right panel's scroll position on mount
        handleRightPanelScroll();
    }, []);

    

    return (



        <Box sx={{ maxHeight: "calc(100vh - 200px)", overflow: "auto" }} onScroll={handleRightPanelScroll} ref={rightPanelRef}>
            {projects.map((project: any, index: number) => (
                <Box>
                    <LazyProjectComponent project={project} index={index} projects={projects} />
                </Box>
            ))}

            <Box sx={{ position: "fixed", bottom: 30, right: 30 }}>
                <CommonPopper
                    placement="top-end"
                    trigger={
                        <Tooltip title="Projects Navigation">
                            <Fab color="secondary" aria-label="Projects">
                                <MenuOpenSharp />
                            </Fab>
                        </Tooltip>
                    }
                    child={
                        <Box sx={{ minWidth: "20em" }}>
                            <ProjectsList projects={projects} onSelect={handleProjectSelect} selectedProject={selectedProject} />
                        </Box>
                    }
                />

            </Box>

        </Box>



    );
}

const LazyProjectComponent: FC<any> = ({ project, index, projects }) => {
    const [height, setHeight] = useState<any>(762);
    return <LazyLoad offset={100} height={height} onContentVisible={() => { console.log(`${project.id} visible now`); setHeight(undefined); }}>
        <Box>
            <ProjectDetails
                index={index}
                key={project.id}

                project={project}
            />
            {projects?.length !== index + 1 && (
                <Divider sx={{ pt: 1, my: 5 }} />
            )}
        </Box>
    </LazyLoad>
}



export default Projects;
