import {
  Avatar,
  Box, Button, Card,
  CardContent, Checkbox,
  Chip, Container, Modal,
  Typography
} from "@mui/material";
import { filter, map } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { API_SLUGS } from "../../constants";
import { axiosInstance } from "../../services/axios";
import {
  INotificationState,
  useNotificationState
} from "../../store/notificationState";
import MessageModal from "./MessageModal";
var format = require("string-template");

interface IMessage {
  id: string;
  title: string;
  status: "read" | "unread";
  createdAt: string;
}

const Inbox: React.FC<RouteComponentProps> = ({ history }) => {

  const { messages, fetchMessages, shouldShowMoreOption, fetchMessageSummary } = useNotificationState(
    (state) => state
  ) as INotificationState;
  const [open, setOpen] = useState(false);
  const [isFetchingMessages, setIsFetchingMessages] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null as null | string);
  const [checkedMap, setCheckedMap] = useState({} as any);
  const [showSelected, setShowSelected] = useState(false);


  useEffect(() => {
    setIsFetchingMessages(true);
    fetchMessages();
    setIsFetchingMessages(false);
  }, []);



  useEffect(() => {
    let ids = [];
    for (let key in checkedMap) {
      if (checkedMap[key]) {
        ids.push(key);
      }
    }
    if (ids.length) {
      setShowSelected(true);
    } else {
      setShowSelected(false);
    }
  }, [checkedMap]);

  const handleOpenMessageModal = (id: string) => {
    setSelectedMessage(id);
    setOpen(true);
  };

  const handleCloseMessageModal = () => {
    fetchMessages();
    setSelectedMessage(null);
    setOpen(false);
  };

  const markMessagesRead = async () => {
    try {
      let ids = [];
      for (let key in checkedMap) {
        if (checkedMap[key]) {
          ids.push(key);
        }
      }
      await axiosInstance.patch("/message", { ids: ids, status: "read" });
      setCheckedMap({});

      fetchMessageSummary()
    } catch (error) { }
  };

  const markMessageRead = async (id: string) => {
    try {
      await axiosInstance.patch(format(API_SLUGS.MARK_MESSAGE, { uuid: id }), {
        status: "read",
      });
      fetchMessageSummary()
    } catch (error) { }
  };




  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: '100%',
        }}
      >
        <Container maxWidth="xl" sx={{ p: 3, textAlign: "left" }}>
          <Box
            sx={{
              alignItems: "center",
              display: "flex",
              justifyContent: "space-between",
              mb: 3,
            }}
          >
            <div>
              <Typography color="textPrimary" gutterBottom variant="h4">
                Inbox
              </Typography>
            </div>
          </Box>

          <Checkbox
            onChange={({ target }) => {
              const ids = map(filter(messages, { status: "unread" }), "id");
              let newCheckedMap: any = {};
              ids.map((o) => {
                newCheckedMap[o] = target.checked;
              });
              setCheckedMap(newCheckedMap);
            }}
          />
          <Button
            sx={{ textAlign: "left", mb: 3 }}
            variant="outlined"
            onClick={() => {
              markMessagesRead();
              fetchMessages();
            }}
            disabled={!showSelected}
          >
            Mark Read
          </Button>


          {(
            messages.map((message) => (
              <Box sx={{ display: "flex", justifyContent: "start" }}>
                <Box>
                  <Checkbox
                    disabled={message?.status === "read"}
                    checked={checkedMap[message?.id] || false}
                    onChange={({ target }) =>
                      setCheckedMap({
                        ...checkedMap,
                        [message?.id]: target.checked,
                      })
                    }
                  />
                </Box>
                <Card
                  elevation={message?.status === "read" ? 0 : 12}
                  sx={{
                    width: "100%",
                    backgroundColor:
                      message?.status !== "read"
                        ? "background.paper"
                        : "background.default",
                    mb: 2,
                    border: "1px solid #E5E5E5",
                  }}

                // onClick={() => handleOpenMessageModal(message?.id)}
                >
                  <CardContent
                    sx={{
                      display: "flex",
                      // flexDirection: "column",
                      justifyContent: "space-between",
                      p: 2,
                    }}
                  >
                    <Box sx={{ display: "inline" }}>
                      <Box
                        key={message?.id}
                        sx={{
                          alignItems: "center",
                          display: "flex",
                          pt: 1,
                        }}
                      >
                        <Avatar
                          sx={{
                            backgroundColor: "primary.main",
                            color: "common.blue",
                          }}
                        >
                          {message?.title[0]}
                        </Avatar>
                        <Typography
                          color="textPrimary"
                          sx={{ ml: 2 }}
                          variant="body2"
                        >
                          {message?.title}
                          <br />
                          <Typography
                            color="textPrimary"
                            sx={{ ml: "auto" }}
                            variant="caption"
                          >
                            {message?.content}
                          </Typography>
                          <br />
                          <Typography
                            color="textPrimary"
                            sx={{ ml: "auto" }}
                            variant="caption"
                          >
                            {moment(message?.createdAt).fromNow()}
                          </Typography>
                        </Typography>

                      </Box>
                    </Box>

                    <Box sx={{ display: "inline", pt: 3 }}>

                      {message?.type === "issue" ? (
                        <Chip
                          size="small"
                          sx={{ mr: 2 }}
                          variant="filled"
                          color="primary"
                          onClick={() => {
                            markMessageRead(message.id);
                            history.push(
                              `/findings?issueId=${message?.actionPayload?.id}`
                            );
                          }}
                          label="Open Issue"
                        />
                      ) : null}
                      {message?.type === "result" ? (
                        <Chip
                          size="small"
                          sx={{ mr: 2 }}
                          variant="filled"
                          color="primary"
                          onClick={() => {
                            markMessageRead(message.id);
                            history.push(
                              `/findings?resultId=${message?.actionPayload?.id}`
                            );
                          }}
                          label="Open Result"
                        />
                      ) : null}
                    </Box>

                  </CardContent>
                </Card>
              </Box>
            ))
          )}
          {shouldShowMoreOption && <Button variant="text" onClick={() => fetchMessages(messages?.length)}>Show older messages</Button>}
          <Modal open={open} onClose={handleCloseMessageModal}>
            <MessageModal
              onClose={handleCloseMessageModal}
              selectedMessage={selectedMessage!}
            />
          </Modal>
        </Container>
      </Box>
    </>
  );
};

export default withRouter(Inbox);
