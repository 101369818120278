import { Close } from "@mui/icons-material";
import { Box, Container, Paper } from "@mui/material";
import { FC } from "react";
import OperationView from "../generators/OperationView";

interface IOperationModal {
  selectedOperation: IOperationDetails;

  onClose: () => void;
}

interface IOperationDetails {
  assetId: string;
  revId: string;
  defaultOperationId?: string | null;
}

const OperationModal: FC<IOperationModal> = ({
  selectedOperation,
  onClose,
}) => {
  return (
    <Box
      sx={{
        minHeight: "100%",
        p: 3,
      }}
    >
      <Container maxWidth="lg">
        <Paper elevation={12} sx={{ p: 3 }}>
          <Box>
            <Close className="hand" onClick={onClose} />
          </Box>
          <Box sx={{ padding: "auto", height: "80vh", overflow: "scroll" }}>
            <OperationView assetId={selectedOperation.assetId} revId={selectedOperation.revId} defaultOperationId={selectedOperation.defaultOperationId as string | undefined} />
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default OperationModal;
