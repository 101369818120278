import {
    Box, Container, Typography
} from '@mui/material';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import AiSuggestions from '../../common/aiSuggestions';
import ResultsTable from './ResultsTable';
import { ISidebarState, useSidebarState } from '../../../store/sidebarState';


const Results: React.FC<RouteComponentProps> = ({ history, match }) => {
    const {  context } = useSidebarState(
        (state) => state
    ) as ISidebarState;



    return (
        <>
            <Box
                sx={{
                    backgroundColor: 'background.paper',
                    display: 'flex',
                    flexDirection: 'column',
                    minHeight: '100%',
                }}
            >

                <Container

                    maxWidth='xl'
                    sx={{ p: 1 }}
                >
                    <Box
                        sx={{
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'space-between',

                        }}
                    >

                        <div>
                            <Typography
                                color="textPrimary"
                                gutterBottom
                                variant="h4"
                            >
                                Runs
                            </Typography>
                        </div>


                    </Box>



                    <Box
                        sx={{
                            flexGrow: 1,
                        }}
                    >
                        {/* {context?.organizationId && <AiSuggestions prompt="List completed distinct results with findings of severity critical" scope={{ organization_uuid: context?.organizationId }} />} */}
                        <ResultsTable />
                    </Box>

                </Container>
            </Box>
        </>
    );
};


export default withRouter(Results);
