import {
    Autocomplete, Box, Button, Card,
    Dialog,
    DialogTitle,
    Link,
    TextField
} from '@mui/material';
import moment from "moment";
import { FC, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getConfigs } from '../../../configs';
import { API_SLUGS } from '../../../constants';
import { ExternalLink } from "../../../icons/external-link";
import { axiosInstance } from '../../../services/axios';
import { IAlertState, useAlertState } from '../../../store/alertState';
import { b64DecodeUnicode, parseHermitConfig } from '../../../utils/hermitConfigUtils';
import { DataCard } from '../../dashboard';
import { CommitConfigForm } from '../CommitConfigForm';
import { AllowedDomains } from './AllowedDomains';

const JobsTable: FC<RouteComponentProps> = ({ history, match }) => {
    const [jobSpec, setJobSpec] = useState({ name: '', assetId: '', createdAt: '', config: '' });
    const [jobId, setJobId] = useState('');
    const [selectedToken, setSelectedToken] = useState('');
    const [runDialogOpen, setRunDialogOpen] = useState(false);
    const [logDialogOpen, setLogDialogOpen] = useState(false);
    const [logDialogText, setLogDialogText] = useState('');
    const { setMessage, clearMessage } = useAlertState(state => state) as IAlertState;
    const [configs, setConfigs] = useState([]);

    useEffect(() => {
        fetchAccessTokens();
        fetchConfigsList();
    }, [])

    const handleRunDialogClose = () => {
        setRunDialogOpen(false);
    }

    const handleRunDialogOpen = async (jobId: string) => {
        setJobId(jobId);
        await fetchJobSpec(jobId);
        setRunDialogOpen(true);
    }

    const handleLogDialogOpen = (text: string) => {
        setLogDialogText(text);
        setLogDialogOpen(true);
    }

    const handleLogDialogClose = () => {
        setLogDialogOpen(false);
    }

    const fetchJobSpec = async (jobId: string) => {
        try {
            const jobResponse = await axiosInstance.get(`/jobs/${jobId}`);
            const job = jobResponse.data;
            setJobSpec(job);
        } catch (error: any) {
            console.log(error);
        }
    }

    const fetchAccessTokens = async () => {
        try {
            const tokensResponse = await axiosInstance.get(`${getConfigs().baseApiUrl}/user/accesstokens`);
            const tokens = tokensResponse.data?.accessTokens;
            if (tokens.length) {
                setSelectedToken(tokens[0].id);
            }
        } catch (error: any) {
            console.log(error);
        }
    }


    const fetchConfigsList = async () => {
        try {
            let configsResponse = await axiosInstance.get(API_SLUGS.RUN_CONFIGURATIONS);
            setConfigs(configsResponse.data?.hermitConfigs)
        } catch (error: any) {
            console.log(error);
        }
    }


    const runJob = async (config: any, templateVars = '') => {
        clearMessage();
        try {
            await axiosInstance.post(`${getConfigs().baseApiUrl}/jobs/${jobId}/runs`, {
                jobId,
                config: btoa(JSON.stringify(config)),
                ...(templateVars && { envVars: btoa(templateVars) }),
                tokenId: selectedToken
            }).then(() => { setMessage({ title: "Remote Sift job execution has been started", type: "success" }); })
                .catch((error: any) => {
                    if (error.response && error.response.data) {
                        setMessage({ title: error.response.data.message, type: "error" });
                    }
                });
            setTimeout(() => history.go(0), 2000);
            // fetchJobSpec(jobId);
        } catch (error: any) {
            console.log(error);
        }
    };



    return (<Card elevation={0} sx={{ textAlign: "left" }}>

        <Box sx={{ ml: 1, mt: 2, mb: 2, display: 'flex', justifyContent: 'flex-start' }}>
            <Autocomplete
                options={configs.map((config: any) => (config.id))}
                getOptionLabel={option => configs.filter((config: any) => config.id === option).map((config: any) => `${config.name} (${config.projectName} / ${config.apiName})`).join()}
                sx={{ width: "70%", textAlign: 'left' }}
                size="medium"
                onChange={(e, value) => {
                    setJobId(value);
                }}
                value={jobId || ''}
                renderInput={(params): JSX.Element => {
                    return <TextField
                        name="configId"
                        variant="outlined"
                        label="Config"
                        {...params}
                    />
                }}
            />
            <Button
                color="primary"
                size="medium"
                sx={{ ml: 2 }}
                variant="contained"
                onClick={() => handleRunDialogOpen(jobId)}
            >
                New run
            </Button>
        </Box>
        <AllowedDomains />
        <DataCard name={"Jobs"} config={{
            "widget": "table",
            "width": 8,
            "data_source": `${getConfigs().baseApiUrl}/jobs/runs`,
            "data_points": [
                { "key": "id", "jsonpath": "$[*].id" },
                { "key": "jobName", "jsonpath": "$[*].configName" },
                { "key": "projectName", "jsonpath": "$[*].projectName" },
                { "key": "firstName", "jsonpath": "$[*].firstName" },
                { "key": "lastName", "jsonpath": "$[*].lastName" },
                { "key": "apiName", "jsonpath": "$[*].apiName" },
                { "key": "started", "jsonpath": "$[*].started" },
                { "key": "resultId", "jsonpath": "$[*].resultId" },
                { "key": "hermitLog", "jsonpath": "$[*].hermitLog" },
                { "key": "status", "jsonpath": "$[*].status" }
            ],
            "pagination": false,
            "columns": [

                { key: "id", name: "ID", type: "hidden" },
                { key: "firstName", name: "firstName", type: "hidden" },
                { key: "lastName", name: "lastName", type: "hidden" },
                { key: "resultId", name: "resultId", type: "hidden" },
                { key: "hermitLog", name: "hermitLog", type: "hidden" },
                { key: "status", name: "status", type: "hidden" },
                { key: "started", name: "started", type: "hidden" },
                {
                    key: "jobName", name: "Name", type: "string", onClick: (row: any) => {
                        history.push(`/jobs/${row?.id}`)
                    }
                },
                {
                    key: "projectName", name: "Project", type: "string"
                },
                {
                    key: "apiName", name: "API", type: "string"
                },
                {
                    key: "lastRunTime", name: "Last run", type: "custom",
                    code: (row: any) => row.started ? moment(row.started).fromNow() : ''
                },
                {
                    key: "lastRunResult",
                    name: "Result",
                    type: "custom",
                    code: (row: any) => {
                        switch (row.status) {
                            case 'complete':
                                return row.resultId ? (<Box sx={{ pr: 1, display: "inline-block" }}>
                                    <Link color="inherit" href={`/findings?resultId=${row.resultId}`}><ExternalLink fontSize="inherit" />{row.resultId.split("-")[0]}</Link>
                                </Box>) : '';
                                break;
                            case 'failed':
                                return row.hermitLog ? (<Box sx={{ pr: 1, display: "inline-block" }}>
                                    <Link color="inherit" sx={{ cursor: 'pointer' }} onClick={() => handleLogDialogOpen(b64DecodeUnicode(row.hermitLog))}><ExternalLink fontSize="inherit" /> Failed</Link>
                                </Box>) : '';
                                break;
                            default:
                                return ''
                        }
                    }
                },
                {
                    key: "user", name: "Started by", type: "custom", code: (row: any) => (row.firstName || row.lastName) ? `${row.firstName} ${row.lastName}` : ''
                },
                {
                    key: "Buttons",
                    name: "",
                    type: "custom",
                    code: (row: any) => (<>
                        <Link sx={{ cursor: 'pointer' }} onClick={() => handleRunDialogOpen(row.id)}>Run
                        </Link><br />
                        <Link sx={{ cursor: 'pointer' }} onClick={() => history.push(`/jobs/${row?.id}`)}>History
                        </Link><br />
                        <Link sx={{ cursor: 'pointer' }} onClick={() => history.push(`/editConfig/listJobs/${row.id}`)}>Edit
                        </Link>
                    </>)
                }
            ],
        }} />
        <Dialog fullWidth maxWidth="md" onClose={handleRunDialogClose} open={runDialogOpen}>
            <Box sx={{ m: 3 }}>
                <CommitConfigForm hermitConf={parseHermitConfig(jobSpec.config)} onSubmit={handleRunDialogClose} runJob={runJob} />
            </Box>
        </Dialog>
        <Dialog fullWidth maxWidth="md" onClose={handleLogDialogClose} open={logDialogOpen}>
            <DialogTitle>Error log</DialogTitle>
            <Box sx={{ m: 3 }}>
                {logDialogText}
            </Box>
        </Dialog>

    </Card >
    )
};

export default withRouter(JobsTable);
