import { HistoryRounded } from "@mui/icons-material";
import {
  Box, ClickAwayListener,
  Grow,
  IconButton,
  Paper,
  Popper, Tooltip, Typography
} from "@mui/material";
import { lowerCase } from "lodash";
import React, { useMemo } from "react";
import { withRouter } from "react-router-dom";
import { getConfigs } from "../../../configs";
import ResultsTable from "../../analysis/ViewResults/ResultsTable";
// import { ArrowDropDownIcon } from '@mui/icons-material';

const RunHistoryPopup: React.FC<any> = ({ history, assetId, sx, toolInfo = undefined, environmentId = undefined }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const resultsUrl = useMemo(() => {
    let url = `${getConfigs().baseApiUrl
      }/results?assetId=${assetId}`

    if (toolInfo) {
      url += `&toolName=${toolInfo?.name}`
    }

    if (toolInfo) {
      url += `&type=${lowerCase(toolInfo?.type)}`
    }

    if (environmentId) {
      url += `&environmentId=${environmentId}`
    }

    return url

  }, [assetId, toolInfo, environmentId])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <Box>
      <Box sx={{ width: "100%", textAlign: "left", ...sx }} ref={anchorRef}>
        <Tooltip title="Run History"><IconButton sx={{ py: 0.5, height: "fit-content" }} onClick={handleToggle}><HistoryRounded sx={{ mr: 1 }} /></IconButton></Tooltip>

      </Box>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom"
        id="popper-add-universal"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              zIndex: 10,
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper elevation={20}>
              <ClickAwayListener onClickAway={handleClose}>
                <Box sx={{ maxWidth: "50em", maxHeight: "30em", overflow: "auto", p: 2 }}>
                  <Typography variant="h5" sx={{ pl: 1, pb: 2 }}>
                    Run History
                  </Typography>
                  <ResultsTable toolInfo={toolInfo} environmentId={environmentId} disableFilters={true} />


                </Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default withRouter(RunHistoryPopup);
