import {
  Box, ClickAwayListener,
  Grow,
  Paper,
  Popper
} from "@mui/material";
import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";

const CommonPopper: React.FC<any> = ({ history, trigger, child, placement, elevatePopper = false, disableHideOnClick = false, defaultToggleState = false, onToggle = undefined }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [hidden, setHidden] = React.useState(false)

  const handleToggle = () => {
    if (hidden) {
      setHidden(false)
      setOpen(true)
      if (onToggle) onToggle(true)
    } else {
      if (onToggle) onToggle(!open)
      setOpen((prevOpen: boolean) => !prevOpen);

    }

  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
    if (onToggle) onToggle(false)
  };

  useEffect(() => {
    setTimeout(() => {
      if (defaultToggleState) {
        setOpen(true)
      }
    }
      , 200)
  }, [defaultToggleState])

  return (
    <Box sx={{ display: "inline" }}>
      <Box
        sx={{ width: "100%", display: "inline" }}
        ref={anchorRef}
        aria-label="split button"
        onClick={handleToggle}
      >
        {trigger}
      </Box>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={hidden ? { display: "none", zIndex: -1 } : elevatePopper ? { zIndex: 9999 } : {}}
        id="popper-add-universal"
        placement={placement ? placement : "bottom-end"}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            in={open}
            timeout={defaultToggleState ? 0 : 200}
            
            {...TransitionProps}
            style={{
              zIndex: 10,
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper elevation={20} onClick={(e) => {
              if (disableHideOnClick) return
              //@ts-ignore
              setHidden(true)
            }}>
              <ClickAwayListener onClickAway={(e) => {
                if (disableHideOnClick) return
                handleClose(e)
              }}>
                <Box>{open && child}</Box>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default withRouter(CommonPopper);
