import {
  Box, Container, LinearProgress,
  Typography
} from "@mui/material";
import { useMemo } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import Projects from "..";
import useFetch from "../../../hooks/http/useFetch";
import SplitButton from "../../common/tree/button";
import { IAppState, useAppState } from "../../../store/appState";

const Analyzers: React.FC<RouteComponentProps> = ({ history }) => {


  const { data: groups, loading } = useFetch("/group");

  const groupId = useMemo(() => {
    const paramGroupId = new URLSearchParams(history.location.search).get("groupId");
    if (paramGroupId) {
      localStorage.setItem("groupId", paramGroupId);
      return paramGroupId;
    }
    const previousGroupId = localStorage.getItem("groupId");
    if (previousGroupId) {
      return previousGroupId;
    }

    return groups?.groups?.[0]?.id;
  },
    [groups?.groups, history.location.search])
    const {breadcrumb} = useAppState((state) => state) as IAppState
  
  

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.paper",
          display: "flex",
          // flexDirection: "column",
          minHeight: '100%',
        }}
      >
        <Container maxWidth="lg" sx={{ p: 3 }}>

          <Box
            sx={{
              alignItems: "left",
              display: "flex",
              justifyContent: "start",
              mb: 3,
            }}
          >
            {/**Group name */}


            <Box sx={{ display: "flex", justifyContent: "start", alignItems: "left", ml: 1.5 }}>


              <Typography
                color="textPrimary"
                gutterBottom
                variant="h5"
                sx={{ textAlign: "left", mr: 2 }}
              >
                Projects
              </Typography>
              <SplitButton groupId={groupId} />
            </Box>


          </Box>
          {loading ? <LinearProgress /> : null}

          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            <Projects groupId={groupId} projectId={breadcrumb?.project?.id}/>
            {/* <ResultsTable groupId={groupId} /> */}
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default withRouter(Analyzers);
