export const config: IConfigs = {
    // baseApiUrl: [window.location.protocol , window.location.hostname].join("//"),
    baseApiUrl: process.env.REACT_APP_BASE_API_URL || "https://alpha.aptori.dev/api/v1",
    slackConnectRedirectUrl: process.env.REACT_APP_SLACK_URL || 'https://slack.com/oauth/v2/authorize?client_id=1408945725125.1855888236096&scope=channels:join,chat:write,chat:write.public,commands,groups:history,groups:read,groups:write,incoming-webhook,im:write&user_scope=im:write',
    swrlDocsUrl: 'https://docs.swrl.ai',
    mixpanelToken: process.env.REACT_APP_MIXPANEL_TOKEN || "",
    sentryDsn: process.env.REACT_APP_SENTRY_DSN || "",
    experimentalFeatures: process.env.REACT_APP_EXPERIMENTAL_FEATURES === 'true',
    isOmnibus: process.env.REACT_APP_OMNIBUS === 'true'
}

