import {
  Alert,
  Box,
  Button,
  Grid,
  TextField
} from '@mui/material';
import { isEmpty } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { STRINGS } from '../../../../constants';
import { axiosInstance } from '../../../../services/axios';
import { IAlertState, useAlertState } from '../../../../store/alertState';

interface IError {
  error: boolean
  message: string
}

interface IAddGitlabFormError {

  url?: IError
  accessToken?: IError
}

interface IAddGitlabFormValues {

  url: string
  accessToken: string
  isActive?: boolean
}

interface IGitlab {
  id: string;
}
interface IAddForm extends RouteComponentProps {
  updateOnly?: boolean
  defaultFormValues?: any
  updateUUID?: string,
  setGitlabStatus: any
}



const GitlabIntegrationForm: FC<IAddForm> = ({ history, match, defaultFormValues, updateUUID, setGitlabStatus }) => {

  const [formValues, setFormValues] = useState({ url: "", accessToken: "", isActive: false, ...defaultFormValues } as IAddGitlabFormValues)
  const [formErrorValues, setFormErrorValues] = useState({} as IAddGitlabFormError)
  const { setMessage } = useAlertState(state => state) as IAlertState
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState(STRINGS.EMPTY)
  const [successMessage, setSuccessMessage] = useState(STRINGS.EMPTY)
  const [updateOnly, setUpdateOnly] = useState(false)


  useEffect(() => {
    fetchGitlabIntegration()
  }, [])

  const fetchGitlabIntegration = async () => {
    try {
      let response = await axiosInstance.get('/cloudintegration')
      setFormValues({ ...formValues, ...response?.data?.gitlab })
      setGitlabStatus(response?.data?.gitlab?.isActive)
      if (!isEmpty(response?.data)) {
        setUpdateOnly(true)
      }
    } catch (error) {

    }

  }

  const handleChange = async (value: any, type: "url" | "accessToken") => {
    let newValues = { ...formValues, [type]: value }


    setFormValues(newValues)
  }
  const handleSubmit = async () => {

    if (updateOnly) {

      if (formValues?.url.trim() != "") {
        await updateGitlab(formValues?.url.trim())
      }
      else {
        setErrorMessage("Please fill all the mandatory fields")
      }
    } else {
      if (formValues?.url.trim() != "") {
        await addGitlab(formValues?.url.trim())
      }
      else {
        setErrorMessage("Please fill all the mandatory fields")
      }
    }
  };

  const addGitlab = async (name: string) => {
    try {
      let response = await axiosInstance.post('/cloudintegration', { type: "gitlab", details: formValues })

      setMessage({ title: "Gitlab details have been saved", type: "success" })
    } catch (error: any) {
      try { setErrorMessage(error.response?.data?.message) } catch (error: any) { }
    }
  };

  const updateGitlab = async (name: string) => {
    try {
      let response = await axiosInstance.patch('/cloudintegration', { type: "gitlab", details: formValues })

      setMessage({ title: "Gitlab details have been saved", type: "success" })

    } catch (error: any) {
      try { setErrorMessage(error.response?.data?.message) } catch (error: any) { }
    }

  };

  return (
    <Grid
      item
      container
      spacing={3}
    >


      <Grid
        item
        xs={12}
      >
        <TextField
          sx={{ maxWidth: "50%", textAlign: 'left' }}
          fullWidth
          name="Gitlab"
          label="Url"
          value={formValues?.url}
          onChange={(e: any) => { handleChange(e.target.value, 'url'); }}
          required
          variant="outlined"
        />

      </Grid>

      <Grid
        item
        xs={12}
      >
        <TextField
          sx={{ maxWidth: "50%", textAlign: 'left' }}
          fullWidth
          label="Access token"
          name="Gitlab"
          value={formValues?.accessToken}
          onChange={(e: any) => { handleChange(e.target.value, 'accessToken'); }}
          required
          variant="outlined"
        />

      </Grid>



      <Grid item xs={12} sx={{ padding: 0 }}>
        {successMessage && successMessage.trim() !== STRINGS.EMPTY ?
          <Box sx={{ mt: 2 }}>
            <Alert severity="success" sx={{ width: "fit-content" }} >
              <div>
                {successMessage}
              </div>
            </Alert>
          </Box> :
          errorMessage && errorMessage.trim() !== STRINGS.EMPTY ? <Box sx={{ mt: 2 }}>
            <Alert severity="error" sx={{ width: "fit-content" }}>
              <div>
                {errorMessage}
              </div>
            </Alert>
          </Box> : null}


        <Box
          sx={{
            display: 'flex',
          }}
        >
          {
            successMessage && successMessage.trim() ? null : <Button
              color="primary"
              size="large"
              variant="contained"
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              Save
            </Button>}
        </Box>
      </Grid>

    </Grid>
  );
};


export default withRouter(GitlabIntegrationForm);
