

import { find, isEmpty } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { API_SLUGS } from "../../constants";
import { IAppState, useAppState } from "../../store/appState";
import useFetch from "../http/useFetch";
const format = require("string-template");


export const usePageContext = () => {
    const setBreadcrumb = useAppState(
        //@ts-ignore
        state => state.setBreadcrumb);
    const breadcrumb = useAppState(
        //@ts-ignore
        state => state.breadcrumb);
    const overrideBreadcrumb = useAppState(
        //@ts-ignore
        state=> state.overrideBreadcrumb);
    const { data: groups, loading: groupsLoading } = useFetch("/group");
    const [groupId, setGroupId] = useState<string | null>(null);
    const projectUrl = useMemo(() => groupId? format(API_SLUGS.PROJECTS_BY_GROUP, {groupId: groupId}): undefined, [groupId]);
    const [projectId, setProjectId] = useState<string | null>(null);
    const {data: projects, loading: projectsLoading } = useFetch(projectUrl)
    const selectedGroup = useMemo(() => find(groups?.groups, {id: overrideBreadcrumb?.group?.id|| groupId}), [groups, groupId, overrideBreadcrumb

    ]);
    const selectedProject = useMemo(() => {
        const projectFound = find(projects?.projects, {id: overrideBreadcrumb?.project?.id || projectId})
  
        return projectFound
    }, [projects, projectId,overrideBreadcrumb]);
    
    const newBreadcrumb = useMemo(() => {
        
        if(groupsLoading || projectsLoading) {
            return
        }
        if(!selectedGroup || !selectedProject) {
            return 
        }
        console.log({selectedGroup, selectedProject})
        console.log("newBreadcrumb")
        let breadcrumb = {};
        if (selectedGroup) {
            breadcrumb = { ...breadcrumb, group: selectedGroup };
        }
        if (selectedProject) {
            breadcrumb = { ...breadcrumb, project: selectedProject };
        }
        return breadcrumb;
    }, [selectedGroup, selectedProject, projectsLoading, groupsLoading]);

    const changeGroup = useCallback((id?: string)=> {
        let newGroupId = ""
        if(id) { 
            newGroupId = ""
        } else {
            const localStoragedGroupId = localStorage.getItem("groupId");
            
            if(localStoragedGroupId) {
                newGroupId = localStoragedGroupId
            }       
        } 
        let group = find(groups?.groups, {id: newGroupId})
        if(group){
            setGroupId(newGroupId)
        }else{
            setGroupId(groups?.groups?.[0]?.id)
        }
    }, [groups]);



    const changeProject = useCallback((id?: string, remove=false)=> {
        
        if(remove) {
            
            localStorage.removeItem("projectId")
            setProjectId(null)
            let newBreadcrumb = {...breadcrumb, project: null}
            setBreadcrumb(newBreadcrumb)
            return
        }
        
        let newProjectId = ""
        if(id) {
            
            newProjectId = id
        } else {
            const localStoragedProjectId = localStorage.getItem("projectId");
            if(localStoragedProjectId) {
                
                newProjectId=localStoragedProjectId
            } 
        }
        let project = find(projects?.projects, {id: newProjectId})
        if(project){
            setProjectId(newProjectId)
        // }else{
        //    setProjectId(projects?.projects?.[0]?.id)
        }

    }, [projects]);

    useEffect(() => {
        
        if(groupId) {
            
            localStorage.setItem("groupId", groupId || "");
        }
    }, [groupId]);

    useEffect(() => {
        if(projectId) {
            localStorage.setItem("projectId", projectId || "");
        }
    }, [projectId]);
    

    useEffect(() => {
        if(!isEmpty(projects?.projects)) {
            changeProject();
        }
    }, [projects, changeProject, groupId]);
    

    useEffect(() => {
        if(!isEmpty(groups?.groups)) {
            changeGroup();
        }
    }, [groups, changeGroup]);

    useEffect(() => {
    
        if(!isEmpty(newBreadcrumb)){
            setBreadcrumb(newBreadcrumb);
        }
    }, [newBreadcrumb, setBreadcrumb]);
    
    
    return {selectedGroup,
    selectedProject,
        groupsLoading,
        projectsLoading,

        
         groups: groups?.groups|| [], projects: projects?.projects|| [], changeGroup, changeProject};
};
