import {
  Alert,
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { API_SLUGS, HELPER_TEXTS, STRINGS } from "../../../constants";
import { axiosInstance } from "../../../services/axios";
var format = require("string-template");

interface IError {
  error: boolean;
  message: string;
}

interface IAddPlatformKeyFormError {
  platformKey?: IError;
  expiry?: IError;
}

interface IAddPlatformKeyFormValues {
  platformKey: string;
  expiry: number;
}

interface IPlatformKey {
  id: string;
}
interface IAddForm extends RouteComponentProps {
  updateOnly?: boolean;
  defaultFormValues?: any;
  updateUUID?: string;
  callback?: () => void;
}

const AddPlatformKeyForm: FC<IAddForm> = ({
  history,
  match,
  updateOnly = false,
  defaultFormValues,
  updateUUID,
  callback = undefined
}) => {
  const [formValues, setFormValues] = useState({
    platformKey: "",
    expiry: 1,
    ...defaultFormValues,
  } as IAddPlatformKeyFormValues);
  const [formErrorValues, setFormErrorValues] = useState({
    platformKey: {},
  } as IAddPlatformKeyFormError);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(STRINGS.EMPTY);
  const [successMessage, setSuccessMessage] = useState(STRINGS.EMPTY);

  useEffect(() => {
    let newDefaultFormValues = { ...formValues };
    if (!updateOnly) {
      setFormValues(newDefaultFormValues);
    }
  }, []);

  const handleChange = async (value: any, type: "platformKey" | "expiry") => {
    let newValues = { ...formValues, [type]: value };

    setFormValues(newValues);
  };
  const handleSubmit = async () => {
    if (updateOnly) {
      if (formValues?.platformKey.trim() != "") {
        await updatePlatformKey(formValues?.platformKey.trim());
      } else {
        setErrorMessage("Please fill all the mandatory fields");
      }
    } else {
      if (formValues?.platformKey.trim() != "") {
        await addPlatformKey(
          formValues?.platformKey.trim(),
          moment().add(formValues?.expiry, "months").toISOString()
        );
      } else {
        setErrorMessage("Please fill all the mandatory fields");
      }
    }
    if (callback) {
      callback()
    }
  };

  const addPlatformKey = async (name: string, expiresAt: string) => {
    try {
      let platformKeyResponse = await axiosInstance.post(
        API_SLUGS.ADD_PLATFORM_KEY,
        { name: name, expiresAt }
      );

      setSuccessMessage(
        `Access Token has been created. Please copy your token: ${platformKeyResponse.data?.accessToken} `
      );
    } catch (error: any) {
      try {
        setErrorMessage(error.response?.data?.message);
      } catch (error: any) { }
    }
  };

  const updatePlatformKey = async (name: string) => {
    try {
      setSuccessMessage("Access Token has been added");
    } catch (error: any) {
      try {
        setErrorMessage(error.response?.data?.message);
      } catch (error: any) { }
    }
  };

  return (
    <Box sx={{ textAlign: "left" }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            color="textPrimary"
            sx={{ mb: 1, textAlign: "left" }}
            variant="h6"
          >
            Platform Key Name
          </Typography>
          <TextField
            autoComplete="off"
            sx={{ maxWidth: "50%", textAlign: "left" }}
            size="medium"
            fullWidth
            helperText={HELPER_TEXTS?.add_platform_key?.platform_key}
            name="PlatformKey"
            defaultValue={formValues?.platformKey}
            onChange={(e: any) => {
              handleChange(e.target.value, "platformKey");
            }}
            required
            variant="outlined"
          />
        </Grid>

        <Grid item xs={12}>
          <Typography
            color="textPrimary"
            sx={{ mb: 1, textAlign: "left" }}
            variant="h6"
          >
            Expires in
          </Typography>
          <Select
            size="medium"
            value={formValues?.expiry}
            onChange={(e: any) => {
              handleChange(e.target.value, "expiry");
            }}
          >
            <MenuItem value={1}>One Month</MenuItem>
            <MenuItem value={3}>Three Months</MenuItem>
            <MenuItem value={6}>Six Months</MenuItem>
            <MenuItem value={12}>One Year</MenuItem>
          </Select>
        </Grid>
      </Grid>
      {successMessage && successMessage.trim() !== STRINGS.EMPTY ? (
        <Box sx={{ mt: 2 }}>
          <Alert severity="success" sx={{ width: "fit-content" }}>
            <div>{successMessage}</div>
          </Alert>
        </Box>
      ) : errorMessage && errorMessage.trim() !== STRINGS.EMPTY ? (
        <Box sx={{ mt: 2 }}>
          <Alert severity="error" sx={{ width: "fit-content" }}>
            <div>{errorMessage}</div>
          </Alert>
        </Box>
      ) : null}

      <Box
        sx={{
          display: "flex",
          mt: 3,
        }}
      >
        {successMessage && successMessage.trim() ? null : (
          <Button
            color="primary"
            size="medium"
            variant="contained"
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            Save
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default withRouter(AddPlatformKeyForm);
