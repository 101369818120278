import { Box, Button, Grid, Table, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { ExternalLink } from "../../../../icons/external-link";
import { axiosInstance } from "../../../../services/axios";
import { FindingDataRow, IDataTile } from "../runComponents/ResultData";
import SequenceStep from "./SequenceStep";
const jp = require("jsonpath")
var format = require("string-template");

interface IWidgetParameterDefinition {
    type: "const" | "keyPath",
    value: any
}

const getWidgetValue = (source: any, definition: IWidgetParameterDefinition) => {
    console.log(source, definition)
    try {
        const { type, value } = definition
        if (type === "const") {
            return value
        }

        if (type === "keyPath") {
            let query = jp.query(source, "$." + value)

            return query[0]
        }
    }
    catch (e) {
        console.log(e)
    }
    return
}

interface IWidgetHandler {
    widget: any,
    info: any
}
const WidgetHandler: FC<IWidgetHandler> = ({ widget, info }) => {
    const { widget: type } = widget

    if (type === "info") {
        return <InfoWidget widget={widget} info={info} />
    }

    if (type === "text") {
        return <TextWidget widget={widget} info={info} />
    }

    if (type === "sequence") {
        return <SequenceWidget widget={widget} info={info} />
    }
    return <></>
}

const InfoWidget: FC<any> = ({ widget, info }) => {
    const { finding, issue } = info

    const [issueDataTiles, setIssueDataTiles] = useState([] as IDataTile[]);
    useEffect(() => {

        const xs = 12;
        const md = 6;
        const data: IDataTile[] = [
            {
                title: "Project",
                description: issue?.projectName,
                onClick: () => {
                    window.open(
                        `/projects/${issue?.projectId}`,
                        "_self"
                    );
                },

                xs,
                md,
            },
            {
                title: finding?.findingDetail?.assetType,
                description: finding?.findingDetail?.assetName,
                xs,
                md,
            },
            {
                title: getWidgetValue(finding, widget?.name?.title),
                description: getWidgetValue(finding, widget?.name?.text),
                hoverText: getWidgetValue(finding, widget?.name?.hover),
                xs,
                md,
            },
            {
                title: "Environment",
                description: issue.environmentName,
                xs,
                md,
            },
            {
                title: "Type",
                description: finding?.findingDetail?.type,
                xs,
                md,
            },
            {
                title: "Tool",
                description: finding?.findingDetail?.schema?.split("_")?.[0],
                xs,
                md,
            },
        ];
        setIssueDataTiles(data);

    }, [issue, finding, widget]);

    return <><Grid container sx={{ mb: 2 }}>
       
        <Table size="small">
            {issueDataTiles?.map((rowData: any) => (
                <FindingDataRow {...rowData} />))}
        </Table>
    </Grid>

    </>

}


const TextWidget: FC<any> = ({ widget, info }) => {
    const { finding, issue } = info
    if (!getWidgetValue(finding, widget?.data?.text)) {
        return null
    }
    return <Box>
        <Typography
            sx={{ fontSize: "12px", opacity: 0.6 }}
            variant="overline"
        >
            {getWidgetValue(finding, widget?.data?.title)}
        </Typography>
        <Box>
            {(() => {
                try {
                    //@ts-ignore
                    return (
                        <ReactMarkdown>
                            {getWidgetValue(finding, widget?.data?.text)}
                        </ReactMarkdown>
                    );
                } catch (error) {
                    return "";
                }
            })()}
        </Box>
    </Box>

}

const SequenceWidget: FC<any> = ({ widget, info }) => {
    const { finding, issue } = info

    const handleExport = async () => {
        try {
            console.log(finding.findingDetail);
            let id = finding.findingDetail.id;
            let response = await axiosInstance.get(
                format(`/findings/{id}/sequence/export`, { id: id })
            );
            const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
                JSON.stringify(response?.data)
            )}`;

            const link = document.createElement("a");
            link.href = jsonString;
            link.download = `${id}.har`;
            link.click();
        } catch (error) { }
    };

    return <Box
        sx={{
            width: "100%",
            mt: 0,
            pt: 0,
        }}
    >

        <Typography
            variant="overline"
            sx={{ fontSize: "12px", opacity: 0.6, mr: 1 }}
        >
            Sequence{" "}
        </Typography>

        <Typography
            className="hand"
            sx={{ display: "inline", fontSize: "14px" }}
            onClick={() => { }
                // history.push(
                //   `/findings?resultId=${finding?.findingDetail?.resultId}`
                // )
            }
        >
            <ExternalLink fontSize="inherit" />{" "}
            {finding?.findingDetail?.resultId?.split("-")[0]}
        </Typography>
        <Button size="small" sx={{ ml: 1 }} onClick={handleExport}>
            Export
        </Button>

        {finding?.sequence &&
            finding?.sequence?.length
            ? finding?.sequence.map(
                (step: any, index: number) => (
                    <SequenceStep
                        data={{
                            index,
                            step,
                            isLastStep:
                                finding?.sequence.length - 1 ===
                                index,
                        }}
                    />
                )
            )
            : null}

    </Box>

}



export default WidgetHandler