import { Add, ArrowLeft, Close, ContentCopy } from "@mui/icons-material";
import { Box, Chip, Container, Paper, Tooltip } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import { API_SLUGS } from "../../constants";
import { usePageContext } from "../../hooks/process/usePageContext";

import copy from "copy-to-clipboard";
import { isEmpty } from "lodash";
import { axiosInstance } from "../../services/axios";
import { IAlertState, useAlertState } from "../../store/alertState";
import AfterApiCreation from "../apiOnboarding/afterApiCreation";
import APIActions from "../apiOnboarding/apiActions";
import AddApiForm from "./AddApi/AddApiForm";
import AddRepositoryForm from "./AddRepository/AddRepositoryForm";

var format = require("string-template");

interface IApiModal {
  assetId: string;
  onClose: () => void;
}

const ApiModal: FC<IApiModal> = ({ assetId, onClose }) => {


  return (
    <Box
      sx={{
        minHeight: "100%",
        p: 3,
      }}
    >
      <Container maxWidth={"xl"}>
        <Paper elevation={12} sx={{ p: 3, px: 5 }}>
          <Box>
            <Close className="hand" onClick={onClose} />
          </Box>
          <ApiModalContent assetId={assetId} onClose={onClose} />
        </Paper>
      </Container>
    </Box >
  );
};

export const ApiModalContent: FC<any> = ({ assetId, callback = undefined, onClose = undefined }) => {

  const [asset, setAsset] = useState({} as any);
  const [uploadingNewRevision, setUploadingNewRevision] = useState(false);
  const [uploadGenerators, setUploadGenerators] = useState(false);
  const { setMessage } = useAlertState((state) => state) as IAlertState;




  const fetchApi = useCallback(
    async () => {
      console.log("fetching asset", assetId);

      try {
        const response = await axiosInstance.get(
          format(API_SLUGS.GET_API, { uuid: assetId })
        );
        setAsset(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    , [assetId]);

  useEffect(() => {
    fetchApi();
  }, [fetchApi]);

  // useEffect and compare previous and new assetId

  return <Box >
    <Box
      sx={{
        width: "100%",
        display: "flex",
        justifyContent: asset?.assetType === "api" ? "space-between" : "start"
        ,
        padding: "auto",

        minHeight: "fit-content",
        maxHeight: "80vh",
        overflow: "scroll",
      }}
    >

      <Box sx={{ mt: 2 }}>
        {!isEmpty(asset) ? <AssetForm asset={asset} />
          : null}

        {!isEmpty(asset) && <Tooltip title="Click to copy"><Box className="hand" onClick={() => {
          copy(asset?.id);
          setMessage({
            title: "Asset ID is copied to the clipboard",
            type: "success",
          });
        }} >
        </Box>
        </Tooltip>}
      </Box>

      <Box sx={{ py: 3, display: "inline" }}>
        <pre><ContentCopy fontSize="inherit" />
          {asset?.id
          } </pre>
        {!isEmpty(asset) &&
          (!uploadingNewRevision ? (
            <>

              {!uploadGenerators && asset?.assetType === "api" && (
                <Chip
                  variant="outlined"
                  size="small"

                  icon={<Add />}
                  sx={{ mr: 1 }}
                  onClick={() => {
                    setUploadGenerators(true);
                  }}
                  label="Add Generators via Postman"
                ></Chip>
              )}
              {uploadGenerators && asset?.assetType === "api" && (
                <Chip
                  variant="outlined"
                  size="small"

                  icon={<ArrowLeft />}
                  sx={{ mr: 1 }}
                  onClick={() => {
                    setUploadGenerators(false);
                  }}
                  label="Back to Resources"
                ></Chip>
              )}

              {asset?.assetType === "api" && <Chip

                variant="outlined"
                size="small"
                label="Upload New Definition"
                sx={{ mr: 1 }}
                onClick={() => setUploadingNewRevision(true)}
              />}
              <APIActions
                row={asset}
                enabledActions={
                  asset?.assetType === "api"
                    ? ["REVISIONS", "DEFINITION"]
                    : []}
              />
            </>
          ) : (
            <>
              {" "}
              <Chip
                color="error"
                variant="outlined"
                size="small"
                label="Cancel"
                sx={{ mr: 1 }}
                onClick={() => setUploadingNewRevision(false)}
              />
            </>
          ))}
      </Box>
    </Box>



    {!isEmpty(asset) && asset?.assetType === "api" && (
      <AfterApiCreation
        assetId={asset.id}
        uploadGenerators={uploadGenerators}
        callback={onClose || callback}
        uploadNewRevision={uploadingNewRevision}
        onRevisionUpload={() => setUploadingNewRevision(false)}
      />
    )}
  </Box>
}

const AssetForm: FC<any> = ({ asset }) => {
  const { selectedGroup } = usePageContext();
  return <>
    {asset?.assetType === "api" ?
      <AddApiForm

        defaultFormValues={{
          project: {
            name: asset.projectName,
            uuid: asset.projectId,
          },
          api: asset.name,
          type: asset.type,
        }}
        groupId={selectedGroup?.id}
        updateOnly
        defaultAllowEdit={false}
        updateUUID={asset.id}
      /> :
      <AddRepositoryForm

        defaultFormValues={{
          project: {
            name: asset.projectName,
            uuid: asset.projectId,
          },
          repository: asset.name,

        }}
        groupId={selectedGroup?.id}
        updateOnly
        defaultAllowEdit={false}
        updateUUID={asset.id}
      />
    }
  </>
}

export default ApiModal;
