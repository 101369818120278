import {
  Alert,
  Box,
  Button,
  Grid,
  TextField,
  Typography
} from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { API_SLUGS, STRINGS } from '../../../constants';
import { axiosInstance } from '../../../services/axios';
import { IAlertState, useAlertState } from "../../../store/alertState";
import { ICurrentUser, IUserStoreState, useUserStore } from '../../../store/userState';

const UserProfileForm: FC<RouteComponentProps> = ({ history, location }) => {
  const { currentUser, updateCurrentUser, fetchCurrentUser } = useUserStore(state => state) as IUserStoreState
  const [formValues, setFormValues] = useState({ ...currentUser } as ICurrentUser)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isFetchingUserDetails, setIsFetchingUserDetails] = useState(false)
  const [errorMessage, setErrorMessage] = useState(STRINGS.EMPTY)
  const [successMessage, setSuccessMessage] = useState(STRINGS.EMPTY)
  const useQuery = () => new URLSearchParams(location.search);
  const query = useQuery();
  const code = query.get('code');
  const { setMessage } = useAlertState((state) => state) as IAlertState;
  const [formErrors, setFormErrors] = useState({
    firstName: {
      error: false,
      message: ""
    },
    lastName: {
      error: false,
      message: ""
    }
  })
  const slackConnect = async (code: string) => {
    try {
      let userResponse = await axiosInstance.post(API_SLUGS.SLACK_CONNECT, { slackCode: code })
      // 
      fetchCurrentUser();
      console.log(userResponse);
    } catch (error: any) {
    }
  }

  if (code) {

    history.replace({ search: '', })
    slackConnect(code);
  }
  useEffect(() => {
    // fetchUserDetails()
  }, [])

  const fetchUserDetails = async () => {
    setIsFetchingUserDetails(true)
    try {
      let userResponse = await axiosInstance.get(API_SLUGS.CURRENT_USER_DETAILS)
      updateCurrentUser(userResponse.data?.data?.user)
    } catch (error: any) {
    }
    setIsFetchingUserDetails(false)
  }

  const handleChange = (value: any, type: "firstName" | "lastName" | "phoneNumber" | "role") => {
    console.log(value)
    let newValues = { ...formValues, [type]: value }

    setFormValues(newValues)
  }
  const handleSubmit = async () => {
    setIsSubmitting(true)
    try {
      await axiosInstance.put(API_SLUGS.USER_PROFILE_SETTINGS, formValues);
      updateCurrentUser(formValues);
      setMessage({ title: "Profile updated successfully", type: "error" });
    } catch (error: any) {
      setMessage({ title: "Error in updating profile", type: "error" });
    }
    setIsSubmitting(false)
  };

  if (isFetchingUserDetails) {
    return null
  }

  const validate = (value: string) => {
    if (!value) {
      return false;
    }

    return true;
  }


  return (
    <form onSubmit={() => {
      if (!validate(formValues?.firstName)) {
        setFormErrors({ ...formErrors, firstName: { error: true, message: "First Name is mandatory." } });
        return;
      } else if (!validate(formValues?.lastName)) {
        setFormErrors({ ...formErrors, lastName: { error: true, message: "Last Name is mandatory." } });
        return;
      }

      handleSubmit();
    }}>
      {formValues?.remainingLicense && formValues?.remainingLicense !== -1 && (
        <Box sx={{ mb: 2 }}>
          <Typography
            color="textPrimary"
            gutterBottom
            variant="h6"
          >
            {`${Math.floor(formValues?.remainingLicense / (60 * 60 * 24))} days remaining`}
          </Typography>
        </Box>)}
      <Grid
        container
        spacing={3}
      >
        <Grid
          item
          xs={12}
          sm={6}
        >
          <TextField
            fullWidth
            label="First Name"
            name="firstName"
            defaultValue={formValues?.firstName}
            onChange={(e: any) => { handleChange(e.target.value, 'firstName'); }}
            required
            onBlur={(e: any) => {
              if (!validate(e.target.value)) {
                setFormErrors({ ...formErrors, firstName: { error: true, message: "First Name is mandatory." } })
              } else {
                setFormErrors({ ...formErrors, firstName: { error: false, message: "" } })
              }
            }}
            error={formErrors.firstName.error}
            helperText={formErrors.firstName.message}
            variant="outlined"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <TextField
            fullWidth
            label="Last Name"
            name="lastName"
            defaultValue={formValues?.lastName}
            onChange={(e: any) => { handleChange(e.target.value, 'lastName'); }}
            required
            onBlur={(e: any) => {
              if (!validate(e.target.value)) {
                setFormErrors({ ...formErrors, lastName: { error: true, message: "Last Name is mandatory." } })
              } else {
                setFormErrors({ ...formErrors, lastName: { error: false, message: "" } })
              }
            }}
            error={formErrors.lastName.error}
            helperText={formErrors.lastName.message}
            variant="outlined"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <TextField
            fullWidth
            label="Email"
            name="email"
            defaultValue={formValues?.email}
            required
            disabled
            variant="outlined"
          />
        </Grid>

      </Grid>
      {successMessage && successMessage.trim() !== STRINGS.EMPTY ?
        <Box sx={{ mt: 2 }}>
          <Alert severity="success" sx={{ width: "fit-content" }} >
            <div>
              {successMessage}
            </div>
          </Alert>
        </Box> :
        errorMessage && errorMessage.trim() !== STRINGS.EMPTY ? <Box sx={{ mt: 2 }}>
          <Alert severity="error" sx={{ width: "fit-content" }}>
            <div>
              {errorMessage}
            </div>
          </Alert>
        </Box> : null}
      <Box
        sx={{
          display: 'flex',
          mt: 3
        }}
      >
        {
          successMessage && successMessage.trim() ? null : <Button
            color="primary"
            size="large"
            variant="contained"
            disabled={isSubmitting}
            onClick={() => {
              if (!validate(formValues?.firstName)) {
                setFormErrors({ ...formErrors, firstName: { error: true, message: "First Name is mandatory." } });
                return;
              } else if (!validate(formValues?.lastName)) {
                setFormErrors({ ...formErrors, lastName: { error: true, message: "Last Name is mandatory." } });
                return;
              }

              handleSubmit();
            }}
          >
            Save
          </Button>}
      </Box>



    </form>
  );
};

export default withRouter(UserProfileForm);
