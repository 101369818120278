import type { AppBarProps } from "@mui/material";
import { AppBar, Box, Chip, IconButton, Toolbar } from "@mui/material";
import { FC, useEffect } from "react";
import {
  RouteComponentProps,
  withRouter
} from "react-router-dom";
import { ROUTES } from "../../../constants";

import { useProSidebar } from "react-pro-sidebar";
import { Mail } from "../../../icons/mail";
import { Menu as MenuIcon } from "../../../icons/menu";
import { Moon } from "../../../icons/moon";
import { Search } from "../../../icons/search";
import { Sun } from "../../../icons/sun";
import {
  INotificationState,
  useNotificationState
} from "../../../store/notificationState";
import { GroupProjectBreadcrumb } from "../../analysis/ViewIssues/IssuesTable";
import QuickStartButton from "../appHeader/QuickStartButton";
import AvatarButton from "./AvatarButton";


interface AppHeaderProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
  showContextFilter?: boolean;
  allowFilterEdit?: boolean;
  filtersToHide?: string[];
}

const AppHeader: FC<AppHeaderProps & RouteComponentProps> = (props) => {
  const { toggleSidebar } = useProSidebar()
  const { onSidebarMobileOpen, history, showContextFilter,allowFilterEdit,filtersToHide, ...other } = props;
  const { unreadMessages, unreadAlerts, fetchMessageSummary, fetchAlerts } =
    useNotificationState((state) => state) as INotificationState;
  let selectedTheme = localStorage.getItem("selectedTheme");


  useEffect(() => {
    fetchMessageSummary();
    // fetchAlerts();
  }, []);

  const handleThemeToggle = () => {
    localStorage.setItem(
      "selectedTheme",
      selectedTheme === "dark" ? "light" : "dark"
    );
    window.location.reload();
  };


  return (
    <AppBar
      position="sticky"
      elevation={0}
      sx={{
        backgroundColor: "background.paper",
        borderBottomColor: "divider",
        borderBottomStyle: "solid",
        borderBottomWidth: 1,
        height: "65px",
        color: "text.secondary",
        // left: {
        //   lg: 220,
        // },
        // width: {
        //   lg: "calc(100% - 220px)",
        // },
      }}
    >
      <Toolbar sx={{ minHeight: 64, fontSize: "24px" }}>
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar()}
          sx={{
            display: {
              // md: "none",
              md: "none",
            },
          }}
          size="large"
        >
          <MenuIcon fontSize="small" />
        </IconButton>

        <Box
          sx={{
            flexGrow: 1,
            textAlign: "left",
          }}
        >
          {showContextFilter&& <GroupProjectBreadcrumb disableEdit={!allowFilterEdit} filtersToHide={filtersToHide}/>}

        </Box>





        <Box sx={{ ml: 2 }}>
          <QuickStartButton />
        </Box>
        <Box
          sx={{ ml: 2, color: "action.active" }}
          className="hand"
          onClick={handleThemeToggle}
        >
          {selectedTheme === "dark" ? (
            <Sun fontSize="small" />
          ) : (
            <Moon fontSize="small" />
          )}
        </Box>
        <Box sx={{ ml: 2 }} className="hand">
          <Search fontSize="small" sx={{ color: "action.active" }} />
        </Box>
        {/* <Box sx={{ ml: 2 }} onClick={() => history.push(ROUTES.ALERTS)} className="hand" >
                    <Bell fontSize="small" sx={{ color: 'action.active' }} />
                    {unreadAlerts ? unreadAlerts : null}
                </Box> */}
        <Box
          sx={{ ml: 2 }}
          onClick={() => history.push(ROUTES.INBOX)}
          className="hand"
        >
          <Mail fontSize="small" sx={{ color: "action.active" }} />
          {unreadMessages ? (
            <Chip
              size="small"
              sx={{
                position: "relative",
                top: -2,
                "&.MuiChip-label": { m: 0 },
                width: "fit-content",
                height: "24px",
                fontSize: "10px",
              }}
              color="error"
              label={unreadMessages}
            ></Chip>
          ) : null}
        </Box>

        <Box sx={{ ml: 2, color: "action.active" }} className="hand">
          <AvatarButton />
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default withRouter(AppHeader);
