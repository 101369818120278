import { Box, Button, LinearProgress, TextField, Typography } from "@mui/material";
import { FC, useCallback, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { axiosInstance } from "../../../services/axios";
import { IChatMessage, IChatState, useChatState } from "../../../store/chatState";
import CommonPopper from "../commonPopper";


const AIChat: FC<any> = () => {
    const { chat, setChat, clearChat, isChatOpen, setIsChatOpen, scope } = useChatState((state) => state) as IChatState;
    
    const [message, setMessage] = useState("")
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const getAnswer = useCallback(
        async (userMessage) => {
            setIsLoading(true)
            let newMessages = [...chat, { message: userMessage, source: "user" }];
            setChat(newMessages as IChatMessage[]);
            try {

                const response = await axiosInstance.post('chat/qaprompt', {
                    prompt: userMessage,
                    scope: scope,
                    responseType: "markdown"
                });
                console.log({ response });
                setChat([...newMessages, { message: response.data, source: "ai" }] as IChatMessage[])



            }
            catch (error) {
                console.error(error);
            }
            setIsLoading(false);
        },
        [chat, scope],
    )

    useEffect(() => {
        setTimeout(() => {
            if (chat.length === 0) {
                setChat([{ message: "Hi! Ask me about anything on this page.", source: "ai" }]as IChatMessage[])
            }
        }, 200)
    }, [chat])

    useEffect(() => {
        console.log({ isChatOpen })
    }, [isChatOpen])




    return <Box sx={{ position: "fixed", bottom: "2em", right: "2em", zIndex: 9999 }}><CommonPopper
        defaultToggleState={isChatOpen}
        onToggle={(open: boolean) => setIsChatOpen(open)}
        disableHideOnClick
        placement="top-end"
        trigger={<Button color="primary" variant="contained" sx={{ br: 2, }}>
            Chat
        </Button>}
        child={<Box sx={{ p: 0, minHeight: "60vh", width: "25em", mb: 2 }}>
            <Box sx={{ p: 2, backgroundColor: "background.default" }}>
                <Typography variant="h6">
                    Chat
                </Typography>
            </Box>
            <Box sx={{ p: 2, height: "48vh", overflow: "auto" }}>
                {chat.map((message: any, index: number) => <Box key={index} sx={{ display: "flex", justifyContent: message.source === "ai" ? "flex-start" : "flex-end", wordWrap: "break-word" }}>
                    <Box sx={{ px: 1, mb: 1.5, borderRadius: "1em", backgroundColor: message.source === "ai" ? "primary.light" : "secondary.light", color: "white", maxWidth: "80%" }}>
                        <ReactMarkdown>
                            {message.message}
                        </ReactMarkdown>
                    </Box>
                </Box>)}
                {
                    isLoading && <Box sx={{ display: "flex", justifyContent: "flex-start", wordWrap: "break-word" }}><Box sx={{ px: 1, mb: 1.5, borderRadius: "1em", width: "80%" }}>
                        <Typography variant="overline" sx={{ display: "block" }}>Typing...</Typography>
                        <LinearProgress />
                    </Box>
                    </Box>
                }
                <Box sx={{ py: 6 }}>

                </Box>
                <Box sx={{ background: "white", position: "absolute", bottom: 0, left: 0, width: "100%", py: 2 }}>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2, width: "95%", margin: "auto" }}>
                        <TextField value={message} label="Ask about the run" fullWidth
                            onChange={(e) => setMessage(e.target.value)}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    getAnswer(message);
                                    setMessage("")
                                }
                            }
                            }
                        />
                        <Button color="secondary" variant="contained" sx={{ ml: 2, backgroundColor: "secondary.light" }}
                            onClick={() => {

                                getAnswer(message);

                                setMessage("")
                            }
                            }
                        >Send</Button>
                    </Box>
                </Box>

            </Box>
        </Box>}



    />
    </Box>
}

export default AIChat;