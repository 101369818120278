import {
    LegendItem,
    LegendLabel, LegendOrdinal
} from '@visx/legend';
import { scaleLinear, scaleOrdinal } from "@visx/scale";
import {
    AnimatedAxis, AnimatedLineSeries, DataProvider, Tooltip, XYChart
} from "@visx/xychart";
import { capitalize } from 'lodash';
import { FC, useMemo } from "react";
const accessors = {
    xAccessor: (d: any) => d.x,
    yAccessor: (d: any) => d.y
};


const VisxTimeSeries: FC<any> = ({
    data,
    height,
    width
}) => {
    const legendGlyphSize = 10
    const yMax = useMemo(() => Math.max(...Object.keys(data)?.map((key: any) => Math.max(...data[key]?.map((d: any) => d.y)))), [data])

    const ordinalColorScale = useMemo(() => scaleOrdinal({
        domain: Object.keys(data)?.map((key: any) => `${key} (${data?.[key]?.[data[key]?.length - 1]?.y})`),
        range: Object.keys(data)?.map((key: any) => {
            if (key === "critical") {
                return "#7B0000"
            }
            if (key === "high") {
                return "#D14343"
            }
            if (key === "medium") {
                return "#FFBD53"
            }
            if (key === "low") {
                return "#2196F3"
            }
            return "#7dd2ff"

        })
    }), [data])



    return (
        <DataProvider

            xScale={{ type: "band" }}
            yScale={{ type: "linear" }}

        >
            <XYChart  >
                <AnimatedAxis orientation="bottom" tickLabelProps={{ fontWeight: 400, opacity: 0.7 }} />
                <AnimatedAxis orientation="left" tickLabelProps={{ fontWeight: 400, opacity: 0.7 }}
                    //@ts-ignore
                    tickFormat={value => Math.round(value)}
                    tickValues={scaleLinear().domain([0, 
                        // value greater than or equal to yMax but divisible by 5
                        Math.ceil(yMax / 5) * 5

                    ]).ticks(5)}
                />
                {/* <AnimatedGrid columns={false} numTicks={4} /> */}
                {
                    Object.keys(data)?.map((key: any) => (
                        <AnimatedLineSeries dataKey={key} data={data[key]} {...accessors}
                            stroke={
                                key === "critical" ?
                                    "#7B0000" :
                                    key === "high"
                                        ? "#D14343"
                                        : key === "medium"
                                            ? "#FFBD53"
                                            : key === "low" ? "#64B6F7" : "#7dd2ff"

                            }


                        />
                    ))
                }



                <Tooltip
                    snapTooltipToDatumX
                    snapTooltipToDatumY
                    showVerticalCrosshair
                    showHorizontalCrosshair
                    // showSeriesGlyphs

                    renderTooltip={({ tooltipData, colorScale }: any) => (
                        <div>
                            <div style={{
                                color: tooltipData.nearestDatum.key === "critical" ?
                                    "#7B0000" :
                                    tooltipData.nearestDatum.key === "high"
                                        ? "#D14343"
                                        : tooltipData.nearestDatum.key === "medium"
                                            ? "#FFBD53"
                                            : tooltipData.nearestDatum.key === "low" ? "#64B6F7" : "#7dd2ff"
                            }}>
                                {tooltipData.nearestDatum.key}
                            </div>
                            {accessors.xAccessor(tooltipData.nearestDatum.datum)}
                            {', '}
                            {accessors.yAccessor(tooltipData.nearestDatum.datum)}
                        </div>
                    )}
                />
            </XYChart >
            <LegendOrdinal scale={ordinalColorScale} labelFormat={(label: any) => `${label.toUpperCase()}`}>
                {(labels: any) => {
                    let labelRows: any = []
                    let currentRow: any = []
                    for (let i = 0; i < labels.length; i++) {
                        currentRow.push(labels[i])
                        if ((i + 1) % 5 === 0) {
                            labelRows.push(currentRow)
                            currentRow = []
                        }
                    }
                    if (currentRow.length) {
                        labelRows.push(currentRow)
                    }

                    return labelRows?.map((labelRow: any, i: number) => <div style={{ display: 'flex', flexDirection: 'row', fontSize: "10px", fontWeight: 300, marginTop: `-${(labelRow.length - i - 4) * 1.5}em` }}>
                        {

                            labelRow.map((label: any, k: number) => (
                                <LegendItem

                                    key={`legend - quantile - ${k}`}
                                    margin="0 5px"
                                // onClick={() => {
                                //     if (events) alert(`clicked: ${ JSON.stringify(label) }`);
                                // }}
                                >
                                    <svg width={legendGlyphSize} height={legendGlyphSize}>
                                        <circle fill={label?.value} r={legendGlyphSize / 2} cx={legendGlyphSize / 2} cy={legendGlyphSize / 2} />

                                    </svg>
                                    <LegendLabel align="left" margin="0 0 0 4px" >
                                        {capitalize(label?.text)}
                                    </LegendLabel>
                                </LegendItem>
                            ))}
                    </div>
                    )
                }}
            </LegendOrdinal>


        </DataProvider>
    );
};

export default VisxTimeSeries;