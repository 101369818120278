import {
  Box,
  Card,
  CardContent, Container,
  Grid, Typography
} from "@mui/material";
import { RouteComponentProps, withRouter } from "react-router-dom";

import ResultsTable from "../../management/ViewPlatformKeys/ResultsTable";

const PlatformKeySettings: React.FC<RouteComponentProps> = ({ history }) => {

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: '100%',
        }}
      >
        <Container maxWidth="xl" sx={{ p: 3 }}>
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 3,
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <Grid sx={{ textAlign: "left" }} container>
                  <Grid md={3}>
                    <div>
                      <Typography color="textPrimary" gutterBottom variant="h5">
                        PlatformKeys
                      </Typography>
                    </div>
                  </Grid>

                  <Grid md={9} spacing={3}>
                    <Card sx={{ border: "none", boxShadow: "none" }}>
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          p: 3,
                        }}
                      >
                        <Box
                          sx={{
                            flexGrow: 1,
                          }}
                        >

                          <ResultsTable />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>


        </Container>
      </Box>
    </>
  );
};

export default withRouter(PlatformKeySettings);
