import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { isEmpty, sortBy } from "lodash";
import { useMemo } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import useFetch from "../../../hooks/http/useFetch";
import { usePageContext } from "../../../hooks/process/usePageContext";
import VisxHorizontalBarChart from "../../common/charts/horzontalBarChart";
import Loaders from "../Loaders";

interface Props extends RouteComponentProps {
  groupId?: string;
  projectId?: string;
  assetId?: string;
  runId?: string;
  height?: string;
  maxDisplayCount?: number;
  disableClick?: boolean;
  sx?: any;
}

const CategoryBar: React.FC<Props> = ({ disableClick = false, history, groupId = undefined, projectId = undefined, assetId = undefined, runId = undefined, height = "400px", maxDisplayCount = 10, sx = {} }) => {
  const { changeProject } = usePageContext();
  const url = useMemo(() => {
    let baseUrl = "/insights/issues?type=category";
    if (groupId) {
      baseUrl = `${baseUrl}&groupId=${groupId}`;
    }
    if (projectId) {
      baseUrl = `${baseUrl}&projectId=${projectId}`;
    }
    if (assetId) {
      baseUrl = `${baseUrl}&assetId=${assetId}`;
    }
    if (runId) {
      baseUrl = `${baseUrl}&resultId=${runId}`;
    }


    return baseUrl;
  }
    , [assetId, groupId, projectId, runId]);

  const { data, loading: isFetchingData } = useFetch(url);
  const sanitizedData = useMemo(() => {
    let newData = []
    try {

      for (let categoryData of data?.byCategory) {
        if (categoryData?.category) {
          newData.push(categoryData)
        }
      }


      return newData



    } catch (error) {
      return []
    }

  }, [data?.byCategory])

  const heightByCount = useMemo(() => {
    return sanitizedData?.length * 30
  }, [sanitizedData])



  return (
    <Grid item md={6} xs={12} sx={sx}>

      <Typography variant="h6" sx={{ ml: 1, textAlign: "left" }}>
        Issues by Category
      </Typography>
      <Box sx={{ height: height, px: 3, overflow: heightByCount < 405 ? "hidden" : "auto" }}>
        {isFetchingData && sanitizedData.length === 0 &&
          <Loaders />
        }

        {sanitizedData.length === 0 && !isFetchingData && (

          <Typography variant="body2" sx={{ textAlign: "center", pt: 10 }}>
            No data available
          </Typography>
        )}
        <ParentSize>
          {(parent) => {
            const h = Math.max(parent.height, heightByCount);

            return (
              !isEmpty(sanitizedData) && (
                <VisxHorizontalBarChart
                  height={h}
                  width={parent.width}
                  colors={["#9DA4DD"]}
                  defaultPrimaryText={`${sanitizedData?.length}`}
                  defaultSecondaryText={"categories"}
                  data={sortBy(
                    sanitizedData?.map((o: any) => ({
                      label: o?.category,
                      value: o?.count,
                      shortHand: o?.category?.split(" ")
                        .map((o: any) => o[0])
                        .join(""),
                      onClick: () => {
                        if (disableClick) {
                          return;
                        }
                        if (projectId) {
                          changeProject(projectId);
                        }
                        setTimeout(() => {
                          history.push(
                            `/issues?categoryName=${encodeURIComponent(
                              o?.category
                            )}`
                          );
                        }, 100);


                      }
                    })),
                    "value"
                  ).reverse()}
                />
              )
            );
          }}
        </ParentSize>
      </Box>
      <Typography variant="caption" sx={{ ml: 3 }}>
        <i></i>
      </Typography>


    </Grid>
  );
};

export default withRouter(CategoryBar);
