import { ROUTES } from "./constants";
import Alerts from './views/alerts';
import Findings from "./views/analysis/ViewFindings";
import Issues from "./views/analysis/ViewIssues";
import Results from "./views/analysis/ViewResults";
import AddApi from "./views/assets/AddApi/addApi";
import UpdateApi from "./views/assets/AddApi/updateApi";
import AddRepository from "./views/assets/AddRepository";
import ApiDetails from "./views/assets/ApiDetails";
import Automations from "./views/automations";
import Dashboard from "./views/dashboard";
import Downloads from './views/downloads';
import HarConverter from './views/downloads/HarConverter';
import OpenApiMerger from './views/downloads/OpenApiMerger';
import PostmanConverter from './views/downloads/PostmanConverter';
import ForgotPassword from './views/forgotPassword';
import Generators from "./views/generators";
import Graph from './views/graph';
import Groups from "./views/groups";
import Inbox from './views/inbox';
import AddJobs from './views/jobs/AddJobSpecifications';
import ListSpecs from './views/jobs/ListConfigs';
import ListJobs from './views/jobs/ListJobSpecifications';
import { ISupportedLayouts } from "./views/layouts/layout.d";
import Login from './views/login';
import SAMLHandler from "./views/login/SAMLHandler";
import VerifyEmail from './views/login/verifyEmail';
import AddPlatformKeys from "./views/management/AddPlatformKeys/addPlatformKeys";
import ViewPlatformKeys from "./views/management/ViewPlatformKeys";
import NotFound from './views/notFound';
import AddOrganization from "./views/organizations/AddOrganization/addOrganization";
import PlanFailure from "./views/plans/failure";
import PlanSuccess from "./views/plans/success";
import AddProject from "./views/projects/AddProject/addProject";
import ViewProjects from "./views/projects/ViewProjects";
import ViewProject from "./views/projects/project";
import QuickStart from "./views/quickStart";
import AddRole from "./views/roles/addRole";
import ViewRoles from "./views/roles/viewRoles";
import Settings from "./views/settings";
import ChangePassword from './views/settings/changePassword';
import IdpMappingSettings from './views/settings/idpMappings';
import AddIntegrations from "./views/settings/integrations/AddIntegrations";
import Team from './views/settings/team';
import UserProfileSettings from './views/settings/userProfile';
import SignUp from './views/signup';

interface IRoute {
    slug: string,
    component: any,
    layout: ISupportedLayouts,
    requireAuth: boolean
    isTreeAvailable?: boolean,
    allowFilterEdit?: boolean,
    filtersToHide?: string[]

}

export const Routes = [{
    slug: ROUTES.LOGIN,
    component: Login,
    layout: "BlankLayout",
    requireAuth: false
}, {
    slug: '/forgot-password',
    component: ForgotPassword,
    layout: "BlankLayout",
    requireAuth: false
},
{
    slug: '/sign-up/verify',
    component: VerifyEmail,
    layout: "BlankLayout",
    requireAuth: true
},
{
    slug: ROUTES.IDP_REDIRECT_AUTH_HANDLER,
    component: SAMLHandler,
    layout: "BlankLayout",
    requireAuth: false
}, {
    slug: ROUTES.SIGNUP,
    component: SignUp,
    layout: "BlankLayout",
    requireAuth: false
}, {
    slug: ROUTES.DASHBOARD,
    component: Dashboard,
    layout: "AppLayoutWithFilter",
allowFilterEdit: true,
    
    requireAuth: true
}, {
    slug: ROUTES.INBOX,
    component: Inbox,
    layout: "AppLayout",
    requireAuth: true
}, {
    slug: ROUTES.TEAM,
    component: Team,
    layout: "AppLayout",
    requireAuth: true
}, {
    slug: ROUTES.ALERTS,
    component: Alerts,
    layout: "AppLayout",
    requireAuth: true
}, {
    slug: ROUTES.USER_PROFILE_SETTINGS,
    component: UserProfileSettings,
    layout: "AppLayout",
    requireAuth: true
}, {
    slug: ROUTES.IDP_MAPPING_SETTINGS,
    component: IdpMappingSettings,
    layout: "AppLayout",
    requireAuth: true
}, {
    slug: ROUTES.CHANGE_PASSWORD,
    component: ChangePassword,
    layout: "AppLayout",
    requireAuth: true
}, {
    slug: ROUTES.ADD_PROJECT,
    component: AddProject,
    layout: "AppLayout",
    requireAuth: true
}, {
    slug: ROUTES.ADD_API,
    component: AddApi,
    layout: "AppLayout",
    requireAuth: true
},  {
    slug: ROUTES.ADD_REPOSITORY,
    component: AddRepository,
    layout: "AppLayout",
    requireAuth: true
}, {
    slug: ROUTES.API_DETAILS,
    component: ApiDetails,
    layout: "AppLayout",
    requireAuth: true
},
//  {
//     slug: ROUTES.UPDATE_PROJECT,
//     component: UpdateProject,
//     layout: "AppLayoutWithFilter",
//     allowFilterEdit: true,
//     requireAuth: true
// },
 {
    slug: ROUTES.UPDATE_API,
    component: UpdateApi,
    layout: "AppLayout",
    requireAuth: true
}, {
    slug: ROUTES.ROLES,
    component: ViewRoles,
    layout: "AppLayout",
    requireAuth: true
}, {
    slug: ROUTES.GENERATORS,
    component: Generators,
    layout: "AppLayout",
    requireAuth: true
},  {
    slug: ROUTES.PROJECTS,
    component: ViewProjects,
    layout: "AppLayoutWithFilter",
    allowFilterEdit: true,
    // filtersToHide: ["project"],
    requireAuth: true
},

{
    slug: ROUTES.PROJECT,
    component: ViewProject,
    layout: "AppLayoutWithFilter",
allowFilterEdit: true,
    requireAuth: true
},
// {
//     slug: ROUTES.ENVIRONMENTS,
//     component: ViewEnvironments,
//     layout: "AppLayout",
//     requireAuth: true
// },
// {
//     slug: ROUTES.ADD_ENVIRONMENT,
//     component: AddEnvironment,
//     layout: "AppLayout",
//     requireAuth: true
// },
// {
//     slug: ROUTES.UPDATE_ENVIRONMENT,
//     component: UpdateEnvironment,
//     layout: "AppLayout",
//     requireAuth: true
// },
// {
//     slug: ROUTES.ADD_ORGANIZATION,
//     component: AddOrganization,
//     layout: "AppLayout",
//     requireAuth: true
// },
{
    slug: '/join',
    component: AddOrganization,
    layout: "BlankLayout",
    requireAuth: true
},
{
    slug: '/integrations',
    component: AddIntegrations,
    layout: "AppLayout",
    requireAuth: true
},
{
    slug: '/settings',
    component: Settings,
    layout: "AppLayout",
    requireAuth: true
}, 
{
    slug: ROUTES.QUICK_START,
    component: QuickStart,
    layout: "AppLayout",
    requireAuth: true
}, 

// {
//     slug: ROUTES.PLANS,
//     component: Plans,
//     layout: "AppLayout",
//     requireAuth: true
// }, 
{
    slug: ROUTES.PLAN_SUCCESS,
    component: PlanSuccess,
    layout: "AppLayout",
    requireAuth: true
}, 
{
    slug: ROUTES.PLAN_FAILURE,
    component: PlanFailure,
    layout: "AppLayout",
    requireAuth: true
}, 
{
    slug: ROUTES.ADD_PLATFORM_KEY,
    component: AddPlatformKeys,
    layout: "AppLayout",
    requireAuth: true
},
{
    slug: ROUTES.PLATFORM_KEYS,
    component: ViewPlatformKeys,
    layout: "AppLayout",
    requireAuth: true
}, {
    slug: ROUTES.ADD_ROLE,
    component: AddRole,
    layout: "AppLayout",
    requireAuth: true
}, {
    slug: ROUTES.EDIT_ROLE,
    component: AddRole,
    layout: "AppLayout",
    requireAuth: true
}, {
    slug: ROUTES.GRAPH,
    component: Graph,
    layout: "AppLayout",
    requireAuth: true
}, {
    slug: ROUTES.INCIDENTS,
    component: Issues,
    layout: "AppLayoutWithFilter",
allowFilterEdit: true,
    requireAuth: true
}, {
    slug: ROUTES.FINDINGS,
    component: Findings,
    layout: "AppLayoutWithFilter",
allowFilterEdit: false,
    requireAuth: true
}, {
    slug: ROUTES.RESULTS,
    component: Results,
    layout: "AppLayoutWithFilter",
allowFilterEdit: true,
    requireAuth: true
},
{
    slug: ROUTES.ADD_CONFIGS,
    component: AddJobs,
    layout: "AppLayout",
    requireAuth: true
},
{
    slug: ROUTES.EDIT_CONFIG,
    component: AddJobs,
    layout: "AppLayout",
    requireAuth: true
},
{
    slug: ROUTES.LIST_JOBS,
    component: ListJobs,
    layout: "AppLayout",
    requireAuth: true
},
{
    slug: ROUTES.LIST_SPECS,
    component: ListSpecs,
    allowFilterEdit: true,
    layout: "AppLayoutWithFilter",
    requireAuth: true
}, 
{
    slug: ROUTES.AUTOMATIONS,
    component: Automations,
    layout: "AppLayout",
    requireAuth: true
},  
{
    slug: ROUTES.DOWNLOADS,
    component: Downloads,
    layout: "AppLayout",
    requireAuth: true
},
{
    slug: ROUTES.GROUPS,
    component: Groups,
    layout: "AppLayout",
    requireAuth: true
},
{
    slug: ROUTES.HAR_CONVERTER,
    component: HarConverter,
    layout: "AppLayout",
    requireAuth: true
},
{
    slug: ROUTES.OPENAPI_MERGER,
    component: OpenApiMerger,
    layout: "AppLayout",
    requireAuth: true
},
{
    slug: ROUTES.POSTMAN_CONVERTER,
    component: PostmanConverter,
    layout: "AppLayout",
    requireAuth: true
},
{
    slug: ROUTES.UNKNOWN,
    component: NotFound,
    layout: "BlankLayout",
    requireAuth: false
}] as IRoute[];

