import React, { useEffect } from "react";
import BlankLayout from "./BlankLayout";
import NormalLayout from "./NormalLayout";
import AppLayout, { AlertPopup } from "./AppLayout";
import { withRouter } from "react-router-dom";
import { ILayoutWrapperParams } from "./layout";
import { IUserStoreState, useUserStore } from "../../store/userState";
import { getConfigs } from "../../configs";
import { Mixpanel } from "../../mixpanel";
import { Box, CircularProgress } from "@material-ui/core";
import { ProSidebarProvider } from "react-pro-sidebar";

const LayoutWrapper: React.FC<ILayoutWrapperParams> = ({
  Component,
  layout,
  requireAuth,
  history,
  isTreeAvailable,
  allowFilterEdit,
  filtersToHide,
}) => {
  const isLoggedIn = useUserStore(
    (state) =>
      //@ts-ignore
      state.isLoggedIn
  );

  return (
    <>
    <AlertPopup />
      {isLoggedIn || !requireAuth ? (
        <>
          {layout.toString() === "BlankLayout" ? (
            <BlankLayout Component={Component} />
          ) : null}
          {layout.toString() === "NormalLayout" ? (
            <NormalLayout Component={Component} />
          ) : null}
          {layout.toString() === "AppLayout" ? (
            <ProSidebarProvider>
              <AppLayout
                Component={Component}
                isTreeAvailable={isTreeAvailable}
                showContextFilter={false}
              />
            </ProSidebarProvider>
          ) : null}
          {layout.toString() === "AppLayoutWithFilter" ? (
            <ProSidebarProvider>
              <AppLayout
                Component={Component}
                isTreeAvailable={isTreeAvailable}
                showContextFilter={true}
                allowFilterEdit={allowFilterEdit}
                filtersToHide={filtersToHide}
              />
            </ProSidebarProvider>
          ) : null}
        </>
      ) : (
        <Box sx={{ mt: 10, backgroundColor: "background.paper" }}>
          <CircularProgress disableShrink />
        </Box>
      )}

      
      <AuthHandler requireAuth={requireAuth} history={history} />
    </>
  );
};

const AuthHandler: React.FC<any> = ({ requireAuth, history }) => {
  const { isLoggedIn, currentUser, fetchCurrentUser, logout, fetchActivePlan } = useUserStore(
    (state) => state
  ) as IUserStoreState;

  useEffect(() => {
    if (isLoggedIn) {
      fetchCurrentUser();
      fetchActivePlan();
    } else if (requireAuth) {
      logout();
    }
  }, []);

  useEffect(() => {
    if (getConfigs().mixpanelToken) {
      Mixpanel.track(window.location.pathname.split("?")[0]);
    }
    if (!isLoggedIn && requireAuth) {
      logout();
    } else if (
      isLoggedIn &&
      !currentUser.isVerified &&
      window.location.pathname !== "/sign-up/verify"
    ) {
      history.push("/sign-up/verify");
    }
  }, [isLoggedIn, requireAuth, history]);

  return <></>;
};

export default withRouter(LayoutWrapper);
