import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { sumBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import useFetch from "../../../hooks/http/useFetch";
import VisxDonut from "../../common/charts/donut";

interface Props extends RouteComponentProps {
  projectId?: string;
  assetId?: string;
  runId?: string;
  height?: string;
  disableClick?: boolean;
  maxDisplayCount?: number;
}

const SeverityDonut: React.FC<Props> = ({ disableClick = false, history, projectId = undefined, assetId = undefined, runId = undefined, height = "400px"
}) => {
  const url = useMemo(() => {
    let baseUrl = "/insights/issues?type=risk";
    if (projectId) {
      return `${baseUrl}&projectId=${projectId}`;
    }
    if (assetId) {
      return `${baseUrl}&assetId=${assetId}`;
    }
    if (runId) {
      return `${baseUrl}&resultId=${runId}`;
    }
    return baseUrl;
  }, [projectId]);
  const { data } = useFetch(url);

  const [pieData, setPieData] = useState([]);
  useEffect(() => {
    if (data) {
      const pieData = data.byRisk.map((d: any) => {
        return {
          label: `${d.risk} risk`,
          value: d.total,
          color:
            d.risk === "critical"
              ? "#7B0000" :
              d.risk === "high"
                ? "#D14343"
                : d.risk === "medium"
                  ? "#FFBD53"
                  : d.risk === "low" ? "#64B6F7" : "#7dd2ff",
          onClick: () => {
            if (disableClick) {
              return;
            }
            history.push(`/issues?risk=${d.risk}`);
          },
        };
      });
      setPieData(pieData);
    }
  }, [data, history]);

  if (!data) {
    return null;
  }

  return (
    <Grid item md={6} xs={12}>

      <Typography variant="h6" sx={{ ml: 3, textAlign: "left" }}>Issues by Risk</Typography>
      <Box sx={{ height: height }}>
        <ParentSize>
          {(parent) => {
            const maxHeight = 405;
            const h = Math.min(parent.height, maxHeight);

            return (
              <VisxDonut
                data={pieData}
                width={parent.width}
                height={h}
                innerRadius={Math.min(parent.width, parent.height) / 2 - 50}
                outerRadius={Math.min(parent.width, parent.height) / 2 - 30}
                defaultPrimaryText={`${sumBy(pieData, "value")}`}
                defaultSecondaryText={"issues"}
                resize={parent.resize}
              />
            );
          }}
        </ParentSize>
      </Box>
      <Typography variant="caption" sx={{ ml: 3 }}>
        <i></i>
      </Typography>


    </Grid>
  );
};

export default withRouter(SeverityDonut);
