import {
  Alert,
  Box,
  Button,
  Grid,
  TextField
} from '@mui/material';
import { isEmpty } from 'lodash';
import { FC, useEffect, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { STRINGS } from '../../../../constants';
import { axiosInstance } from '../../../../services/axios';
import { IAlertState, useAlertState } from '../../../../store/alertState';

interface IError {
  error: boolean
  message: string
}

interface IAddJiraFormError {
  host?: IError
  email?: IError
  apiToken?: IError
}

interface IAddJiraFormValues {
  host: string
  email: string
  apiToken: string
  isActive?: boolean
}

interface IJira {
  id: string;
}
interface IAddForm extends RouteComponentProps {
  updateOnly?: boolean
  defaultFormValues?: any
  updateUUID?: string,
  setJiraStatus: any
}



const JiraIntegrationForm: FC<IAddForm> = ({ history, match, defaultFormValues, updateUUID, setJiraStatus }) => {

  const [formValues, setFormValues] = useState({ host: "", email: "", apiToken: "", isActive: false, ...defaultFormValues } as IAddJiraFormValues)
  const [formErrorValues, setFormErrorValues] = useState({ host: {} } as IAddJiraFormError)
  const { setMessage } = useAlertState(state => state) as IAlertState
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState(STRINGS.EMPTY)
  const [successMessage, setSuccessMessage] = useState(STRINGS.EMPTY)
  const [updateOnly, setUpdateOnly] = useState(false)


  useEffect(() => {
    fetchJiraIntegration()
  }, [])

  const fetchJiraIntegration = async () => {
    try {
      let response = await axiosInstance.get('/cloudintegration')
      setFormValues({ ...formValues, ...response?.data?.jira })
      setJiraStatus(response?.data?.jira?.isActive)
      if (!isEmpty(response?.data)) {
        setUpdateOnly(true)
      }
    } catch (error) {

    }

  }

  const handleChange = async (value: any, type: "host" | "email" | "apiToken") => {
    let newValues = { ...formValues, [type]: value }


    setFormValues(newValues)
  }
  const handleSubmit = async () => {

    if (updateOnly) {

      if (formValues?.host.trim() != "") {
        await updateJira(formValues?.host.trim())
      }
      else {
        setErrorMessage("Please fill all the mandatory fields")
      }
    } else {
      if (formValues?.host.trim() != "") {
        await addJira(formValues?.host.trim())
      }
      else {
        setErrorMessage("Please fill all the mandatory fields")
      }
    }
  };

  const addJira = async (name: string) => {
    try {
      let response = await axiosInstance.post('/cloudintegration', { type: "jira", details: formValues })

      setMessage({ title: "Jira details have been saved", type: "success" })
    } catch (error: any) {
      try { setErrorMessage(error.response?.data?.message) } catch (error: any) { }
    }
  };

  const updateJira = async (name: string) => {
    try {
      let response = await axiosInstance.patch('/cloudintegration', { type: "jira", details: formValues })

      setMessage({ title: "Jira details have been saved", type: "success" })

    } catch (error: any) {
      try { setErrorMessage(error.response?.data?.message) } catch (error: any) { }
    }

  };

  return (
    <Grid
      item
      container
      spacing={3}
    >
      <Grid
        item
        xs={12}
      >
        <TextField
          sx={{ maxWidth: "50%", textAlign: 'left' }}
          fullWidth
          name="Jira"
          label="Web URL"
          value={formValues?.host}
          onChange={(e: any) => { handleChange(e.target.value, 'host'); }}
          required
          variant="outlined"
        />
      </Grid>

      <Grid
        item
        xs={12}
      >
        <TextField
          sx={{ maxWidth: "50%", textAlign: 'left' }}
          fullWidth
          name="Jira"
          label="Username or Email"
          value={formValues?.email}
          onChange={(e: any) => { handleChange(e.target.value, 'email'); }}
          required
          variant="outlined"
        />

      </Grid>

      <Grid
        item
        xs={12}
      >
        <TextField
          sx={{ maxWidth: "50%", textAlign: 'left' }}
          fullWidth
          label="API token"
          name="Jira"
          value={formValues?.apiToken}
          onChange={(e: any) => { handleChange(e.target.value, 'apiToken'); }}
          required
          variant="outlined"
        />

      </Grid>



      <Grid item xs={12} sx={{ padding: 0 }}>
        {successMessage && successMessage.trim() !== STRINGS.EMPTY ?
          <Box sx={{ mt: 2 }}>
            <Alert severity="success" sx={{ width: "fit-content" }} >
              <div>
                {successMessage}
              </div>
            </Alert>
          </Box> :
          errorMessage && errorMessage.trim() !== STRINGS.EMPTY ? <Box sx={{ mt: 2 }}>
            <Alert severity="error" sx={{ width: "fit-content" }}>
              <div>
                {errorMessage}
              </div>
            </Alert>
          </Box> : null}


        <Box
          sx={{
            display: 'flex',
          }}
        >
          {
            successMessage && successMessage.trim() ? null : <Button
              color="primary"
              size="large"
              variant="contained"
              disabled={isSubmitting}
              onClick={handleSubmit}
            >
              Save
            </Button>}
        </Box>
      </Grid>

    </Grid>
  );
};


export default withRouter(JiraIntegrationForm);
