import {
  Box, Card, CardContent, Dialog,
  Grid,
  Typography
} from "@mui/material";
import { capitalize, isEmpty } from "lodash";
import { FC, useEffect, useState } from "react";
import { ExternalLink } from "../../../icons/external-link";
import { JobSpecContainer } from "../../jobs/JobSpecContainer";

import { withRouter } from "react-router-dom";
import { axiosInstance } from "../../../services/axios";
import APIActions from "../../apiOnboarding/apiActions";
import ConfigTablePopup from "../../dashboard/configTablePopup";
import CategoryBar from "../../dashboard/widgetLibrary/categoryBar";
import SeverityDonut from "../../dashboard/widgetLibrary/severityDonut";
import RunHistoryPopup from "./runHistoryPopup";
var format = require("string-template");

export interface IDataTile {
  title: string;
  ComponentToRender?: FC<any>;
  description?: string;
  onClick?: () => void;
  xs?: number;
  md?: number;
}

export const FindingDataRow: FC<IDataTile> = ({
  title,
  ComponentToRender,
  description,
  onClick,
  xs,
  md,
}) => {
  return (
    <Grid item md={md} xs={xs} sx={{ pr: 3, pt: 1 }}>
      <Typography variant={"overline"} sx={{ fontSize: "12px", opacity: 0.6 }}>
        {title}
      </Typography>
      <Typography
        className="hand"
        variant={"h6"}
        onClick={onClick ? onClick : () => { }}
      >
        {onClick ? <ExternalLink fontSize="inherit" /> : null}
        {description}
        {ComponentToRender ? <ComponentToRender /> : null}
      </Typography>
    </Grid>
  );
};

const ResultData: FC<any> = ({
  id,
  api,
  onFaultsGroupChange,
  match,
  Summary,
  location,
}: any) => {
  const [dialogOpen, setDialogOpen] = useState(false);


  const [environment, setEnvironment] = useState<any>({});
  const useQuery = () => new URLSearchParams(location.search);
  const query = useQuery();
  const environmentId = query.get("environmentId");
  const [configurations, setConfigurations] = useState<any[] | null>(null);

  useEffect(() => {
    if (!isEmpty(api)) {

      if (environmentId) {
        fetchConfigurations();
      }
    }
  }, [api]);

  const fetchConfigurations = async () => {
    try {
      let configurationsResponse = await axiosInstance.get(
        format(
          "/run/configurations?assetId={assetId}&environmentId={environmentId}",
          {
            assetId: api.id,
            environmentId: environmentId,
          }
        )
      );
      setConfigurations(configurationsResponse?.data?.hermitConfigs);
    } catch (error) { }
  };



  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <Dialog maxWidth="lg" onClose={handleDialogClose} open={dialogOpen}>
        <Box sx={{ m: 3 }}>
          <JobSpecContainer id={id} />
        </Box>
      </Dialog>
      <Typography variant="h5" sx={{ ml: 2 }}>
        {capitalize(api.assetType)} Issues
      </Typography>
      <Grid container columnSpacing={2}>
        <Grid md={12} item sx={{ pl: 2 }}>
          <Card
            elevation={0}
            sx={{
              minHeight: "6em",
            }}
          >
            <CardContent>
              <Box >
                <Box sx={{ display: "flex", justifyContent: "start" }}>
                  <Box sx={{ display: "inline", mr: 2 }}>
                    <Typography
                      variant="overline"
                      sx={{ fontSize: "12px" }}
                      color="text.secondary"
                    >
                      Project
                    </Typography>
                    <br />
                    <Typography variant="h6">{api.projectName}</Typography>
                  </Box>
                  <Box sx={{ display: "inline", mr: 2 }}>
                    <Typography
                      variant="overline"
                      sx={{ fontSize: "12px" }}
                      color="text.secondary"
                    >
                      {api.assetType}
                    </Typography>
                    <br />
                    <Typography variant="h6">{api.name}</Typography>
                  </Box>
                  <Box sx={{ display: "inline", mr: 2 }}>
                    <Typography
                      variant="overline"
                      sx={{ fontSize: "12px" }}
                      color="text.secondary"
                    >
                      Environment
                    </Typography>
                    <br />
                    <Typography variant="h6">{environment?.name}</Typography>
                  </Box>
                </Box>{" "}
                <Grid container spacing={2} sx={{ mt: 2, display: "flex", justifyContent: "spaced-evenly", border: "2px solid #5148e626", backgroundColor: "#5048e508", borderRadius: "0.35em", p: 3 }}>


                  <CategoryBar assetId={api?.id} height="200px" maxDisplayCount={4} disableClick />

                  <SeverityDonut assetId={api?.id} height="200px" disableClick />

                </Grid>
                <Box sx={{ pl: 2, display: "flex", justifyContent: "end" }}>
                  {api.assetType === "sift" && <ConfigTablePopup sx={{ width: "100%" }} assetId={api?.id} />}
                  <RunHistoryPopup
                    sx={{ mt: 1, width: "100%" }}
                    assetId={api?.id}
                  />
                  {api.assetType === "sift" && <APIActions
                    row={{
                      id: api?.id,
                    }}
                    sx={{ mt: 1, width: "100%" }}
                    enabledActions={["SETTINGS"]}
                  />}
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
};

export default withRouter(ResultData);
