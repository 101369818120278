import create from "zustand";
import { devtools } from "zustand/middleware";

export interface IChatMessage {
    source: "user"| "ai",
    message: string
}

export interface IChatState {
    chat: IChatMessage[] 
    setChat: (data: IChatMessage[]) => void
    clearChat: (intro?: IChatMessage[]) => void
    isChatOpen: boolean
    setIsChatOpen: (isOpen: boolean) => void
    scope: {[key: string]: string}
    setScope: (data: {[key: string]: string}) => void
}

export const useChatState = create(devtools(set => ({
    chat: [],
    setChat: (data: IChatMessage[]) => {
        set((state: IChatState) => {
            return { ...state, chat: data }
        })
    },
    clearChat: (intro: IChatMessage[]) => {
        set((state: IChatState) => {
            return { ...state, chat: [...intro] }
        })
    },
    scope:  {},
    isChatOpen: false,
    setIsChatOpen: (isOpen: boolean) => {
        set((state: IChatState) => {
            return { ...state, isChatOpen: isOpen }
        })
    },  
    setScope: (data: {[key: string]: string}) => {
        set((state: IChatState) => {
            return { ...state, scope: data|| {} }
        })
    },
    
} as IChatState)))