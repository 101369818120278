import { Button, CircularProgress, Grid, Link, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import useFetch from "../../../../hooks/http/useFetch";
import usePost from "../../../../hooks/http/usePost";
import { IAlertState, useAlertState } from "../../../../store/alertState";

const PostmanIntegration = ({ setPostmanStatus }: any) => {
  const { data: postmanIntegration, loading } = useFetch(
    "/cloudintegration?type=postman"
  );
  const { postData } = usePost("/cloudintegration");
  const [apiKey, setApiKey] = useState("");
  const { setMessage } = useAlertState() as IAlertState;

  useEffect(() => {
    if (postmanIntegration) {
      setApiKey(postmanIntegration?.postman?.apiKey);
      setPostmanStatus(postmanIntegration?.postman?.isActive)
    }
  }, [postmanIntegration, setPostmanStatus]);

  const submit = async () => {
    if (apiKey) {
      try {
        await postData({ type: "postman", details: { apiKey } });
        setPostmanStatus(true);
      } catch (error: any) {
        setMessage({ title: error.message, type: "error" });
      }
    }
  };

  if (loading) {
    return <CircularProgress disableShrink />;
  }

  return (
    <>
      <Grid md={9} spacing={3}>
        <Box
          sx={{
            mb: 1,
            flexGrow: 1,
            justifyContent: "flex-start",
            display: "flex",
          }}
        >
          <TextField
            type="text"
            placeholder="Enter your Postman API Key"
            sx={{ minWidth: "50%" }}
            value={apiKey}
            onChange={({ target }) => setApiKey(target.value)}
          />
          <Button
            color="primary"
            size="large"
            variant="contained"
            onClick={submit}
            disabled={apiKey?.trim() === ""}
            sx={{ ml: 2 }}
          >
            Link
          </Button>
        </Box>
        <Link target="_self" href="https://learning.postman.com/docs/developer/intro-api/#generating-a-postman-api-key">How to generate Postman API key?</Link>
      </Grid>
    </>
  );
};

export default PostmanIntegration;
