import {
  Box, Button, Card,
  CardContent, Container,
  Grid, Typography
} from "@mui/material";
import { RouteComponentProps, withRouter } from "react-router-dom";

import ModalWrapper from "../../common/modal/wrapper";
import { Plus } from "../../../icons/plus";
import AddGroupForm from "../../groups/subcomponents/AddGroupForm";
import GroupsTable from "../../groups/subcomponents/GroupsTable";

const GroupSettings: React.FC<RouteComponentProps> = ({ history }) => {

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: '100%',
        }}
      >
        <Container maxWidth="xl" sx={{ p: 3 }}>
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 3,
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <Grid sx={{ textAlign: "left" }} container>
                  <Grid md={3}>
                    <div>
                      <Typography color="textPrimary" gutterBottom variant="h5">
                        Groups
                      </Typography>
                    </div>
                  </Grid>

                  <Grid md={9} spacing={3}>
                    <Card sx={{ border: "none", boxShadow: "none" }}>
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          p: 3,
                        }}
                      >
                        <Box
                          sx={{
                            flexGrow: 1,
                          }}
                        >
                          <ModalWrapper
                            maxWidth="md"
                            title="Add Group"
                            trigger={<Button variant="contained" sx={{ mb: 2 }}>Add <Plus /></Button>}
                            child={<AddGroupForm />}
                          />
                          <GroupsTable />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
         

        </Container>
      </Box>
    </>
  );
};

export default withRouter(GroupSettings);
