import {
  Alert,
  Box,
  Button,
  Grid,
  TextField,
} from '@mui/material';
import { FC, useState } from 'react';
import { API_SLUGS, ERRORS, STRINGS } from '../../../constants';
import { axiosInstance } from '../../../services/axios';
import { IAlertState, useAlertState } from "../../../store/alertState";
import { IUserStoreState, useUserStore } from '../../../store/userState';

interface IError {
  error: boolean
  message: string
}

interface IChangePasswordFormError {
  password?: IError
  newPassword?: IError
  confirmPassword?: IError
}

interface IChangePasswordFormValues {
  password?: string
  newPassword?: string
  confirmPassword?: string
}


let EMPTY_PASSWORD_FORM_VALUES: IChangePasswordFormValues = { password: "", newPassword: "", confirmPassword: "" }

const ChangePasswordForm: FC = () => {
  const { updateJWTToken, isLoggedIn } = useUserStore(state => state) as IUserStoreState
  const [formValues, setFormValues] = useState(EMPTY_PASSWORD_FORM_VALUES as IChangePasswordFormValues)
  const [formErrorValues, setFormErrorValues] = useState({ password: {}, newPassword: {}, confirmPassword: {} } as IChangePasswordFormError)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [errorMessage, setErrorMessage] = useState(STRINGS.EMPTY)
  const [successMessage, setSuccessMessage] = useState(STRINGS.EMPTY);
  const { setMessage } = useAlertState((state) => state) as IAlertState;

  const setError = (values: IChangePasswordFormError) => {
    setFormErrorValues({ ...formErrorValues, ...values })
  }

  const verifyPassword = (values: any, type: "password" | "newPassword" | "confirmPassword") => {
    if (type in values) {
      if (!values[type]) {
        setError({ [type]: { error: true, message: ERRORS.PASSWORD_EMPTY } })
        return false
      }
      if (values.newPassword && values.newPassword!.length < 8) {
        setError({ [type]: { error: true, message: ERRORS.PASSWORD_LENGTH_SHORT } })
        return false
      }

      if (type === 'confirmPassword' && values.confirmPassword !== values.newPassword) {
        setError({ [type]: { error: true, message: ERRORS.PASSWORDS_NOT_SAME } })
        return false
      }

    } else {
      setError({ [type]: { error: true, message: ERRORS.PASSWORD_EMPTY } })
      return false
    }
    setError({ [type]: { error: false, message: STRINGS.EMPTY } })
    return true
  }


  const handleChange = (value: any, type: "password" | "newPassword" | "confirmPassword") => {
    let newValues = { ...formValues, [type]: value }
    switch (type) {
      case "password":
        verifyPassword(newValues, type)
        break;
      case "newPassword":
        verifyPassword(newValues, type)
        break;
      case "confirmPassword":
        verifyPassword(newValues, type)
        break;
      default:
        break;
    }
    setFormValues(newValues)
  }
  const handleSubmit = async () => {
    if (verifyPassword(formValues, 'password') && verifyPassword(formValues, 'newPassword') && verifyPassword(formValues, 'confirmPassword')) {
      setIsSubmitting(true)
      try {
        let userResponse = await axiosInstance.put(API_SLUGS.CHANGE_PASSWORD, formValues);
        setFormValues(EMPTY_PASSWORD_FORM_VALUES)
        updateJWTToken(userResponse.data?.data?.jwtToken);
        setMessage({ title: "Password updated successfully", type: "success" });
      } catch (error: any) {
        setMessage({ title: "Error in updating password", type: "error" });
      }
      setIsSubmitting(false)
    }
  };

  return (
    <form onSubmit={handleSubmit} >
      <Grid
        container
        spacing={3}
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Grid
          item
          xs={12}
          sm={6}
        >
          <TextField
            fullWidth
            label="Current Password"
            name="password"
            type="password"
            onChange={(e: any) => { handleChange(e.target.value, 'password'); }}
            value={formValues?.password}
            error={formErrorValues.password?.error}
            helperText={formErrorValues.password?.message}
            required
            variant="outlined"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <TextField
            fullWidth
            label="New Password"
            name="newPassword"
            type="password"
            onChange={(e: any) => { handleChange(e.target.value, 'newPassword'); }}
            value={formValues?.newPassword}
            error={formErrorValues.newPassword?.error}
            helperText={formErrorValues.newPassword?.message}
            required
            variant="outlined"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
        >
          <TextField
            fullWidth
            label="Re-enter new password"
            name="confirmPassword"
            type="password"
            onChange={(e: any) => { handleChange(e.target.value, 'confirmPassword'); }}
            value={formValues?.confirmPassword}
            error={formErrorValues.confirmPassword?.error}
            helperText={formErrorValues.confirmPassword?.message}
            required
            variant="outlined"
          />
        </Grid>


      </Grid>
      {successMessage && successMessage.trim() !== STRINGS.EMPTY ?
        <Box sx={{ mt: 2 }}>
          <Alert severity="success" sx={{ width: "fit-content" }} >
            <div>
              {successMessage}
            </div>
          </Alert>
        </Box> :
        errorMessage && errorMessage.trim() !== STRINGS.EMPTY ? <Box sx={{ mt: 2 }}>
          <Alert severity="error" sx={{ width: "fit-content" }}>
            <div>
              {errorMessage}
            </div>
          </Alert>
        </Box> : null}
      <Box
        sx={{
          display: 'flex',
          mt: 3
        }}
      >
        {
          successMessage && successMessage.trim() ? null : <Button
            color="primary"
            size="large"
            variant="contained"
            disabled={isSubmitting}
            onClick={() => {
              handleSubmit();
            }}
          >
            Save
          </Button>}
      </Box>
    </form>
  );
};

export default ChangePasswordForm;
