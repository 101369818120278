import { PlayCircle } from "@mui/icons-material";
import { Box, Button, Chip, TextField, Typography } from "@mui/material";
import copy from "copy-to-clipboard";
import { FC, useState } from "react";
import { withRouter } from "react-router-dom";
import { getConfigs } from "../../configs";
import usePermissionChecker from "../../hooks/process/usePermissionChecker";
import { IAlertState, useAlertState } from "../../store/alertState";
import { PermissionTargets, PermissionTypes } from "../../store/userState";
import ModalWrapper from "../common/modal/wrapper";
import PermissionBlocker from "../common/permissionBlocker";

const ScannersPopup: FC<any> = ({ assetId, environmentId, sx, trigger = undefined }) => {
  const { setMessage, clearMessage } = useAlertState(
    (state) => state,
  ) as IAlertState;
  const [scanTool, setScanTool] = useState("SAST");
  const [label, setLabel] = useState("");
  const { hasPermission } = usePermissionChecker(
    PermissionTargets.configuration,
    PermissionTypes.execute,
  );

  const getPlatformUrl = () => {
    if (getConfigs().baseApiUrl.includes("app")) {
      return "";
    }
    return `--platform-url ${getConfigs().baseApiUrl} `;
  };

  const toolCommands: any = {
    SAST: "sast semgrep",
    "Dependency, SCA": "dependency trivy",
    Secrets: "secret gitleaks",
    Licenses: "license trivy",
    IaC: "iac trivy",
    //    k8s: "k8s trivy",
    //    AWS: "aws trivy",
  };

  const externalRunTypes = ["k8s", "AWS"];

  const getEnvironment = () => {
    if (environmentId === "") {
      return "";
    }
    return ` --environment-id ${environmentId}`;
  };

  const getFile = () => {
    if (externalRunTypes.includes(scanTool)) {
      return ` --file ${scanTool}-report.json`;
    }
    return "";
  };

  const getCommand = () => {
    return `docker run --rm -v "\${PWD}:/src" aptori/scanners scanners ${toolCommands[scanTool]} --key $APTORI_PLATFORM_KEY ${getPlatformUrl()}--asset-id ${assetId}${getFile()}${getEnvironment()}${label}`;
  };

  const getTrivyCommand = () => {
    if (scanTool === "k8s") {
      return "trivy k8s --report summary all -f json -o k8s-report.json";
    }
    if (scanTool === "AWS") {
      return "trivy aws --region us-east-1 -f json -o AWS-report.json";
    }
    return "";
  };

  const commitLabels = (event: any) => {
    event.preventDefault();
    const repo = event?.target?.elements?.repoinput?.value;
    const branch = event?.target?.elements?.branchinput?.value;
    if (repo && branch) {
      setLabel(` --labels "repository=${repo}, branch=${branch}"`);
    } else {
      setLabel("");
    }
  };



  return (
    <Box sx={{ display: "inline", textAlign: "left", ...sx }}>
      <ModalWrapper
        title={"Run scanners tool"}
        maxWidth="lg"
        trigger={trigger ? trigger : <Chip sx={{ mt: 2, border: 0 }}
          color="primary"
          variant="outlined" icon={<PlayCircle />} label="Analyze" />}
        child={
          <Box sx={{ p: 2, minWidth: "50em" }}>
            {hasPermission ? (
              <Box sx={{ m: 3 }}>
                <Typography sx={{ mt: 2, mb: 1, textAlign: "left" }}>
                  {"Store your platform key in an environment variable: "}
                </Typography>
                <Box sx={{ p: 1, backgroundColor: "neutral.100" }}>
                  <code>{'export APTORI_PLATFORM_KEY="my-platform-key"'}</code>
                </Box>
                <Typography sx={{ mt: 2, mb: 1, textAlign: "left" }}>
                  {"Choose scan type: "}
                </Typography>
                <Box>
                  {Object.keys(toolCommands)?.map((key) => (
                    <Button
                      key={key}
                      variant={scanTool === key ? "contained" : "outlined"}
                      sx={{ mr: 1 }}
                      onClick={() => setScanTool(key)}
                      size="small"
                    >
                      {key}
                    </Button>
                  ))}
                </Box>
                <Typography sx={{ mt: 2, mb: 1, textAlign: "left" }}>
                  {
                    "Optionally, enable direct links to lines of code in scan results by adding URL and branch name for your repository: "
                  }
                </Typography>
                <Box>
                  <form onSubmit={commitLabels}>
                    <TextField
                      sx={{ textAlign: "left", m: 0 }}
                      label="Repository URL"
                      helperText="Repository URL, e.g. https://github.com/your-repository"
                      variant="outlined"
                      size="small"
                      name="repoinput"
                    />
                    <TextField
                      sx={{ textAlign: "left", ml: 2 }}
                      label="Branch name"
                      helperText="Scanned branch name, e.g. master"
                      variant="outlined"
                      size="small"
                      name="branchinput"
                    />
                    <Button
                      variant={"contained"}
                      sx={{ ml: 2 }}
                      type={"submit"}
                      size="small"
                    >
                      Apply
                    </Button>
                  </form>
                </Box>
                {externalRunTypes.includes(scanTool) && (
                  <>
                    <Typography sx={{ mt: 2, mb: 1, textAlign: "left" }}>
                      {`Run trivy tool in a terminal with access to your ${scanTool} CLI and capture output in a json file using a similar command:`}
                    </Typography>
                    <Box sx={{ p: 1, backgroundColor: "neutral.100" }}>
                      <code>{getTrivyCommand()}</code>
                    </Box>
                    <Button
                      variant="outlined"
                      sx={{ mt: 2 }}
                      size="small"
                      onClick={() => {
                        copy(getTrivyCommand());
                        clearMessage();
                        setMessage({
                          title: "Trivy run command copied to clipboard",
                          type: "success",
                        });
                      }}
                    >
                      Copy
                    </Button>
                  </>
                )}
                <Typography sx={{ mt: 2, mb: 1, textAlign: "left" }}>
                  {"Copy and paste this command in your command line: "}
                </Typography>
                <Box sx={{ p: 1, backgroundColor: "neutral.100" }}>
                  <code>{getCommand()}</code>
                </Box>
                <Button
                  variant="outlined"
                  sx={{ mt: 2 }}
                  size="small"
                  onClick={() => {
                    copy(getCommand());
                    clearMessage();
                    setMessage({
                      title: "CLI run command copied to clipboard",
                      type: "success",
                    });
                  }}
                >
                  Copy
                </Button>
              </Box>
            ) : (
              <PermissionBlocker featureName="Analyze" />
            )}
          </Box>
        }
      />
    </Box>
  );
};

export default withRouter(ScannersPopup);
