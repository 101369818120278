import Box from "@mui/material/Box";

import { Alert, CircularProgress, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { API_SLUGS } from "../../constants";
import { axiosInstance } from "../../services/axios";
import AddApiForm from "../assets/AddApi/AddApiForm";
import AfterAPICreation from "./afterApiCreation";
var format = require("string-template");

interface IAPIOnboarding extends RouteComponentProps {
  assetId?: string;
  callback?: () => void;
}
const APIOnboarding = ({ callback, assetId }: IAPIOnboarding) => {
  const [api, setApi] = useState<any>(null);
  const [isFetchingApi, setIsFetchingApi] = useState(false);

  const fetchApi = useCallback(async () => {
    setIsFetchingApi(true);
    try {
      let apiResponse = await axiosInstance.get(
        format(API_SLUGS.GET_API, {
          //@ts-ignore
          uuid: assetId,
        })
      );
      setApi(apiResponse.data);
    } catch (error: any) { }
    setIsFetchingApi(false);
  }, [assetId]);

  useEffect(() => {
    if (assetId) {
      fetchApi();
    }
  }, [assetId, fetchApi]);

  const handleNewApi = async (api: any) => {
    setApi(api);
    try {
      await axiosInstance.put(`guides/quickstart/api`, { assetId: api.id });
    } catch (error: any) { }
  };

  return (
    <Box sx={{ textAlign: "left" }}>
      {!api && !isFetchingApi && (
        <AddApiForm preventRedirect callback={handleNewApi} />
      )}
      {api && !isFetchingApi && (
        <Alert
          variant="standard"
          color="info"
          icon={false}
          sx={{ p: 2, borderRadius: "5px" }}
        >
          <Typography variant="overline" color={"text.secondary"}>
            {api.projectName}
          </Typography>
          <Typography variant="h5">{api.name}</Typography>
        </Alert>
      )}
      {isFetchingApi && <CircularProgress disableShrink />}
      <Box sx={{ mt: 2 }}>
        {api && <AfterAPICreation assetId={api.id} callback={callback} />}
      </Box>
    </Box>
  );
};

export default withRouter(APIOnboarding);
