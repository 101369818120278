import { Assistant, Close } from "@mui/icons-material";
import {
  Alert,
  Box, Button, Card, Chip, Container, Grid, Modal,
  Paper, Typography
} from "@mui/material";
import { isEmpty } from "lodash";
import moment from "moment";
import { FC, useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { withRouter } from "react-router-dom";
import { getConfigs } from "../../../../configs";
import { SEVERITY_OPTIONS } from "../../../../constants";
import { axiosInstance } from "../../../../services/axios";
import { IAppState, useAppState } from "../../../../store/appState";
import DefinitionModal from "../../../assets/DefinitionModal";
import ModalWrapper from "../../../common/modal/wrapper";
import { DataCard } from "../../../dashboard";
import Loaders from "../../../dashboard/Loaders";
import { IDataTile } from "../runComponents/ResultData";
import ActionButton from "../shared/button";
import WidgetHandler from "./WidgetHandler";
import FaultInfoButton from "./faultInfoButton";
var format = require("string-template");
var jp = require("jsonpath");


const templates: any = {
  "sift_v1": [{
    widget: "info",
    name: {
      "title": {
        type: "const",
        value: "Operation"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.operationId"
      },
    },
    extras: {
      "title": {
        type: "keyPath",
        value: "fault.faultId"
      },
      "text": {
        type: "keyPath" || "const",
        value: "fault.faultName"
      },
    },
  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Reason"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.reason"
      },
    },
  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Remedy"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.remedy"
      },
    },
  },
  {
    widget: "sequence",
    data: "fault.sequence"
  }
  ],
  "semgrep_v1": [{
    widget: "info",
    name: {
      "title": {
        type: "const",
        value: "Issue Location"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.details.displayPath"
      },
      "hover": {
        type: "keyPath",
        value: "findingDetail.details.path"
      }
    },

  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Reason"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.reason"
      },
    },
  },

  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Evidence"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.details.evidence"
      },
    },
  },

  ],
  "trivy_v1": [{
    widget: "info",
    name: {
      "title": {
        type: "const",
        value: "Issue Location"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.details.displayPath"
      },
      "hover": {
        type: "keyPath",
        value: "findingDetail.details.path"
      }

    },

  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Package Name"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.details.PkgName"
      },
    },
  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Reason"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.reason"
      },
    },
  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Remedy"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.remedy"
      },
    },
  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Evidence"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.details.evidence"
      },
    },
  },

  ],
  "gitleaks_v1": [{
    widget: "info",
    name: {
      "title": {
        type: "const",
        value: "Issue Location"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.details.displayPath"
      },
      "hover": {
        type: "keyPath",
        value: "findingDetail.details.path"
      }

    },

  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Reason"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.reason"
      },
    },
  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Remedy"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.remedy"
      },
    },
  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Evidence"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.details.evidence"
      },
    },
  },

  ],
  "trivy_license_v1": [{
    widget: "info",
    name: {
      "title": {
        type: "const",
        value: "Issue Location"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.details.location"
      },


    },

  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Reason"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.reason"
      },
    },
  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Remedy"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.remedy"
      },
    },
  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Evidence"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.details.evidence"
      },
    },
  },

  ],

  "fallback": [{
    widget: "info",
    name: {
      "title": {
        type: "const",
        value: "Issue Location"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.details.displayPath"
      },
      "hover": {
        type: "keyPath",
        value: "findingDetail.details.path"
      }

    },

  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Reason"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.reason"
      },
    },
  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Remedy"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.remedy"
      },
    },
  },
  {
    widget: "text",
    data: {
      "title": {
        type: "const",
        value: "Evidence"
      },
      "text": {
        type: "keyPath",
        value: "findingDetail.details.evidence"
      },
    },
  },

  ]
}

export const IssueData: FC<any> = ({
  id,
  history,
  findingId,
  setIssueData = undefined,
}) => {
  const [issue, setIssue] = useState({} as any);
  const [latestFindingInfo, setLatestFindingInfo] = useState({} as any);
  const [openDefinitionModal, setOpenDefinitionModal] = useState(false);
  const [result, setResult] = useState({} as any);
  const [issueDataTiles, setIssueDataTiles] = useState([] as IDataTile[]);
  const { setOverrideBreadcrumb, } = useAppState() as IAppState
  useEffect(() => {
    if (result) {
      const xs = 12;
      const md = 6;
      const data: IDataTile[] = [
        {
          title: "API / Operation",
          description: `${issue?.assetName} / 
          ${latestFindingInfo && latestFindingInfo?.findingDetail
              ? latestFindingInfo?.findingDetail?.operationId
              : null
            }`,
          onClick: handleOpenDefinitionModal,
          xs,
          md,
        },
        {
          title: "Environment",
          description: issue.environmentName,
          xs,
          md,
        },
      ];
      setIssueDataTiles(data);
    }
  }, [issue, latestFindingInfo]);

  const fetchResult = async (id: string) => {
    try {
      let response = await axiosInstance.get(format(`/results/${id}`));
      setResult(response?.data);
    } catch (error: any) { }
  };

  useEffect(() => {
    fetchLatestFindingInfo();
  }, []);

  const fetchLatestFindingInfo = async () => {
    let findingDetailResponse: any = {};
    let faultResponse: any = {};
    try {
      let findingResponse = await axiosInstance.get(

        `/findings?limit=1&sort=-timestamp&issueId=${id}`

      );

      let finding = findingResponse?.data?.findings
        ? findingResponse?.data?.findings[0]
        : null;

      if (finding) {
        findingDetailResponse = await axiosInstance.get(
          format(
            //@ts-ignore
            `/findings/${findingId || finding?.id}`
          )
        );

        faultResponse = await axiosInstance.get(
          format(
            //@ts-ignore
            `/faults/${finding?.faultId}`
          )
        );
      }

      fetchResult(findingDetailResponse?.data?.resultId);
    } catch (error: any) { }

    setLatestFindingInfo({
      findingDetail: findingDetailResponse?.data,
      sequence: findingDetailResponse?.data?.sequence,
      fault: { id: findingDetailResponse?.data?.faultId, ...faultResponse?.data },
    });
  };

  useEffect(() => {
    fetchIssue();
  }, []);

  const handleOpenDefinitionModal = () => {
    setOpenDefinitionModal(true);
  };

  const handleCloseDefinitionModal = () => {
    setOpenDefinitionModal(false);
  };

  const fetchIssue = async () => {
    try {
      let response = await axiosInstance.get(format(`/issues/${id}`));
      setIssue(response?.data);
      setIssueData?.(response?.data);


    } catch (error: any) { }
  };
  useEffect(() => {
    let newBreadcrumb = {
      group: {
        id: issue?.groupId,
        name: issue?.groupName
      }, project: {
        id: issue?.projectId,
        name: issue?.projectName
      }
    }
    setOverrideBreadcrumb(newBreadcrumb)
  }
    , [issue, setOverrideBreadcrumb])



  return (
    <>

      <Box sx={{ pl: 1, mt: 3 }}>
        <Typography variant="overline"
          sx={{ fontSize: "12px", display: "block" }}
          color="text.secondary" onClick={() => history.push(`/findings?issueId=${id}`)}>
          <Chip label={latestFindingInfo?.findingDetail?.type} size="small" />&nbsp;Issue&nbsp;

          #{issue?.index}

        </Typography>
        <Typography variant="h5" sx={{ fontWeight: 600, lineHeight: 1 }} >
          {issue?.findingName}
        </Typography>
      </Box>

      <Box
        sx={{
          flexGrow: 1,
          mt: 3,
        }}
      >

        <Box sx={{ ml: 1, mr: 2, textAlign: "left" }} >

          {
            (templates?.[latestFindingInfo?.findingDetail?.schema] || templates?.["fallback"])?.map((widget: any, index: number) =>
              <WidgetHandler widget={widget} info={{ finding: latestFindingInfo, issue }} />
            )
          }

        </Box>



        <Modal open={openDefinitionModal} onClose={handleCloseDefinitionModal}>
          <DefinitionModal
            onClose={handleCloseDefinitionModal}
            selectedDefinition={{
              assetId: issue?.assetId,
              revId: result?.apiRevisionId,
            }}
          />
        </Modal>
      </Box>
    </>
  );
};

export const IssueRiskData: FC<any> = ({
  id,

  findingId,
  setIssueData,
}) => {
  const [issue, setIssue] = useState({} as any);
  const [latestFindingInfo, setLatestFindingInfo] = useState({} as any);

  useEffect(() => {
    fetchLatestFindingInfo();
  }, []);

  const fetchLatestFindingInfo = async () => {
    let findingDetailResponse: any = {};
    let faultResponse: any = {};
    try {
      let findingResponse = await axiosInstance.get(

        `/findings?limit=1&sort=-timestamp&issueId=${id}`

      );

      let finding = findingResponse?.data?.findings
        ? findingResponse?.data?.findings[0]
        : null;

      if (finding) {
        findingDetailResponse = await axiosInstance.get(
          format(
            //@ts-ignore
            `/findings/${findingId || finding?.id}`
          )
        );

        faultResponse = await axiosInstance.get(
          format(
            //@ts-ignore
            `/faults/${finding?.faultId}`
          )
        );
      }


    } catch (error: any) { }

    setLatestFindingInfo({
      findingDetail: findingDetailResponse?.data,
      sequence: findingDetailResponse?.data?.sequence,
      fault: { id: findingDetailResponse?.data?.faultId, ...faultResponse?.data },
    });
  };

  useEffect(() => {
    fetchIssue();
  }, []);


  const fetchIssue = async () => {
    try {
      let response = await axiosInstance.get(format(`/issues/${id}`));
      setIssue(response?.data);
      setIssueData(response?.data);
    } catch (error: any) { }
  };



  return (
    <>

      <FaultInfoButton
        fault={latestFindingInfo?.fault}
        findingDetail={latestFindingInfo?.findingDetail}
        issue={issue}
      />
    </>
  );
};

const IssueView: FC<any> = ({ history, match, id, location }) => {
  const [selectedFindingId, setSelectedFindingId] = useState(
    null as string | null
  );
  const useQuery = () => new URLSearchParams(location.search);
  const query = useQuery();
  const resultId = query.get("resultId");
  const findingId = query.get("findingId");
  const [issueId, setIssueId] = useState(id);
  const [issue, setIssue] = useState({} as any);
  const [selectedFaultGroup, setSelectedFaultGroup] = useState("");
  const [showHistory, setShowHistory] = useState(!issueId as boolean);
  const [selectedOperationId, setSelectedOperationId] = useState(null);
  const [assetId, setApiId] = useState(null);
  const [revId, setRevId] = useState(null);

  return (
    <>
      <Grid container columnSpacing={3}>
        <Grid xs={12} md={8}>
          <Card
            elevation={0}
            sx={{
              pl: 2,
              textAlign: "left",
              maxHeight: "85vh",
              overflow: "auto",
            }}
          >
            {/* <Modal
              open={openOperationModal}
              onClose={handleCloseOperationModal}
            >
              <OperationModal
                onClose={handleCloseOperationModal}
                selectedOperation={{
                  assetId: assetId!,
                  revId: revId!,
                  defaultOperationId: selectedOperationId,
                }}
              />
            </Modal> */}

            {!isEmpty(issue) ? <Box sx={{ display: "flex", justifyContent: "space-between" }}>

              <Box>
                <ActionButton
                  issueId={issue?.id}
                  buttons="BOTH"
                  state={issue?.state}
                  risk={issue?.risk}

                  user={issue?.assignee}
                  jira={{
                    url: issue?.jiraUrl,
                    number: issue?.jiraNumber,
                  }}
                />
              </Box>
              {issue?.id && <IssueFixButton issueId={issue?.id} />}
            </Box> : null}
            <IssueData
              id={issueId}
              history={history}
              findingId={findingId}
              setIssueData={setIssue}
            />
            <Grid xs={12}>
              <Box
                sx={{
                  display: showHistory ? "block" : "none",
                }}
              >
                <DataCard
                  name={""}
                  config={{
                    widget: "table",
                    enableContextFilter: true,
                    width: 8,
                    data_source: `${getConfigs().baseApiUrl
                      }/findings?faultGroupId=${selectedFaultGroup}&issueId=${issueId}&limit={limit}&offset={offset}`,
                    data_points: [
                      { key: "id", jsonpath: "$.findings[*].id" },
                      { key: "api", jsonpath: "$.findings[*].assetName" },
                      {
                        key: "operation",
                        jsonpath: "$.findings[*].operationId",
                      },
                      { key: "target", jsonpath: "$.findings[*].target" },
                      { key: "name", jsonpath: "$.findings[*].name" },
                      { key: "faultId", jsonpath: "$.findings[*].faultId" },
                      {
                        key: "issueId",
                        jsonpath: "$.findings[*].issueId",
                      },
                      {
                        key: "issueNumber",
                        jsonpath: "$.findings[*].issueNumber",
                      },
                      { key: "runId", jsonpath: "$.findings[*].resultId" },
                      { key: "findingType", jsonpath: "$.findings[*].type" },
                      { key: "severity", jsonpath: "$.findings[*].severity" },
                      {
                        key: "environment",
                        jsonpath: "$.findings[*].environmentName",
                      },
                      { key: "method", jsonpath: "$.findings[*].method" },
                      { key: "isNew", jsonpath: "$.findings[*].isNew" },
                    ],
                    pagination: true,
                    pagination_limit: 20,
                    columns: [
                      { key: "id", name: "ID", type: "hidden" },

                      {
                        key: "faultId",
                        name: "Fault",
                        type: "string",
                        width: "100px",
                        onClick: (row: any) => {
                          window.open(
                            `/findings?issueId=${row?.issueId}&findingId=${row?.id}`,
                            "_self"
                          );
                        },
                      },
                      {
                        key: "severity",
                        name: "Severity",
                        type: "tag",
                        width: "150px",
                        columnHeaderFilter: true,
                        columnHeaderFilterOptions: SEVERITY_OPTIONS,
                      },
                      {
                        key: "isNew",
                        name: "",
                        type: "custom",
                        code: (row: any) => (
                          <>
                            {row?.isNew ? (
                              <Chip
                                size="small"
                                color="success"
                                label="New"
                              ></Chip>
                            ) : null}
                          </>
                        ),
                        columnHeaderFilter: true,
                        columnHeaderFilterOptions: [
                          { key: true, label: "New" },
                        ],
                      },
                      { key: "operation", name: "Operation" },
                      {
                        key: "issueId",
                        name: "Issue UUID",
                        type: "hidden",
                      },
                      {
                        key: "runId",
                        name: "Run Id",
                        width: 100,
                        type: "custom",
                        code: (row: any) =>
                          row?.runId ? row?.runId.split("-")[0] : "",
                        onClick: (row: any) =>
                          history.push(`findings?resultId=${row?.runId}`),
                      },
                      {
                        key: "name",
                        name: "Name",
                        type: "string",
                        isSearchable: true,
                        width: "300px",
                      },
                    ],
                  }}
                />
              </Box>
            </Grid>
          </Card>
        </Grid>

        <Grid xs={12} md={4}>

          <Card
            elevation={0}
            sx={{ textAlign: "left", minHeight: "85vh" }}
          >

            <IssueRiskData
              id={issueId}

              findingId={selectedFindingId}
            />
            <Alert variant="standard" color="warning" icon={false} >
              <Typography variant="h6">Open for {issue?.duration} </Typography>
              {issue?.createdAt && <Typography variant="caption" color="textSecondary">First seen on  {moment(issue?.createdAt).format("DD MMM YYYY hh:mm A")}</Typography>}
              <br />
              <Button variant="text" size="small" color="primary" onClick={() => setShowHistory(!showHistory)} sx={{ p: 0 }}>
                {showHistory ? "Hide" : "Show"} History
              </Button>

              {showHistory && <DataCard
                name={""}
                config={{
                  widget: "table",
                  enableContextFilter: true,
                  hideSearch: true,
                  width: 8,
                  data_source: `${getConfigs().baseApiUrl
                    }/findings?faultGroupId=${selectedFaultGroup}&${resultId
                      ? `resultId=${resultId}&`
                      : issueId
                        ? `issueId=${issueId}&`
                        : ""
                    }limit={limit}&offset={offset}`,
                  data_points: [
                    { key: "id", jsonpath: "$.findings[*].id" },

                    { key: "runId", jsonpath: "$.findings[*].resultId" },
                    { key: "timestamp", jsonpath: "$.findings[*].timestamp" },
                    {
                      key: "issueId",
                      jsonpath: "$.findings[*].issueId",
                    },
                  ],
                  pagination: true,
                  pagination_limit: 20,
                  columns: [
                    { key: "id", name: "ID", type: "hidden" },
                    {
                      key: "issueId",
                      name: "Issue UUID",
                      type: "hidden",
                    },
                    {
                      key: "runId",
                      name: "Run Id",
                      width: 100,
                      type: "custom",
                      code: (row: any) =>
                        row?.runId ? row?.runId.split("-")[0] : "",
                      onClick: (row: any) => {
                        window.open(
                          `/findings?resultId=${row?.runId}`,
                          "_self"
                        );
                      },
                    },
                    { key: "timestamp", name: "Found At", type: "dateTime" },
                  ],
                }}
              />}

            </Alert>

          </Card>
        </Grid>
      </Grid>
      <Modal
        open={selectedFindingId != null}
        onClose={() => setSelectedFindingId(null)}
      >
        <Box
          sx={{
            minHeight: "100%",
            overflow: "auto",
            p: 3,
          }}
        >
          <Container maxWidth="lg">
            <Paper elevation={12} sx={{ p: 3 }}>
              <Close
                className="hand"
                onClick={() => setSelectedFindingId(null)}
              />

              <Box sx={{ maxHeight: "80vh", overflow: "auto" }}>
                <IssueData
                  id={issueId}
                  history={history}
                  findingId={selectedFindingId}
                />
              </Box>
            </Paper>
          </Container>
        </Box>
      </Modal>
    </>
  );
};

export const IssueFixButton: FC<any> = ({ issueId }) => {
  const [isloadingFix, setIsLoadingFix] = useState(false)
  const [fix, setFix] = useState<any>(null)
  const [isFixApplied, setIsFixApplied] = useState(false)
  const [appliedFix, setAppliedFix] = useState<any>(null)
  const fetchFix = async () => {
    setIsLoadingFix(true)
    try {
      let response = await axiosInstance.post("chat/suggestion?type=preview", {
        issueId: issueId
      })
      setFix(response.data)
    } catch (error) {
      console.log(error)
    }
    setIsLoadingFix(false)
  }

  const applyFix = async () => {
    try {
      const response = await axiosInstance.post("chat/suggestion?type=apply", {
        issueId: issueId,
        token: fix?.token
      })
      setIsFixApplied(true)
      setAppliedFix(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchFix()
  }, [])
  return <ModalWrapper
    title="Suggested Fix"
    maxWidth="md"

    trigger={
      <Button
        variant="text"
        color="secondary"
        size="small"
        sx={{ mt: -0.5 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", }}>
          <Assistant sx={{ mr: 1 }} />
          Suggest a Fix
        </Box>

      </Button>
    }
    child={
      <Box sx={{ p: 2 }}>


        <Box sx={{ mt: 2, minHeight: "5em", minWidth: "20em" }}>
          {
            isloadingFix ? <Loaders /> : <Box>
              <Typography variant="h6" sx={{ opacity: 0.6 }}>
                Fix
              </Typography>
              <ReactMarkdown>
                {fix?.codeSuggestion?.fixed_code}
              </ReactMarkdown>
              <Typography variant="h6" sx={{ opacity: 0.6 }}>
                Explanation
              </Typography>
              <ReactMarkdown>
                {fix?.codeSuggestion?.explanation}
              </ReactMarkdown>

            </Box>
          }
        </Box>
        {!appliedFix ?
          <Button sx={{ mt: 2 }} variant="contained" color="primary" size="small" disabled={isFixApplied || !fix?.token} onClick={() => applyFix()}
          >
            Post to git
          </Button>
          : <Alert severity="success" >
            <Typography variant="h6">
              Comment submitted on a Merge Request <a href={appliedFix?.url} target="_self">#{appliedFix?.mergeRequestId}</a>
            </Typography>
          </Alert>}
      </Box>
    }
  />
}

export default withRouter(IssueView);
