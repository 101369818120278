import create from "zustand";
import { devtools } from "zustand/middleware";
import { API_SLUGS, ROUTES, STRINGS } from "../constants";
import { axiosInstance } from "../services/axios";


export interface ISidebarState {
    projectsFetchedOnce: boolean
    projects: any[]
    context: null | { [key: string]: any }
    fetchContext: () => void
    setProjects: (projects: any[]) => void
    services: any
    setServices: (projectUUID: string, services: any[]) => void
    apis: any
    setApis: (serviceUUID: string, apis: any[]) => void
    isTreeOpen: boolean
    toggleTreeOpen: () => void
    contextualSidebarData: any
    setContextualSidebarData: (data: any) => void
}

export const useSidebarState = create(devtools(set => ({
    projectsFetchedOnce: false,
    projects: [],
    context: null,
    fetchContext: async () => {
        let contextResponse: any = null

        contextResponse = await axiosInstance.get(API_SLUGS.GET_CONTEXT)
        const currentUser = localStorage.getItem(STRINGS.LOCAL_STORAGE_USER)
        ? JSON.parse(localStorage.getItem("user") as string)
        : {}
        if (window.location.pathname !== ROUTES.JOIN && !contextResponse.data?.organizationId && currentUser?.isVerified ) {
            window.location.href = ROUTES.JOIN
        } else {
            set((state: ISidebarState) => {
                return { ...state, context: contextResponse.data }
            })
        }




    },
    setProjects: (projects: any[]) => {
        
        set((state: ISidebarState) => {
            return { ...state, projects: projects, projectsFetchedOnce: true }
        })
    },
    services: {},
    setServices: (projectUUID: string, services: any[]) => {
        set((state: ISidebarState) => {
            return { ...state, services: { ...state.services, [projectUUID]: services } }
        })
    },
    apis: {},
    setApis: (serviceUUID: string, apis: any[]) => {
        set((state: ISidebarState) => {
            return { ...state, apis: { ...state.apis, [serviceUUID]: apis } }
        })
    },

    isTreeOpen: false,
    toggleTreeOpen: () => {
        set((state: ISidebarState) => {
            return { ...state, isTreeOpen: !state.isTreeOpen }
        })
    },
    contextualSidebarData: { description: "", tabs: [] },
    setContextualSidebarData: (data) => {
        set((state: ISidebarState) => {
            return { ...state, contextualSidebarData: data }
        })
    },

} as ISidebarState)))