import {
  Box, Button,
  ButtonGroup,
  ClickAwayListener,
  Grow, MenuItem,
  MenuList, Paper,
  Popper
} from "@mui/material";
import * as React from "react";
import { withRouter } from "react-router-dom";
import { ROUTES } from "../../../constants";
import useFetch from "../../../hooks/http/useFetch";
import { Plus } from "../../../icons/plus";
// import { ArrowDropDownIcon } from '@mui/icons-material';

const options = [
  // 'Add Organization',

  "Add Project",
  "Add API",
  "Add Repository",
];

const SplitButton: React.FC<any> = ({ history, match, groupId = undefined }) => {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  //@ts-ignore
  const { data: groups, loading } = useFetch("/group");





  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    let groupFilter = ""
    if (groupId) {
      groupFilter = `?groupId=${groupId}`
    }

    if (index === 0) {
      history.push(ROUTES.ADD_PROJECT + groupFilter);
    }

    if (index === 1) {
      history.push(ROUTES.ADD_API + groupFilter);
    }

    if (index === 2) {
      history.push(ROUTES.ADD_REPOSITORY + groupFilter);
    }

    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start"
        // position: "fixed",
        // bottom: "2em",
        // right: "1em",
        // zIndex: 999,
      }}
    >
      <ButtonGroup
        sx={{ background: "secondary.main" }}
        ref={anchorRef}
        aria-label="split button"
      >
        <Button
          size="small"
          sx={{ p: 1, mt: -1 }}
          variant="text"
          onClick={handleToggle}
        >

          Add

          <Plus />
        </Button>
      </ButtonGroup>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        id="popper-add-universal"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              zIndex: 10,
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

export default withRouter(SplitButton);
