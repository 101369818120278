import { Assistant, Replay } from "@mui/icons-material";
import { Alert, Box, Chip, IconButton, LinearProgress, TextField, Tooltip, Typography } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { axiosInstance } from "../../../services/axios";
import ActionButton from "../../analysis/ViewFindings/shared/button";

const AiSuggestions: FC<any> = ({ prompt, scope }) => {
    const [promptValue, setPromptValue] = useState<string>(prompt);
    const [suggestions, setSuggestions] = useState<any>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const fetchAISuggestions = async () => {
        setIsLoading(true);
        try {

            const response = await axiosInstance.post('chat/qaprompt', {
                prompt: promptValue,
                scope: scope,
                responseType: "json"
            });
            console.log({ response });
            setSuggestions(response.data);



        }
        catch (error) {
            console.error(error);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        fetchAISuggestions();
    }, [])

    if (isLoading) {
        return <Alert sx={{ ml: 2, mr: 4, border: "2px solid #10B98164", backgroundColor: "#10B98108", p: 3, borderRadius: "0.35em" }} icon={<Assistant />}>
            <Typography variant="h6" sx={{ pb: 2 }}>AI is suggesting</Typography>
            <LinearProgress color="secondary" />

        </Alert>
    }


    return <Alert sx={{ ml: 2, mr: 4, border: "2px solid #10B98164", backgroundColor: "#10B98108", p: 3, borderRadius: "0.35em" }} icon={<Assistant />}>
        <Typography variant="h6">AI Suggestions</Typography>
        <Box sx={{ mt: 2, display: "flex", justifyConten: "space-between" }}>
            <TextField
                label="Prompt"
                value={promptValue}
                onChange={(e) => setPromptValue(e.target.value)}
                fullWidth
                color="secondary"
                variant="filled"
                sx={{ mb: 2 }}
            />
            <IconButton sx={{ height: "fit-content", mt: 2 }} size="small" onClick={fetchAISuggestions} color="secondary" ><Replay /></IconButton>
        </Box>


        {
            /** suggestions will look like sample data */
            suggestions?.map((suggestion: any) => {
                return <Box
                    className="hand"
                    sx={{

                        pt: 1,
                        mb: 1,
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                    onClick={() => {
                        window.open(`/findings?issueId=${suggestion?.["finding_issue_uuid"]}`, "_self")
                    }}
                >
                    <Box sx={{ display: "inline" }}>

                        <Box >
                            <Typography variant="overline" >#{suggestion?.["incident_number"]}&nbsp;<i>{suggestion?.["fault_id"]}</i></Typography>
                            <Typography variant="body1" sx={{ fontWeight: 600 }}>
                                {suggestion?.["finding_name"]}
                            </Typography>
                        </Box>

                        {suggestion?.["is_new"] ? (
                            <Chip size="small" color="success" label="New" sx={{ ml: 1 }}></Chip>
                        ) : null}
                    </Box>
                    <Box sx={{ display: "flex" }}>
                        <Tooltip title="Risk">
                            <ActionButton
                                issueId={suggestion?.["finding_issue_uuid"]}
                                buttons="RISK"
                                risk={suggestion?.["risk"]}
                                state={suggestion?.["state"]}
                                user={suggestion?.["assignee_id"]}
                            />
                        </Tooltip>


                    </Box>
                </Box>
            })
        }
    </Alert>
}

export default AiSuggestions;