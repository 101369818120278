import { Close, FilterAlt, FilterAltOutlined } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  styled
} from "@mui/material";
import { filter, find, groupBy, isEmpty, orderBy } from "lodash";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import { getConfigs } from "../../../../configs";
import useFetch from "../../../../hooks/http/useFetch";
import useFetchOperations from "../../../../hooks/process/useFetchOperations";
import { axiosInstance } from "../../../../services/axios";
import { FaultsList } from "../../../assets/apiComponents/ResultNewView";
import CommonPopper from "../../../common/commonPopper";
import VisxDrag from "../../../common/resourceGraph/visxDrag";
import OperationView from "../../../generators/OperationView";
import PostmanOperations from "../../../generators/PostmanGenerators";
import { listOfJsonKeyPaths } from "../../../generators/treeFunctions";
import ActionPopup from "./apiSettingsComponents/ActionPopup";
import ResourcePopup from "./apiSettingsComponents/ResourcePopup";

const APISettings = ({
  defaultSelectedOperationId = null,
  hideSidebar = false,
  assetId,
  revId = null,
  uploadGenerators = false,
}: any) => {
  const [selectedOperationId, setSelectedOperationId] = useState(
    defaultSelectedOperationId
  );
  const virtuoso = useRef(null);
  const [selectedOperation, setSelectedOperation] = useState<any>(null);
  const [selectedTab, setSelectedTab] = useState<"Fields" | "Issues">("Fields");
  const [selectedViewTab, setSelectedViewTab] = useState<"Graph" | "List">(
    getConfigs().experimentalFeatures ? "Graph" : "List"
  );
  const [scrollToResourceId, setScrollToResourceId] = useState<any>(null);
  const [userSettingsMap, setUserSettingsMap] = useState<any>({});
  const [operationsByResource, setOperationsByResource] = useState<any>({});
  const [latestRun, setLatestRun] = useState<any>(null);
  const [rightPanelVisibility, setRightPanelVisibility] = useState(false);
  const { data: faultsData } = useFetch(`/issues?assetId=${assetId}`);
  const operationFaults = useMemo(() => {
    if (!faultsData) return [];
    return faultsData?.issues?.filter((fault: any) => {
      return fault?.operationId === selectedOperationId;
    });
  }, [faultsData, selectedOperationId]);

  const operationRiskMap = useMemo(() => {
    if (!faultsData) return {};
    const operationRiskMap: any = {};
    faultsData?.issues?.forEach((fault: any) => {
      if (!operationRiskMap[fault?.operationId]) {
        operationRiskMap[fault?.operationId] = {
          high: 0,
          medium: 0,
          low: 0,
        };
      }
      if (["high", "medium", "low"].includes(fault?.risk)) {
        operationRiskMap[fault?.operationId][fault?.risk] += 1;
      }
    });
    return operationRiskMap;
  }, [faultsData]);

  const operationCategoryMap = useMemo(() => {
    if (!faultsData) return {};
    const operationCategoryMap: any = {};
    faultsData?.issues?.forEach((fault: any) => {
      if (!operationCategoryMap[fault?.operationId]) {
        operationCategoryMap[fault?.operationId] = {};
      }
      if (!operationCategoryMap[fault?.operationId][fault?.categoryName]) {
        operationCategoryMap[fault?.operationId][fault?.categoryName] = 0;
      }
      operationCategoryMap[fault?.operationId][fault?.categoryName] += 1;
    });
    return operationCategoryMap;
  }, [faultsData]);

  const riskOptionsWithCount = useMemo(() => {
    if (!faultsData) return [];
    const riskOptionsWithCount: any = [];
    const riskCountMap: any = {
      high: 0,
      medium: 0,
      low: 0,
    };
    faultsData?.issues?.forEach((fault: any) => {
      if (["high", "medium", "low"].includes(fault?.risk)) {
        riskCountMap[fault?.risk] += 1;
      }
    });
    Object.keys(riskCountMap).forEach((risk) => {
      riskOptionsWithCount.push({
        label: risk,
        value: risk,
        count: riskCountMap[risk],
      });
    });
    return riskOptionsWithCount;
  }, [faultsData]);

  const categoryOptionsWithCount = useMemo(() => {
    if (!faultsData) return [];
    const categoryOptionsWithCount: any = [];
    const categoryCountMap: any = {};
    faultsData?.issues?.forEach((fault: any) => {
      if (!categoryCountMap[fault?.categoryName]) {
        categoryCountMap[fault?.categoryName] = 0;
      }
      categoryCountMap[fault?.categoryName] += 1;
    });
    Object.keys(categoryCountMap).forEach((category) => {
      categoryOptionsWithCount.push({
        label: category,
        value: category,
        count: categoryCountMap[category],
      });
    });
    return categoryOptionsWithCount;
  }, [faultsData]);

  const [filters, setFilters] = useState<any>({});

  const filteredOperationsByResource = useMemo(() => {
    if (isEmpty(filters)) return operationsByResource;
    const filteredOperationsByResource: any = {};
    Object.keys(operationsByResource)?.forEach((resource) => {
      filteredOperationsByResource[resource] = operationsByResource[
        resource
      ].filter((operation: any) => {
        let isOperationValid = true;
        Object.keys(filters)?.forEach((filterName) => {
          if (filterName === "category") {
            const thisOperationCategoryMap = operationCategoryMap[operation.id];
            if (!thisOperationCategoryMap) {
              isOperationValid = false;
              return;
            }
            filters[filterName].forEach((filterValue: any) => {
              if (!thisOperationCategoryMap[filterValue]) {
                isOperationValid = false;
                return;
              }
            });
          }
          if (filterName === "risk") {
            const thisOperationRiskMap = operationRiskMap[operation.id];
            if (!thisOperationRiskMap) {
              isOperationValid = false;
              return;
            }
            filters[filterName].forEach((filterValue: any) => {
              if (!thisOperationRiskMap[filterValue]) {
                isOperationValid = false;
                return;
              }
            });
          }
        });
        return isOperationValid;
      });
    });
    return filteredOperationsByResource;
  }, [filters, operationsByResource, operationCategoryMap, operationRiskMap]);

  const handleFilterChange = (filterName: string, filterValue: any) => {
    //push flter value in array if not present and if present then remove it
    if (filters[filterName]?.includes(filterValue)) {
      setFilters({
        ...filters,
        [filterName]: filters[filterName].filter(
          (value: any) => value !== filterValue
        ),
      });
      return;
    } else {
      setFilters({
        ...filters,
        [filterName]: [...(filters[filterName] || []), filterValue],
      });
    }
  };

  const {
    operations,
    loading: areOperationsLoading,
    reFetchUserOperationsSettings,
  } = useFetchOperations(
    "revision",
    assetId,
    undefined,
    revId || latestRun?.apiRevisionId
  );
  const [IOOptions, setIOOptions] = useState<any[]>([]);

  useEffect(() => {
    getLatestRun();
  }, []);

  useEffect(() => {
    evaluateOperations();
  }, [userSettingsMap, operations]);

  useEffect(() => {
    if (!filteredOperationsByResource) return;

    const index = Object.keys(filteredOperationsByResource).findIndex(
      (resource) => resource === scrollToResourceId
    );
    if (index !== -1) {
      setTimeout(() => {
        //@ts-ignore
        virtuoso?.current?.scrollToIndex({
          index: index,
          align: "start",
          behavior: "smooth",
        });

        setTimeout(() => {
          setScrollToResourceId(null);
        }, 3000);
      }, 500);
    }
  }, [filteredOperationsByResource, scrollToResourceId]);

  const getLatestRun = async () => {
    if (revId) return;
    try {
      const latestRunsResponse = await axiosInstance.get(
        `/results?limit=1&assetId=${assetId}`
      );
      let latestRun = latestRunsResponse?.data?.results?.[0];
      const latestRunDetailsResponse = await axiosInstance.get(
        `results/${latestRun.id}`
      );
      setLatestRun(latestRunDetailsResponse?.data);
    } catch (error) { }
  };

  const handleIdentifierSelect = async (
    identifier: string | null,
    operation: any
  ) => {
    try {
      await axiosInstance.put(`/apis/${assetId}/operations/resourceprofiles`, {
        operation: operation.operationID,
        ...userSettingsMap[operation.operationID],
        identifier,
      });

      reFetchUserOperationsSettings();
    } catch (error) { }
  };

  useEffect(() => {
    if (getConfigs().experimentalFeatures) return;
    // if (!isEmpty(operationsByResource) && !selectedOperationId) {
    //   const firstResource = Object.keys(operationsByResource)[0];
    //   if (!isEmpty(firstResource)) {
    //     setSelectedOperationId(
    //       operationsByResource[firstResource][0]?.operationID
    //     );
    //   }
    // }
  }, [operationsByResource]);

  const evaluateOperations = useCallback(() => {
    if (!operations) return;
    let newOperations = [...operations];

    setOperationsByResource(
      groupBy(
        orderBy(
          newOperations,
          (operation) => operation?.settingsToUse?.resource
        ),
        (operation) => operation?.settingsToUse?.resource
      )
    );
  }, [operations]);

  useEffect(() => {
    if (!operations) return;
    try {
      if (selectedOperationId) {
        const newSelectedOperation = find(operations, {
          operationID: selectedOperationId,
        });
        if (newSelectedOperation) {
          setSelectedOperation(newSelectedOperation);
        }
      }
    } catch (error) { }
  }, [operations, selectedOperationId]);

  useEffect(() => {
    evaluateOperations();
  }, [operations, evaluateOperations]);

  const listFieldsOfOperation = (operation: any) => {
    if (!operation) return;
    let listItems: any[] = [];

    listItems = [
      ...listItems,
      ...listOfJsonKeyPaths(operation?.["inputs"]).map((o: string) => ({
        operation: operation?.path,
        key: o,
      })),
    ];

    listItems = [
      ...listItems,
      ...listOfJsonKeyPaths(operation?.["outputs"]).map((o: string) => ({
        operation: operation?.path,
        key: o,
      })),
    ];

    return listItems || [];
  };

  useEffect(() => {
    if (selectedOperation) {
      const flattenedList = listFieldsOfOperation(selectedOperation);
      if (flattenedList) {
        setIOOptions(flattenedList);
      }
    }
  }, [selectedOperation]);

  return (
    <Grid container sx={{}} spacing={2} rowGap={2} columnSpacing={2}>
      {getConfigs().experimentalFeatures && false && (
        <Grid item xs={12}>
          <Tabs
            sx={{
              backgroundColor: "background.default",
              width: "fit-content",
              height: "min-content",
              minHeight: 0,
              borderRadius: "5px",
              pr: 1,
              pl: 2,
              ".MuiTabs-indicator": {
                display: "none",
              },
            }}
            value={selectedViewTab}
            onChange={(e, value) => {
              setSelectedViewTab(value);
            }}
          >
            {["Graph", "List"]?.map((category: any) => {
              return (
                <StyledTab
                  sx={{
                    fontWeight: 600,
                    height: "min-content",
                    my: 1,
                    mr: 1,
                    lineHeight: 0,
                    px: 2,
                    py: 2,
                    minHeight: 0,
                    "&.Mui-selected": {
                      backgroundColor: "background.paper",
                      color: "primary.main",
                      borderRadius: "5px",
                      border: 0,
                    },
                  }}
                  label={category}
                  value={category}
                />
              );
            })}
          </Tabs>
        </Grid>
      )}

      {uploadGenerators ? <PostmanOperations assetId={assetId} /> : null}

      {!uploadGenerators && !hideSidebar && (
        <Grid item xs={12} md={rightPanelVisibility ? 4 : 12} sx={{ backgroundColor: "#54545412", borderRadius: 2, }}>
          <Box sx={{ display: "flex", justifyContent: "space-between", }}>
            <Typography variant="h6" sx={{ fontSize: "1em", ml: 1 }}>Resources & Operations</Typography>
            {selectedTab === "Issues" && (
              <FilterComponent
                filters={filters}
                clearFilter={() => setFilters({})}
                handleFilterChange={handleFilterChange}
                riskOptionsWithCount={riskOptionsWithCount}
                categoryOptionsWithCount={categoryOptionsWithCount}
              />
            )}
          </Box>
          {areOperationsLoading && isEmpty(operations) && (
            <CircularProgress disableShrink />
          )}
          <Virtuoso
            style={{ minHeight: "55vh", height: "auto" }}
            ref={virtuoso}
            totalCount={Object.keys(filteredOperationsByResource).length}
            itemContent={(index: any) => {
              return (
                <Box
                  sx={{
                    mx: 1,
                    backgroundColor:
                      scrollToResourceId ===
                        Object.keys(filteredOperationsByResource)[index]
                        ? "#54545412"
                        : null,
                  }}
                >
                  <Typography variant="h6" sx={{ py: 2 }}>
                    {Object.keys(filteredOperationsByResource)[index]}
                  </Typography>
                  {filteredOperationsByResource[
                    Object.keys(filteredOperationsByResource)[index]
                  ]?.map((operation: any) => (
                    <OperationListItem
                      operation={operation}
                      assetId={assetId}
                      selectedTab={selectedTab}
                      operationRiskMap={operationRiskMap}
                      getResourceSettings={reFetchUserOperationsSettings}
                      resources={Object.keys(filteredOperationsByResource)}
                      selectedOperationId={selectedOperationId}
                      setSelectedOperationId={(operationId: string) => {
                        setSelectedViewTab("List");
                        setSelectedOperationId(operationId);
                        setSelectedOperation(operation);
                        setRightPanelVisibility(true);
                      }}
                    />
                  ))}
                </Box>
              );
            }}
          />
        </Grid>
      )}
      {false && getConfigs().experimentalFeatures && rightPanelVisibility && (
        <Grid
          xs={12}
          md={hideSidebar ? 12 : 9}
          sx={{ display: selectedViewTab === "Graph" ? "block" : "none" }}
        >
          <VisxDrag
            assetId={assetId}
            onNodeClick={(node: any) => {
              // setSelectedViewTab("List");

              setScrollToResourceId(node?.id);
              // const firstOperationOfResource =
              //   filteredOperationsByResource[node?.id]?.[0];

              // if (firstOperationOfResource) {
              //   setSelectedOperationId(firstOperationOfResource?.operationID);
              //   setSelectedOperation(firstOperationOfResource);
              // }
            }}
            width={Math.max(
              Object.keys(filteredOperationsByResource).length * 40,
              1500
            )}
            height={Math.max(
              Object.keys(filteredOperationsByResource).length * 40,
              600
            )}
          // width={600}
          // height={600}
          />
        </Grid>
      )}
      {selectedViewTab === "List" && rightPanelVisibility && (
        <>
          {!uploadGenerators && (
            <Grid xs={12} md={hideSidebar ? 12 : 8} sx={{ p: 2, pt:0,  borderRadius: 2 }}>
              <Grid
                item
                xs={12}
                sx={{ display: "flex", justifyContent: "space-between" }}
              >

                <Tabs
                  sx={{
                    backgroundColor: "background.default",
                    width: "fit-content",
                    height: "min-content",
                    minHeight: 0,
                    borderRadius: "5px",

                    pr: 1,
                    pl: 2,
                    ".MuiTabs-indicator": {
                      display: "none",
                    },
                  }}
                  value={selectedTab}
                  onChange={(e, value) => {
                    setSelectedTab(value);
                  }}
                >
                  {["Fields", "Issues"]?.map((category: any) => {
                    if (category === "Issues" && isEmpty(operationFaults))
                      return null;
                    return (
                      <StyledTab
                        sx={{
                          fontWeight: 600,
                          height: "min-content",
                          my: 1,
                          mr: 1,
                          lineHeight: 0,
                          px: 2,
                          py: 2,
                          minHeight: 0,
                          "&.Mui-selected": {
                            backgroundColor: "background.paper",
                            color: "primary.main",
                            borderRadius: "5px",
                            border: 0,
                          },
                          // borderBottom: category === "Issues" ? "4px solid" : 0,
                          // borderColor: `${operationRiskMap?.[selectedOperationId]?.high > 0
                          //   ? "error"
                          //   : operationRiskMap?.[selectedOperationId]
                          //     ?.medium > 0
                          //     ? "warning"
                          //     : "info"
                          //   }.main`,
                        }}
                        label={category}
                        value={category}
                      />
                    );
                  })}
                </Tabs>
                <Tooltip title="Close right panel">
                  <Close
                    onClick={() => {
                      setRightPanelVisibility(false)
                      setSelectedOperationId(null)
                      setSelectedOperation(null)
                    }}
                    color="inherit"
                    sx={{ mr: 0 }}
                    className="hand"
                  />
                </Tooltip>
              </Grid>

              <Grid sx={{ display: "flex", justifyItems: "space-between" }}>
                <Typography
                  variant="h6"
                  sx={{ flex: 2, textAlign: "left", p: 1 }}
                >
                  {selectedOperation?.path}
                </Typography>

                {selectedOperation &&
                  selectedTab === "Fields" &&
                  ["create", "list"].indexOf(
                    selectedOperation?.settingsToUse?.action
                  ) > -1 &&
                  !isEmpty(IOOptions) && (
                    <IDSelector
                      IOOptions={IOOptions}
                      handleIdentifierSelect={handleIdentifierSelect}
                      selectedOperation={selectedOperation}
                    />
                  )}
              </Grid>
              <Box
                sx={{
                  display: selectedTab !== "Issues" ? "none" : "block",
                  maxHeight: "55vh",
                  overflow: "auto",
                }}
              >
                <FaultsList faults={operationFaults} />
              </Box>
              {selectedOperationId && (latestRun || revId) && (
                <Box
                  sx={{
                    maxHeight: "55vh",
                    overflow: "auto",
                    display: selectedTab !== "Fields" ? "none" : "block",
                  }}
                >
                  <OperationView
                    assetId={assetId}
                    revId={revId || latestRun?.apiRevisionId}
                    defaultOperationId={selectedOperationId as string}
                    view="ADD"
                  />
                </Box>
              )}
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};

const IDSelector: FC<any> = ({
  IOOptions,

  handleIdentifierSelect,
  selectedOperation,
}) => {
  const [idSelectorValue, setIDSelectorValue] = useState<any>(null);

  useEffect(() => {
    if (isEmpty(IOOptions)) return;
    const identifier = selectedOperation?.settingsToUse?.identifier;
    const selectorValue = find(IOOptions, { key: identifier });
    setIDSelectorValue(selectorValue);
  }, [IOOptions, selectedOperation?.settingsToUse?.identifier]);

  return (
    <Autocomplete
      sx={{ maxWidth: "50%", textAlign: "left", flex: 1 }}
      size="small"
      getOptionLabel={(option): any => option.key}
      options={IOOptions}
      onChange={(e, value) => {
        handleIdentifierSelect(value?.key || null, selectedOperation);
      }}
      value={idSelectorValue}
      filterOptions={(options, params) => {
        const filtered = filter(options, function (item: any) {
          return item.key.indexOf(params.inputValue) > -1;
        });

        return filtered;
      }}
      renderInput={(params): JSX.Element => {
        return (
          <TextField
            autoComplete="off"
            name="id"
            variant="outlined"
            label="select ID"
            {...params}
          />
        );
      }}
    />
  );
};

const OperationListItem = ({
  assetId,
  operation,
  resources,
  selectedTab,
  operationRiskMap,
  getResourceSettings,
  selectedOperationId,
  setSelectedOperationId,
}: any) => {
  const [operationSetting, setOperationSetting] = useState({
    ...operation?.settingsToUse,
  });

  useEffect(() => {
    setOperationSetting({ ...operation?.settingsToUse });
  }, [operation?.settingsToUse]);

  const handleActionSelect = async (action: string, operation: any) => {
    try {
      await axiosInstance.put(`/apis/${assetId}/operations/resourceprofiles`, {
        operation: operation.operationID,
        action,
        resource: operationSetting.resource,
      });
      setOperationSetting({ ...operationSetting, action });
      getResourceSettings();
    } catch (error) { }
  };
  const handleResourceSelect = async (resource: string, operation: any) => {
    try {
      await axiosInstance.put(`/apis/${assetId}/operations/resourceprofiles`, {
        operation: operation.operationID,
        resource,
        action: operationSetting.action,
      });
      setOperationSetting({ ...operationSetting, resource });
      getResourceSettings();
    } catch (error) { }
  };

  return (
    <Box sx={{ pb: 1 }}>
      <Box
        className="hand"
        sx={{
          maxWidth: "100%",
          overflow: "auto",
          display: "flex",
          justifyContent: "space-between",
          p: 1,
          borderRadius: "5px",

          "&:hover": {
            backgroundColor: "#54545418",
            borderRadius: "5px",
          },
          ...(selectedOperationId === operation.operationID
            ? {
              backgroundColor: "#54545445",


            }
            : {}),
        }}
      >
        <Box
          sx={{ width: "100%" }}

          onClick={() => setSelectedOperationId(operation.operationID)}
        >

          <Box sx={{ display: "flex", justifyContent: "space-between", }}>
            <Box sx={{ display: "flex" }}>
              {!isEmpty(operation.userSettings) ? (
                <Tooltip title="User has overwritten the parent resource or the action." >
                  <Chip sx={{ mx: 0.5, p: 0, pb: 0.5, mt: 0.25, height: "fit-content" }} variant="filled" color="primary" size="small" label={<Typography variant="overline" sx={{ fontSize: "0.65em" }}>O</Typography>} />
                </Tooltip>
              ) : (
                <Tooltip title="This operations is set to the action and the resource identified automatically.">
                  <Chip
                    sx={{ mx: 0.5, p: 0, pb: 0.35, mt: 0.25, height: "fit-content" }}
                    variant="outlined"
                    color="primary"
                    size="small"
                    label={<Typography variant="overline" sx={{ fontSize: "0.65em" }}>A</Typography>}
                  />
                </Tooltip>
              )}
              <Chip
                label={
                  <Typography variant="overline" sx={{ fontSize: "0.75em" }}>{operation.method}</Typography>
                }
                size="small"
                variant="filled"
                color="success"
                sx={{ display: "inline", mx: 0.5, p: 0, pb: 0.5, mt: 0.25, height: "fit-content", width: "4em", }}
              />
              <Tooltip title={operation.path} placement="top-start">
                <Typography variant="body2" sx={{ fontWeight: 600 }}>
                  {operation.path}

                </Typography>
              </Tooltip>


            </Box>

            <Box sx={{ display: "flex", justifyContent: "end" }}>
              {selectedTab === "Fields" && (
                <>

                  {["create", "list"].indexOf(operationSetting.action) > -1 && (
                    <Tooltip
                      title={
                        operationSetting.identifier || "No ID field has been selected"
                      }
                    >
                      <Chip
                        variant="filled"
                        size="small"
                        sx={{ mx: 0.5, p: 0, pb: 0.5, mt: 0.25, height: "fit-content" }}
                        label={<Typography variant="overline" sx={{ fontSize: "0.65em" }}>ID</Typography>}
                        color={operationSetting.identifier ? "default" : "error"}
                      />
                    </Tooltip>
                  )}


                </>
              )}



              <ActionPopup
                action={operationSetting.action}
                callback={(action: string) =>
                  handleActionSelect(action, operation)
                }
              />




              {selectedTab === "Issues" &&
                operationRiskMap?.[operation.operationID] && (
                  <>
                    {Object.keys(operationRiskMap?.[operation.operationID])?.map(
                      (risk: any) => {
                        return (
                          operationRiskMap?.[operation.operationID]?.[risk] > 0 && (
                            <Chip
                              label={
                                operationRiskMap?.[operation.operationID]?.[risk]
                              }
                              size="small"
                              color={
                                risk === "high"
                                  ? "error"
                                  : risk === "medium"
                                    ? "warning"
                                    : "info"
                              }
                              sx={{ display: "inline", mx: 0.5, p: 0, height: "fit-content" }}
                            />
                          )
                        );
                      }
                    )}
                  </>
                )}
              <ResourcePopup
                resources={resources}
                callback={(resource: string) =>
                  handleResourceSelect(resource, operation)
                }
              />
            </Box>


          </Box>

        </Box>


      </Box>
    </Box>
  );
};

const FilterComponent: FC<any> = ({
  filters,
  handleFilterChange,
  clearFilter,
  riskOptionsWithCount,
  categoryOptionsWithCount,
}) => {
  return (
    <CommonPopper
      elevatePopper
      trigger={
        isEmpty(filters?.risk) && isEmpty(filters?.category) ? (
          <FilterAltOutlined className="hand" />
        ) : (
          <>
            <Button onClick={clearFilter} size="small">
              Clear
            </Button>
            <FilterAlt className="hand" />
          </>
        )
      }
      child={
        //Filter component for risk and category with checkboxes
        <Box sx={{ p: 2 }}>
          <Typography>Risk</Typography>
          <Box>
            {riskOptionsWithCount?.map((riskOption: any) => (
              <Box
                key={riskOption}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  defaultChecked={filters?.risk?.includes(riskOption.value)}
                  value={filters?.risk?.includes(riskOption.value)}
                  onChange={() => handleFilterChange("risk", riskOption.value)}
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <Typography>
                  {riskOption.label}({riskOption.count})
                </Typography>
              </Box>
            ))}
          </Box>
          <Typography>Category</Typography>
          <Box>
            {categoryOptionsWithCount?.map((categoryOption: any) => (
              <Box
                key={categoryOption}
                sx={{ display: "flex", alignItems: "center" }}
              >
                <Checkbox
                  value={filters?.category?.includes(categoryOption.value)}
                  defaultChecked={filters?.category?.includes(
                    categoryOption.value
                  )}
                  onChange={() =>
                    handleFilterChange("category", categoryOption.value)
                  }
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
                <Typography>
                  {categoryOption.label}({categoryOption.count})
                </Typography>
              </Box>
            ))}
          </Box>
        </Box>
      }
    />
  );
};

const StyledTab = styled(Tab)(({ theme }) => ({
  "&.Mui-selected": {
    boxShadow: theme.shadows[10],
  },
}));

export default APISettings;
