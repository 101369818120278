import { Box } from '@mui/system';
import { RouteComponentProps, withRouter } from 'react-router';
import LayoutFlow from '../layouts/Overview';
var format = require("string-template")

const Graph: React.FC<RouteComponentProps> = ({ match }) => {

  return (
    <Box sx={{ width: '100vw', height: '100vh', mt: "5em", ml: "5em" }}>
      <LayoutFlow assetId={"apiID"} revId={"revID"} />
    </Box>
  );
};

export default withRouter(Graph);
