import {
  Box,
  Button,
  Divider,
  Grid,
  Typography
} from "@mui/material";
import { filter } from "lodash";
import { useEffect, useState } from "react";
import { CheckCircleOutlined } from "../../../icons/check-circle-outlined";
import { axiosInstance } from "../../../services/axios";

const QuickOverview: React.FC<any> = ({ view = "QUICK_START" }) => {
  const [quickStart, setQuickStart] = useState([] as any);
  const [features, setFeatures] = useState([] as any);
  const [isFetchingProjects, setIsFetchingProjects] = useState(false);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    setIsFetchingProjects(true);
    try {
      let quickStartResponse = await axiosInstance.get("/guides/quickstart");
      setQuickStart(quickStartResponse?.data?.quickstart);
    } catch (error: any) { }

    try {
      let latestReleaseResponse = await axiosInstance.get("/guides/latest");
      setFeatures(latestReleaseResponse?.data?.features);
    } catch (error: any) { }

    setIsFetchingProjects(false);
  };

  return (
    <Grid
      item
      container
      md={12}
      columnSpacing={3}
      sx={{ textAlign: "left", p: 2 }}
    >
      {view == "QUICK_START" && (
        <Grid item md={12} xs={12} sx={{ mb: 2 }}>
          <Typography variant="h6">Quick Start</Typography>
          <Box sx={{ maxHeight: "20em", overflow: "scroll", mt: 1 }}>
            {filter(quickStart, { status: "pending" }).map((task: any) => (
              <Box
                sx={{
                  py: 2,
                  px: 2,
                  my: 1,
                  borderRadius: 1,
                  backgroundColor: "background.default",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ display: "inline", maxWidth: "calc(100% - 4em)" }}>
                  <Typography variant="body1">{task.title}</Typography>
                  <Divider />
                  <Typography variant="body2" sx={{ pt: 1 }}>
                    {task.description}
                  </Typography>
                </Box>
                <Button
                  sx={{ height: "fit-content" }}
                  variant="contained"
                  size="small"
                  onClick={() => {
                    window.location.href = task.url;
                  }}
                >
                  Open
                </Button>
              </Box>
            ))}

            {filter(quickStart, { status: "completed" }).length ? (
              <Box sx={{ py: 1, px: 1 }}>
                <Typography variant="overline">Already completed</Typography>
                {filter(quickStart, { status: "completed" }).map(
                  (task: any) => (
                    <Box
                      className="hand"
                      sx={{
                        py: 1,
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      onClick={() => {
                        window.location.href = task.url;
                      }}
                    >
                      <Typography>{task.title}</Typography>
                      <CheckCircleOutlined sx={{ color: "success.main" }} />
                    </Box>
                  )
                )}
              </Box>
            ) : null}
          </Box>
        </Grid>
      )}
      {view == "WHATS_NEW" && (
        <Grid item md={12} xs={12}>
          <Typography variant="h6">What's new in Aptori</Typography>
          <Box sx={{ maxHeight: "20em", overflow: "scroll", mt: 1 }}>
            {features &&
              features.map((task: any) => (
                <Box
                  sx={{
                    py: 2,
                    px: 2,
                    my: 1,
                    borderRadius: 1,
                    backgroundColor: "background.default",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ display: "inline", maxWidth: `calc(100% - ${task.url ? 4 : 0}em)` }}>
                    <Typography variant="body1">{task.title}</Typography>
                    <Divider />
                    <Typography variant="body2" sx={{ pt: 1 }}>
                      {task.details}
                    </Typography>
                  </Box>
                  {task.url ? (
                    <Button
                      sx={{ height: "fit-content" }}
                      variant="contained"
                      size="small"
                      onClick={() => {
                        window.location.href = task.url;
                      }}
                    >
                      More
                    </Button>
                  ) : null}
                </Box>
              ))}
          </Box>
        </Grid>
      )}
    </Grid>
  );
};

export default QuickOverview;
