import {
  Box, Container
} from "@mui/material";
import { RouteComponentProps, withRouter } from "react-router-dom";
import './index.css';
import IssueView from "./issueComponents/IssueView";
import ResultView from "./runComponents/ResultView";

const FindingsView: React.FC<RouteComponentProps> = ({
  history,
  match,
  location,
}) => {
  const useQuery = () => new URLSearchParams(location.search);
  const query = useQuery();
  const resultId = query.get("resultId");
  const issueId = query.get("issueId");

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.paper",
          display: "flex",
          flexDirection: "column",
          minHeight: '100%',
        }}
      >
        <Container maxWidth="xl" sx={{ p: 3 }}>

          <Box
            sx={{
              flexGrow: 1,
            }}
          >
            {issueId ? <IssueView id={issueId} /> : null}

            {resultId ? <ResultView resultId={resultId} /> : null}
          </Box>

        </Container>
      </Box>
    </>
  );
};

export default withRouter(FindingsView);
