import { Alert, Box, Button, Grid, TextField, Typography } from "@mui/material";
import { FC, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { API_SLUGS, HELPER_TEXTS, STRINGS } from "../../../constants";
import { axiosInstance } from "../../../services/axios";
import { IAlertState, useAlertState } from "../../../store/alertState";
var format = require("string-template");

interface IError {
  error: boolean;
  message: string;
}

interface IAddEnvironmentFormError {
  environment?: IError;
}

interface IAddEnvironmentFormValues {
  environment: string;
}

interface IEnvironment {
  id: string;
}
interface IAddForm extends RouteComponentProps {
  updateOnly?: boolean;
  defaultFormValues?: any;
  preventRedirect?: boolean;
  updateUUID?: string;
  projectId?: string;
  callback?: (data: any) => void;
}

const AddEnvironmentForm: FC<IAddForm> = ({
  history,
  match,
  preventRedirect = false,
  callback = undefined,
  updateOnly = false,
  defaultFormValues,
  projectId,
  updateUUID,
}) => {
  const [formValues, setFormValues] = useState({
    environment: "",
    ...defaultFormValues,
  } as IAddEnvironmentFormValues);
  const [formErrorValues, setFormErrorValues] = useState({
    environment: {},
  } as IAddEnvironmentFormError);
  const { setMessage } = useAlertState((state) => state) as IAlertState;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState(STRINGS.EMPTY);
  const [successMessage, setSuccessMessage] = useState(STRINGS.EMPTY);
  const [allowEdit, setAllowEdit] = useState(false);



  const handleChange = async (value: any, type: "environment") => {
    let newValues = { ...formValues, [type]: value };

    setFormValues(newValues);
  };
  const handleSubmit = async () => {
    if (updateOnly) {
      if (formValues?.environment.trim() != "") {
        await updateEnvironment(formValues?.environment.trim());
      } else {
        setErrorMessage("Please fill all the mandatory fields");
      }
    } else {
      if (formValues?.environment.trim() != "") {
        await addEnvironment(formValues?.environment.trim());
      } else {
        setErrorMessage("Please fill all the mandatory fields");
      }
    }
  };

  const addEnvironment = async (name: string) => {
    try {
      let environmentResponse = await axiosInstance.post(
        format(API_SLUGS.ADD_ENVIRONMENT, { projectId: projectId }),
        { name: name }
      );
      if (!preventRedirect) {
        history.push("/environments");
      }
      if (callback) {
        callback(environmentResponse.data);
      }
      setMessage({ title: "Environment has been added", type: "success" });
    } catch (error: any) {
      try {
        setErrorMessage(error.response?.data?.message);
      } catch (error: any) { }
    }
  };

  const updateEnvironment = async (name: string) => {
    try {
      await axiosInstance.patch(
        format(API_SLUGS.UPDATE_ENVIRONMENT, {
          projectId: projectId,
          id: updateUUID,
        }),
        { name: name }
      );
      if (!preventRedirect) {
        history.push("/environments");
      }
      if (callback) {
        callback({ id: updateUUID, name: name });
      }
      setMessage({ title: "Environment has been updated", type: "success" });
    } catch (error: any) {
      try {
        setErrorMessage(error.response?.data?.message);
      } catch (error: any) { }
    }
  };

  return (
    <Box sx={{ textAlign: "left" }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            color="textPrimary"
            sx={{ mb: 1, textAlign: "left" }}
            variant="h6"
          >
            Environment
          </Typography>
          <TextField
            autoComplete="off"
            sx={{ maxWidth: "50%", textAlign: "left" }}
            size="medium"
            fullWidth
            name="Environment"
            helperText={HELPER_TEXTS?.add_environment?.environment}
            defaultValue={formValues?.environment}
            onChange={(e: any) => {
              handleChange(e.target.value, "environment");
            }}
            required
            variant="outlined"
          />
        </Grid>
      </Grid>
      {successMessage && successMessage.trim() !== STRINGS.EMPTY ? (
        <Box sx={{ mt: 2 }}>
          <Alert severity="success" sx={{ width: "fit-content" }}>
            <div>{successMessage}</div>
          </Alert>
        </Box>
      ) : errorMessage && errorMessage.trim() !== STRINGS.EMPTY ? (
        <Box sx={{ mt: 2 }}>
          <Alert severity="error" sx={{ width: "fit-content" }}>
            <div>{errorMessage}</div>
          </Alert>
        </Box>
      ) : null}

      <Box
        sx={{
          display: "flex",
          mt: 3,
        }}
      >
        {successMessage && successMessage.trim() ? null : (
          <Button
            color="primary"
            size="medium"
            variant="contained"
            disabled={isSubmitting}
            onClick={handleSubmit}
          >
            Save
          </Button>
        )}
        {updateOnly ? (
          <Button
            color="primary"
            size="large"
            variant="outlined"
            sx={{ ml: 1 }}
            onClick={() => {
              if (callback) {
                callback({});
              }
              // history.push("/environments");
            }}
          >
            Cancel
          </Button>
        ) : null}
      </Box>
    </Box>
  );
};

export default withRouter(AddEnvironmentForm);
