import {
    Box,
    Button,
    Card,
} from '@mui/material';
import { FC, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { getConfigs } from '../../../configs';
import { API_SLUGS } from '../../../constants';
import { Plus } from '../../../icons/plus';
import { axiosInstance } from '../../../services/axios';
import ModalWrapper from '../../common/modal/wrapper';
import { DataCard } from '../../dashboard';
import AddPlatformKeyForm from '../AddPlatformKeys/AddPlatformKeyForm';
var format = require("string-template")

const ResultsTable: FC<RouteComponentProps> = ({ history, match }) => {
    const [filter, setFilter] = useState({ "Search On": "", "Search String": "" } as any)
    const revokePlatformKey = async (id: string) => {
        console.log({ id })
        try {
            await axiosInstance.delete(format(API_SLUGS.REVOKE_PLATFORM_KEY, { id: id }))
            window.location.reload()
        } catch (error: any) {

        }

    };

    return (<Card elevation={0} sx={{ textAlign: "left" }}>
        <Box sx={{ ml: 1 }}>
            <ModalWrapper
                maxWidth="md"
                title="Add Group"
                trigger={<Button variant="contained" sx={{ mb: 2 }}>Add <Plus /></Button>}
                child={<AddPlatformKeyForm />}
            />

        </Box>
        <DataCard name={"Projects"} config={{
            "widget": "table",
            "width": 8,
            "data_source": `${getConfigs().baseApiUrl}/user/accesstokens`,
            "data_points": [
                { "key": "id", "jsonpath": "$.accessTokens[*].id" },
                { "key": "name", "jsonpath": "$.accessTokens[*].name" },
                { "key": "expiry", "jsonpath": "$.accessTokens[*].expiresAt" },
            ],
            "pagination": true,
            "pagination_limit": 20,
            "columns": [
                {
                    key: "id", name: "Id", type: "hidden"
                },
                {
                    key: "name", name: "Name", type: "string"
                },
                {
                    key: "expiry", name: "Expires At", type: "dateTime"
                },
                {
                    key: "revoke", name: "Revoke", type: "custom", code: (row: any) => {
                        return <Button onClick={() => revokePlatformKey(row?.id)}>Revoke</Button>
                    }
                }

            ],
        }} />

    </Card >
    )
};

export default withRouter(ResultsTable);
