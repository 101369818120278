import {
  AccountTreeOutlined,
  AddCircle, Clear,
  ExpandMore, RemoveCircle, TableChartOutlined
} from "@mui/icons-material";
import {
  Accordion, AccordionDetails, AccordionSummary, Alert, Autocomplete, Box, Checkbox, Chip,
  Grid, Switch, TextField, Tooltip, Typography
} from "@mui/material";
import {
  filter, find, findIndex, groupBy, isEmpty, map, orderBy, startCase
} from "lodash";
import { FC, useEffect, useMemo, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import { getConfigs } from "../../../../configs";
import { axiosInstance } from "../../../../services/axios";
import Loaders from "../../../dashboard/Loaders";
import ActionButton from "../../ViewFindings/shared/button";
import RepoIssuesTable from "../../ViewIssues/RepoIssuesTable";
import IssuePreviewPopup from "../issueComponents/issuePreviewPopup";


const APIComponent: FC<any> = ({ type, api, setFilters }) => {
  return (
    <>
      <Box
        sx={{ ml: 2, backgroundColor: "background.default", my: 0.5, p: 0.5 }}
      >
        <Checkbox
          key={api.label}
          onChange={({ target }) => {
            setFilters(api.key, target.checked);
          }}
        />
        <Typography variant="body2" sx={{ display: "inline" }}>
          {api.label}
        </Typography>
      </Box>
    </>
  );
};

const ResourceComponent: FC<any> = ({ name, resource, setFilters }) => {
  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Typography
          variant="h6"
          sx={{ backgroundColor: "background.default", my: 0.5, p: 0.5 }}
        >
          {name}
        </Typography>
        {resource?.map((api: any) => (
          <APIComponent api={api} setFilters={setFilters} />
        ))}
      </Box>
    </>
  );
};

const APIData: FC<any> = ({ perAnalyzerRuleTables, operationID }) => {
  return (
    <Box sx={{ py: 2 }}>
      {perAnalyzerRuleTables?.map((tabData: any) => (
        <>
          <Box sx={{ py: 2 }}>
            <Typography
              variant="h6"
              sx={{ width: "33%", flexShrink: 0, display: "inline" }}
            >
              {tabData.tab}
            </Typography>
          </Box>

          <Grid container>
            {tabData.data?.map((row: any) => (
              <Grid xs={6} item sx={{ mb: 2 }}>
                <Box>
                  <Tooltip title={row.decision}>
                    <Chip
                      size="small"
                      className="hand"
                      sx={{ fontSize: "12px" }}
                      label={row.analyzerName}
                      style={{
                        backgroundColor:
                          row.decision?.toLowerCase() === "pass"
                            ? "#10B981"
                            : row.decision?.toLowerCase() === "none"
                              ? "#e46b48"
                              : row.decision?.toLowerCase() === "skip"
                                ? "rgb(117,114,128)"
                                : "#D14343 ",
                        color: "#fff",
                      }}
                    />
                  </Tooltip>
                </Box>
              </Grid>
            ))}
          </Grid>
        </>
      ))}
    </Box>
  );
};

const FaultCard: FC<any> = ({ isComparing, fault }) => {
  return !isComparing || (isComparing && fault?.diff) ? (
    <Box
      sx={{
        pl: 2,
        pt: 1,
        mb: 1,
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box sx={{ display: "inline" }}>
        <Box sx={{ display: "inline", pr: 1 }}>
          {fault?.diff ? (
            fault?.diff === "POSITIVE" ? (
              <AddCircle
                color="success"
                sx={{ position: "relative", top: 8 }}
              />
            ) : (
              <RemoveCircle
                color="error"
                sx={{ position: "relative", top: 8 }}
              />
            )
          ) : null}
        </Box>
        <Chip label={fault?.faultId} />
        <Typography variant="body1" sx={{ display: "inline", pl: 2 }}>
          {fault?.name}
        </Typography>

        {fault?.isNew ? (
          <Chip size="small" color="success" label="New" sx={{ ml: 1 }}></Chip>
        ) : null}
      </Box>
      <Box sx={{ display: "flex" }}>
        <Tooltip title="Risk">
          <ActionButton
            issueId={fault?.issueId}
            buttons="RISK"
            risk={fault?.risk}
            state={fault?.state}
            user={fault?.assigneeId}
          />
        </Tooltip>
        <IssuePreviewPopup finding={fault} />

      </Box>
    </Box>
  ) : null;
};

const FaultsList: FC<any> = ({ isComparing, faults, newFaultsOnly }) => {
  return (
    <Box
      sx={{ backgroundColor: "background.default", borderRadius: "5px", p: 2 }}
    >
      {faults?.map(
        (fault: any) =>
          (!newFaultsOnly || fault.isNew) && (
            <FaultCard fault={fault} isComparing={isComparing} />
          )
      )}
    </Box>
  );
};

const SelectedAPIComponent: FC<any> = ({
  result,
  selectedAPIData,
  comparisonFaults,
  newFaultsOnly,
  perAnalyzerRuleTables,
  countsPerOperation,
  faultsSummaryByOperation,
  isExpanded,
  expand,
}) => {
  const [faults, setFaults] = useState<any[] | null>(null);
  const [faultsToDisplay, setFaultsToDisplay] = useState<any[] | null>(null);
  const [isComparing, setIsComparing] = useState(false);
  const [subAccordionExpanded, setSubAccordionExpanded] = useState<
    string | false
  >("faults");
  const [requestsCounts, setRequestsCounts] = useState<number[] | null>(null);

  useEffect(() => {
    if (
      (isExpanded || isComparing) &&
      isEmpty(faults) &&
      selectedAPIData.operationID?.trim() !== ""
    ) {
      fetchFaults();
    }
  }, [isExpanded, isComparing]);

  useEffect(() => {
    setIsComparing(!isEmpty(comparisonFaults));
  }, [comparisonFaults]);

  useEffect(() => {
    if (isEmpty(faults)) return;

    if (!isComparing) {
      setFaultsToDisplay(faults);
    } else {
      let newFaults = [];
      for (const fault of faults!) {
        if (
          findIndex(comparisonFaults, {
            operationId: fault.operationId,
            faultId: fault.faultId,
          }) > -1
        ) {
          newFaults.push(fault);
        } else if (
          findIndex(comparisonFaults, {
            operationId: fault.operationId,
          }) > -1 &&
          findIndex(comparisonFaults, {
            faultId: fault.faultId,
          }) < 0
        ) {
          newFaults.push({ ...fault, diff: "POSITIVE" });
        }
      }

      if (comparisonFaults) {
        for (const fault of comparisonFaults!) {
          if (
            findIndex(faults, {
              operationId: fault.operationId,
            }) > -1 &&
            findIndex(faults, {
              faultId: fault.faultId,
            }) < 0
          ) {
            newFaults.push({ ...fault, diff: "NEGATIVE" });
          }
        }
      }
      setFaultsToDisplay(newFaults);
    }
  }, [isComparing, faults, comparisonFaults]);

  const handleSubAccordionChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setSubAccordionExpanded(isExpanded ? panel : false);
    };

  useEffect(() => {
    try {
      const row = find(countsPerOperation, {
        operationId: selectedAPIData.operationID,
      });
      setRequestsCounts([row?.requestsSuccessful, row?.requestsFailed]);
    } catch (error) { }
  }, [selectedAPIData, countsPerOperation]);

  const fetchFaults = async () => {
    const url = `/findings?resultId=${result.id
      }&operationId=${encodeURIComponent(selectedAPIData.operationID)}`;

    try {
      const faultsResponse = await axiosInstance.get(url);

      setFaults(
        filter(faultsResponse?.data?.findings, {
          operationId: selectedAPIData.operationID,
        })
      );
    } catch (error) { }
  };

  if (
    isComparing &&
    filter(faultsToDisplay, (o) => (o.diff ? true : false)).length === 0
  ) {
    return null;
  }
  return (
    <Accordion
      expanded={isExpanded || isComparing}
      onChange={expand(selectedAPIData.operationID)}
      elevation={12}
      sx={{
        mb: 2,

        borderLeft: "8px solid red",
        borderColor: isComparing
          ? "background.paper"
          : faultsSummaryByOperation?.[selectedAPIData.operationID]?.high
            ? "error.main"
            : faultsSummaryByOperation?.[selectedAPIData.operationID]?.medium
              ? "warning.main"
              : faultsSummaryByOperation?.[selectedAPIData.operationID]?.low
                ? "info.main"
                : "success.main",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        sx={{ py: 3 }}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box>

            <Box sx={{ display: "inline", width: "2em", mr: 4 }}></Box>


            <Chip
              label={
                <Typography variant="overline">
                  {selectedAPIData.method}
                </Typography>
              }
              size="small"
              variant="outlined"
              color="success"
              sx={{ display: "inline", mr: 1, p: 0.25 }}
            />

            <Typography
              variant="h6"
              sx={{
                display: "inline",
              }}
            >
              {selectedAPIData.path}
            </Typography>

            <Typography variant="body2" sx={{ display: "inline", pl: 1 }}>
              {selectedAPIData.operationID}
            </Typography>
          </Box>

          {!isComparing && (
            <Box sx={{ pr: 2 }}>
              {faultsSummaryByOperation?.[selectedAPIData.operationID]?.high >
                0 && (
                  <Chip
                    label={
                      faultsSummaryByOperation?.[selectedAPIData.operationID]
                        ?.high
                    }
                    size="small"
                    color="error"
                    sx={{ display: "inline", mr: 1, p: 0.25 }}
                  />
                )}
              {faultsSummaryByOperation?.[selectedAPIData.operationID]?.medium >
                0 && (
                  <Chip
                    label={
                      faultsSummaryByOperation?.[selectedAPIData.operationID]
                        ?.medium
                    }
                    size="small"
                    color="warning"
                    sx={{ display: "inline", mr: 1, p: 0.25 }}
                  />
                )}
              {faultsSummaryByOperation?.[selectedAPIData.operationID]?.low >
                0 && (
                  <Chip
                    label={
                      faultsSummaryByOperation?.[selectedAPIData.operationID]?.low
                    }
                    size="small"
                    color="info"
                    sx={{ display: "inline", mr: 1, p: 0.25 }}
                  />
                )}
            </Box>
          )}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {!isEmpty(faultsToDisplay) && <Accordion
          expanded={subAccordionExpanded === "faults"}
          onChange={handleSubAccordionChange("faults")}
          elevation={12}
        >
          <AccordionSummary expandIcon={<ExpandMore />} sx={{ py: 3 }}>
            <Typography variant="h6">Faults</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {subAccordionExpanded === "faults" && (
              <FaultsList
                isComparing={isComparing}
                faults={faultsToDisplay}
                newFaultsOnly={newFaultsOnly}
              />
            )}
          </AccordionDetails>
        </Accordion>}

      </AccordionDetails>
    </Accordion>
  );
};

const ResourceSection: FC<any> = ({
  operations,
  newFaultsOnly,
  comparisonFaults,
  result,
  filters,
  perAnalyzerRuleTables,
  countsPerOperation,
  faultsSummaryByOperation,
}) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const isFilterValid = (operationSummary: any, filters: any[]) => {
    if (
      filters.length === 0 ||
      (filter(filters[0].options, { selected: false }).length === 0 &&
        filter(filters[1].options, { selected: false }).length === 0)
    ) {
      return true;
    }
    let riskFilterCheck = false;
    let categoryFilterCheck = false;
    let riskFilterSelectedOptions = filter(filters[0].options, {
      selected: true,
    });
    riskFilterCheck = riskFilterCheck || riskFilterSelectedOptions.length === 0;
    for (let option of riskFilterSelectedOptions) {
      if (operationSummary?.[option.key] > 0) {
        riskFilterCheck = true;
        break;
      }
    }
    let categoryFilterSelectedOptions = filter(filters[1].options, {
      selected: true,
    });

    categoryFilterCheck =
      categoryFilterCheck || categoryFilterSelectedOptions.length === 0;

    for (let option of categoryFilterSelectedOptions) {
      if (operationSummary?.categories?.indexOf(option.key) > -1) {
        categoryFilterCheck = true;
        break;
      }
    }

    return riskFilterCheck && categoryFilterCheck;
  };
  return (
    <Box sx={{ m: 2 }}>
      <Typography variant="h5">
        {operations?.[0]?.settingsToUse?.resource}
      </Typography>
      {operations?.map(
        (operation: any, index: number) =>
          isFilterValid(
            faultsSummaryByOperation?.[operation?.operationID],
            filters
          ) &&
          (!newFaultsOnly || operation?.isNew) && (
            <SelectedAPIComponent
              result={result}
              comparisonFaults={comparisonFaults}
              newFaultsOnly={newFaultsOnly}
              selectedAPIData={operation}
              faultsSummaryByOperation={faultsSummaryByOperation}
              isExpanded={operation.operationID === expanded}
              expand={handleChange}
              perAnalyzerRuleTables={perAnalyzerRuleTables}
              countsPerOperation={countsPerOperation}
            />
          )
      )}
    </Box>
  );
};

const ResultNewView: FC<any> = ({ operations, result, api }) => {

  const [operationsByResource, setOperationsByResource] = useState<any>({});
  const [faults, setFaults] = useState<any[] | null>(null);
  const [comparingResultId, setComparingResultId] = useState<string | null>(
    null
  );
  const [comparisonFaults, setComparisonFaults] = useState<any[] | null>(null);
  const [isThereDifference, setIsThereDifference] = useState(false);
  const [riskFilters, setRiskFilters] = useState<any[]>([]);
  const [categoryFilters, setCategoryFilters] = useState<any>([]);
  const [newFaultsOnly, setNewFaultsOnly] = useState(false);
  const [compareResultOptions, setcompareResultOptions] = useState<
    any[] | null
  >(null);
  const [faultsView, setFaultsView] = useState<"TABLE" | "RESOURCE">(api?.assetType === "api" ? "RESOURCE" : "TABLE");


  const [fetchingComparisonFaults, setFetchingComparisonFaults] =
    useState(false);
  const [faultsSummaryByOperation, setFaultsSummaryByOperation] =
    useState<any>(null);
  useEffect(() => {
    if (!isEmpty(result)) {
      fetchFaults();
      fetchResults();
    }
  }, [result]);

  useEffect(() => {
    if (comparingResultId) {
      fetchComparisonFaults();
    }
  }, [comparingResultId]);

  const fetchResults = async () => {
    try {
      const response = await axiosInstance.get(
        `${getConfigs().baseApiUrl}/results?assetId=${result.assetId
        }&environmentId=${result.environmentId}&checkpoint=true`
      );
      setcompareResultOptions(response?.data?.results);
    } catch (error) {
      console.log({ error });
    }
  };
  const fetchFaults = async () => {
    try {
      const faultsResponse = await axiosInstance.get(
        `${getConfigs().baseApiUrl}/findings?resultId=${result.id}`
      );

      setFaults(faultsResponse?.data?.findings);
    } catch (error) { }
  };

  const fetchComparisonFaults = async () => {
    setFetchingComparisonFaults(true);
    try {
      const faultsResponse = await axiosInstance.get(
        `${getConfigs().baseApiUrl}/findings?resultId=${comparingResultId}`
      );

      setComparisonFaults(faultsResponse?.data?.findings);
      // check if there are any change in the faults
      let newFaults = [];
      for (const fault of faults!) {
        if (
          findIndex(faultsResponse?.data?.findings, {
            operationId: fault.operationId,
            faultId: fault.faultId,
          }) < 0
        ) {
          newFaults.push({ ...fault, diff: "POSITIVE" });
        }
      }

      for (const fault of faultsResponse?.data?.findings) {
        if (
          findIndex(faults, {
            operationId: fault.operationId,
            faultId: fault.faultId,
          }) < 0
        ) {
          newFaults.push({ ...fault, diff: "NEGATIVE" });
        }
      }

      if (newFaults.length > 0) {
        setIsThereDifference(true)
      } else {
        setIsThereDifference(false)
      }


    } catch (error) { }
    setFetchingComparisonFaults(false);
  };

  useEffect(() => {
    generateRiskFilters();
  }, [faults, newFaultsOnly]);

  useEffect(() => {
    generateCategoryFilters();
  }, [riskFilters, faults]);

  const generateRiskFilters = () => {
    if (isEmpty(faults)) return;
    try {
      let filteredFaults = [...faults!];
      if (newFaultsOnly) {
        filteredFaults = filter(filteredFaults, { isNew: true });
      }
      let riskCountMap: any = {
        high: 0,
        medium: 0,
        low: 0,
      };
      let groupedFaults: any = groupBy(filteredFaults, "operationId");
      let newFaultsSummaryByOperation: any = {};
      for (let operationId in groupedFaults) {
        const faultsGroupedBySeverity: any = groupBy(
          groupedFaults[operationId],
          "risk"
        );
        newFaultsSummaryByOperation[operationId] = {
          total: groupedFaults[operationId].length,
          categories: [],
        };

        for (let risk in riskCountMap) {
          let riskCount = faultsGroupedBySeverity?.[risk]?.length;
          newFaultsSummaryByOperation[operationId][risk] = riskCount || 0;
          riskCountMap[risk] += riskCount || 0;
        }
      }

      let riskFilters: any = [];
      for (let risk in riskCountMap) {
        riskFilters.push({
          key: risk,
          label: `${startCase(risk)} (${riskCountMap[risk]})`,
          count: riskCountMap[risk],
          selected: false,
        });
      }

      setRiskFilters(riskFilters);
      setFaultsSummaryByOperation(newFaultsSummaryByOperation);
    } catch (error) { }
  };

  useEffect(() => {
    evaluateOperations();
  }, [faults, operations]);

  const evaluateOperations = () => {


    if (!operations) return;

    let newOperations = [...operations];
    if (!isEmpty(faults)) {
      for (let fault of faults!) {
        let operationIndex = findIndex(newOperations, {
          operationID: fault.operationId,
        });
        if (
          newOperations[operationIndex] &&
          !newOperations[operationIndex]["isNew"]
        ) {
          newOperations[operationIndex]["isNew"] = fault.isNew;
        }
      }
    }


    setOperationsByResource(
      groupBy(
        orderBy(
          newOperations,
          (operation) => operation?.settingsToUse?.resource
        ),
        (operation) => operation?.settingsToUse?.resource
      )
    );
  };



  const generateCategoryFilters = () => {
    if (isEmpty(faults) || isEmpty(riskFilters)) return;

    try {
      let filteredFaults = [...faults!];
      for (let riskFilter of riskFilters) {
        if (riskFilter.selected) {
          filteredFaults = filter(filteredFaults, { risk: riskFilter.key });
        }
      }

      if (newFaultsOnly) {
        filteredFaults = filter(filteredFaults, { isNew: true });
      }
      let categories = map(filteredFaults, "categoryName");
      let categoryCountMap: any = {};
      for (let category of categories) {
        categoryCountMap[category] = 0;
      }

      let groupedFaults: any = groupBy(filteredFaults, "operationId");
      let newFaultsSummaryByOperation: any = { ...faultsSummaryByOperation };
      for (let operationId in groupedFaults) {
        newFaultsSummaryByOperation[operationId].categories = [];
        for (let fault of groupedFaults[operationId]) {
          newFaultsSummaryByOperation[operationId]?.categories?.push(
            fault?.categoryName
          );
          categoryCountMap[fault?.categoryName]++;
        }
      }

      let categoryFilters: any = [];
      for (let category in categoryCountMap) {
        categoryFilters.push({
          key: category,
          label: `${category} (${categoryCountMap[category]})`,
          count: categoryCountMap[category],
          selected: false,
        });
      }

      categoryFilters = orderBy(categoryFilters, "count", "desc");

      setCategoryFilters(categoryFilters);
      setFaultsSummaryByOperation(newFaultsSummaryByOperation);
    } catch (error) { }
  };

  const perAnalyzerRuleTables = useMemo(() => {
    if (!result) {
      return {};
    }
    let operations = groupBy(result?.metrics?.perOperation, "operationId");
    for (let operation in operations) {
      let operationObject = operations[operation]?.[0];
      let operationData = [
        {
          tab: "Analyzers",
          data: operationObject?.perAnalyzer?.map((o: any) => ({
            decision: o.decision,
            analyzerName:
              find(result.faultAnalyzers, { name: o.analyzerName })
                ?.displayName || o.analyzerName,
          })),
        },
      ];
      let groupedAnalyzerRule = groupBy(
        operationObject?.perAnalyzerRule,
        "analyzerName"
      );
      for (let rule in groupedAnalyzerRule) {
        operationData.push({
          tab: rule,
          data: groupedAnalyzerRule[rule]?.map((o) => ({
            decision: o.decision,
            analyzerName: o.ruleName,
          })),
        });
      }
      operations[operation] = operationData;
    }

    return operations;
  }, [result]);

  if (fetchingComparisonFaults) {
    return <Loaders />;
  }
  return (
    <Grid container xs={12} sx={{ p: 2 }} columnSpacing={3}>


      <Grid item xs={12}
        sx={{ dipslay: "flex", justifyContent: "space-between", width: "100%" }}
      >
        {api?.assetType === "api" && <Box sx={{ display: "inline-block", mr: 1 }}>
          <Typography variant="h6" sx={{ dipslay: "inline" }}>
            {faultsView === "TABLE" ? "Resource" : "Table"} View
          </Typography>
          {faultsView === "TABLE" ? (
            <AccountTreeOutlined
              color="primary"
              sx={{

                cursor: "pointer",

              }}
              onClick={() => {
                setFaultsView("RESOURCE");
              }}
            />
          )
            : <TableChartOutlined
              color="primary"
              sx={{

                cursor: "pointer",

              }}
              onClick={() => {
                setFaultsView("TABLE");
              }
              }
            />}
        </Box>}
        <Box sx={{ display: "inline-block" }}>
          {isEmpty(comparisonFaults) && faultsView === "RESOURCE" && (

            <Box sx={{ dipslay: "flex", justifyContent: "start", mr: 5 }}>
              <Box sx={{ display: "inline" }}>
                <Typography variant="h6" sx={{ dipslay: "inline" }}>New Faults</Typography>
                <Switch
                  defaultChecked={false}
                  sx={{ dipslay: "inline" }}
                  onChange={({ target }) => {
                    setNewFaultsOnly(target.checked);
                  }}
                />
              </Box>
            </Box>
          )}
        </Box>

      </Grid>
      {!comparingResultId && faultsView === "RESOURCE" && (
        <Grid item xs={3}>
          <Box sx={{ position: "sticky" }}>
            <ResourceComponent
              name="Risk"
              resource={riskFilters}
              setFilters={(key: string, checked: boolean) => {
                let newFilters: any = [...riskFilters];
                for (let index in newFilters) {
                  if (newFilters[index]?.key === key) {
                    newFilters[index].selected = checked;
                  }
                }

                setRiskFilters(newFilters);
              }}
            />
            <ResourceComponent
              name="Category"
              resource={categoryFilters}
              setFilters={(key: string, checked: boolean) => {
                let newFilters: any = [...categoryFilters];
                for (let index in newFilters) {
                  if (newFilters[index]?.key === key) {
                    newFilters[index].selected = checked;
                  }
                }

                setCategoryFilters(newFilters);
              }}
            />
          </Box>
        </Grid>
      )}
      <Grid item xs={comparingResultId || faultsView === "TABLE" ? 12 : 9}>
        {!isEmpty(compareResultOptions) && !comparingResultId && (
          <Box sx={{ display: "inline", textAlign: "right" }}>
            <Typography variant="h6" sx={{ textAlign: "left", mb: 0.5 }}>
              Compare with another run {`(Checkpoints only)`}
            </Typography>
            <Autocomplete
              sx={{ width: "fit-content", minWidth: "15em" }}
              getOptionLabel={(option: any) => option.name}
              options={compareResultOptions!}
              onChange={(e, value) => {
                if (value) {
                  setComparingResultId(value.id);
                } else {
                  setComparingResultId(null);
                  setComparisonFaults([]);
                }
              }}
              size="small"
              renderInput={(params): JSX.Element => {
                return (
                  <TextField
                    sx={{ textAlign: "left", m: 0 }}
                    placeholder="Choose a checkpoint"
                    name="analyzers"
                    helperText="Name a run to turn it into a checkpoint"
                    variant="outlined"
                    {...params}
                  />
                );
              }}
            />
          </Box>
        )}

        {!isEmpty(compareResultOptions) && comparingResultId && (
          <Box sx={{ display: "inline", textAlign: "right" }}>
            <Typography variant="h6" sx={{ textAlign: "left" }}>
              <Clear
                className="hand"
                sx={{ pt: 1 }}
                onClick={() => {
                  setComparingResultId(null);
                  setComparisonFaults([]);
                }}
              />
              Comparing with &nbsp;
              <Chip
                label={`${find(compareResultOptions, { id: comparingResultId })?.name
                  }`}
              ></Chip>

              {!isThereDifference && (
                <Alert severity="info" sx={{ mt: 2, ml: 2 }}>
                  No differences were found between the two versions of the analysis runs.
                </Alert>
              )}

            </Typography>
          </Box>
        )}

        {faultsView === "TABLE" ?
          <Grid item xs={12}>
            <RepoIssuesTable runId={result?.id} comparisonRunId={comparingResultId} defaultApiId={api?.id} disableGlobalFilter
              columnVisibility={{
                operationId: api?.assetType === "api",
              }}
              filters={{
                "category": categoryFilters
              }} />
          </Grid>
          : <Virtuoso
            style={{ minHeight: "90vh", height: "auto" }}
            totalCount={Object.keys(operationsByResource).length}
            itemContent={(index: any) => {
              return (
                <>
                  <ResourceSection
                    key={index}
                    result={result}
                    newFaultsOnly={newFaultsOnly}
                    filters={[
                      { name: "Risk", options: riskFilters },
                      { name: "Category", options: categoryFilters },
                    ]}
                    comparisonFaults={comparisonFaults}
                    faultsSummaryByOperation={faultsSummaryByOperation}
                    operations={
                      operationsByResource[
                      Object.keys(operationsByResource)[index]
                      ]
                    }
                    perAnalyzerRuleTables={perAnalyzerRuleTables}
                    countsPerOperation={result?.metrics?.perOperation}
                  />
                </>
              );
            }}
          />}
      </Grid>

    </Grid>
  );
};

export default ResultNewView;
