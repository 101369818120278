import {
  Box, Card,
  CardContent, Chip, Container,
  Grid, Typography
} from "@mui/material";
import { useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getConfigs } from "../../../configs";
import OrganizationSelector from "../../common/appHeader/OrganizationSelector";
import GitlabIntegrationForm from "../integrations/AddIntegrations/GitlabIntegrationForm";
import JiraIntegrationForm from "../integrations/AddIntegrations/JiraIntegrationForm";
import Webhook from "../integrations/AddIntegrations/Webhook";
import { InviteMembers } from "../userProfile/inviteMembers";

const OrganizationSettings: React.FC<RouteComponentProps> = ({ history }) => {
  const [jiraStatus, setJiraStatus] = useState(false);
  const [gitlabStatus, setGitlabStatus] = useState(false);
  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          display: "flex",
          flexDirection: "column",
          minHeight: '100%',
        }}
      >
        <Container maxWidth="xl" sx={{ p: 3 }}>
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 3,
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <Grid sx={{ textAlign: "left" }} container>
                  <Grid md={3}>
                    <div>
                      <Typography color="textPrimary" gutterBottom variant="h5">
                        Current Organization
                      </Typography>
                    </div>
                  </Grid>

                  <Grid md={9} spacing={3}>
                    <Card sx={{ border: "none", boxShadow: "none" }}>
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          p: 3,
                        }}
                      >
                        <Box
                          sx={{
                            flexGrow: 1,
                          }}
                        >
                          <OrganizationSelector />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
          <Card sx={{ border: "none", boxShadow: "none" }}>
            <CardContent
              sx={{
                display: "flex",
                flexDirection: "column",
                p: 3,
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <Grid sx={{ textAlign: "left" }} container>
                  <Grid md={3}>
                    <div>
                      <Typography color="textPrimary" gutterBottom variant="h5">
                        Members
                      </Typography>
                    </div>
                  </Grid>

                  <Grid md={9} spacing={3}>
                    <Card sx={{ border: "none", boxShadow: "none" }}>
                      <CardContent
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          p: 3,
                        }}
                      >
                        <Box
                          sx={{
                            flexGrow: 1,
                          }}
                        >
                          <InviteMembers />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>
          <Card sx={{ border: 'none', boxShadow: 'none' }}>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 3
              }}
            >
              <Box
                sx={{
                  flexGrow: 1,
                }}
              >
                <Grid sx={{ textAlign: "left" }} container>
                  <Grid
                    md={3}
                  >

                    <div>
                      <Typography
                        color="textPrimary"
                        gutterBottom
                        variant="h5"
                      >
                        Jira <Chip sx={{ ml: 2 }} label={jiraStatus ? "Active" : "Inactive"} color={jiraStatus ? "success" : "error"} />
                      </Typography>
                    </div>


                  </Grid>

                  <Grid md={9} spacing={3}>
                    <Card sx={{ border: 'none', boxShadow: 'none' }}>
                      <CardContent
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          p: 3
                        }}
                      >

                        <Box
                          sx={{
                            flexGrow: 1,
                          }}
                        >
                          <JiraIntegrationForm setJiraStatus={setJiraStatus} />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid
                    md={3}
                  >

                    <div>
                      <Typography
                        color="textPrimary"
                        gutterBottom
                        variant="h5"
                      >
                        Gitlab <Chip sx={{ ml: 2 }} label={gitlabStatus ? "Active" : "Inactive"} color={gitlabStatus ? "success" : "error"} />
                      </Typography>
                    </div>


                  </Grid>
                  <Grid md={9} spacing={3}>
                    <Card sx={{ border: 'none', boxShadow: 'none' }}>
                      <CardContent
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          p: 3
                        }}
                      >

                        <Box
                          sx={{
                            flexGrow: 1,
                          }}
                        >
                          <GitlabIntegrationForm setGitlabStatus={setGitlabStatus} />
                        </Box>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
          </Card>

          {getConfigs().experimentalFeatures && (
            <Card sx={{ border: 'none', boxShadow: 'none', mt: 3 }}>
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  p: 3
                }}
              >
                <Box
                  sx={{
                    flexGrow: 1,
                  }}
                >
                  <Grid sx={{ textAlign: "left" }} container>
                    <Grid
                      md={3}
                    >

                      <div>
                        <Typography
                          color="textPrimary"
                          gutterBottom
                          variant="h5"
                        >
                          Webhook
                        </Typography>
                      </div>


                    </Grid>

                    <Grid md={9} spacing={3}>
                      <Card sx={{ border: 'none', boxShadow: 'none' }}>
                        <CardContent
                          sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            p: 3
                          }}
                        >

                          <Box
                            sx={{
                              flexGrow: 1,
                            }}
                          >
                            <Webhook />
                          </Box>
                        </CardContent>
                      </Card>
                    </Grid>
                  </Grid>
                </Box>
              </CardContent>
            </Card>
          )}

        </Container>
      </Box>
    </>
  );
};

export default withRouter(OrganizationSettings);
