import { Download } from "@mui/icons-material";
import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import { Box } from "@mui/system";
import { isEmpty } from "lodash";
import { FC, useEffect, useMemo, useState } from "react";
import ReactMarkdown from "react-markdown";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getConfigs } from "../../configs";
import { axiosInstance } from "../../services/axios";
import { IAlertState, useAlertState } from "../../store/alertState";
import { base64ToString } from "../../utils/base64ToString";

const isOmnibus = true; // download sift from /public folder

const basename = (name: string) => name.replace(/^.*[\\\/]/, "");
const apiUrl = isOmnibus
  ? [window.location.protocol, window.location.host].join("//")
  : getConfigs().baseApiUrl;
const getDataSource = (release: string, apiUrl: string) =>
  isOmnibus
    ? `${apiUrl}/sift/downloadsIndex.json`
    : `${apiUrl}/downloads/list/${release}`;

const DownloadList: FC<RouteComponentProps> = () => {
  const [downloadedFile, setDownloadedFile] = useState<any>(null);
  const [release, setRelease] = useState("");
  const [files, setFiles] = useState<any>({});
  //check if navigator is windows, mac or linux
  const userAgent = useMemo(() => {
    if (navigator?.userAgent?.toLowerCase().includes("win")) {
      return "windows";
    } else if (navigator?.userAgent?.toLowerCase().includes("mac")) {
      // check intel
      if (navigator?.userAgent?.toLowerCase().includes("intel")) {
        return "macos_amd64";
      } else {
        return "macos_arm64";
      }
    } else if (navigator?.userAgent?.toLowerCase().includes("linux")) {
      return "linux";
    }

    return "";
  }, []);

  useEffect(() => {
    fetchRelease();
  }, []);

  const fetchRelease = async () => {
    try {
      const response = await axiosInstance.get(
        isOmnibus
          ? `${apiUrl}/sift/release`
          : `${apiUrl}/downloads/releaseVersion`
      );
      const payload = typeof response?.data === 'object'
        ? response.data
        : JSON.parse(response?.data);
      setRelease(encodeURIComponent(payload.latest));
    } catch (error: any) {
      console.log(error);
    }
  };

  const fetchFiles = async () => {
    try {
      const response = await axiosInstance.get(getDataSource(release, apiUrl));
      const newFiles = response?.data;
      //format
      // detected file based on userAgent is in file name or not, checksum file, rest of the files
      const detectedFile = newFiles.filter((file: any) =>
        file?.fileName?.toLowerCase().includes(userAgent)
      );
      
      const checksumFile = newFiles.filter((file: any) =>
        file?.fileName?.toLowerCase().includes("checksum")
      );
      const restOfFiles = newFiles.filter(
        (file: any) =>
          // !file?.fileName?.toLowerCase().includes(userAgent) &&
          !file?.fileName?.toLowerCase().includes("checksum")
      );
      
      setFiles({
        detectedFile: detectedFile[0],
        checksumFile: checksumFile[0],
        restOfFiles: restOfFiles,
      });
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (release) {
      fetchFiles();
    }
  }, [release]);

  return (
    <Box>
      <Typography variant="h2">SIFT</Typography>
      <Typography variant="h5">Aptori's cross-platform CLI tool</Typography>
      {release && (
        <Typography variant="body1">
          Latest release {decodeURIComponent(release)?.split("/")?.[1]}
        </Typography>
      )}

      

      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "1em",
          mt: 6,
        }}
      >
        {files?.detectedFile && (
          <Button variant="contained">
            {getConfigs().isOmnibus ? (
              <FileDonwloadOmnibus
                file={files?.detectedFile}
                setDownloadedFile={setDownloadedFile}
              />
            ) : (
              <FileDownload
                highlighted
                key={files?.detectedFile?.fileKey}
                file={{
                  ...files?.detectedFile,
                  fileName: getPlatformName(files?.detectedFile?.platform),
                }}
                setDownloadedFile={setDownloadedFile}
              />
            )}
          </Button>
        )}
      </Box> */}

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          gap: "1em",
          mt: "1em",
        }}
      >
        {isEmpty(files) && (
          <Box>
            <CircularProgress disableShrink />
          </Box>
        )}
        {files?.restOfFiles?.map((file: any) => (
          <Box>
            <Button variant="outlined">
              {isOmnibus ? (
                <FileDonwloadOmnibus
                  file={file}
                  setDownloadedFile={setDownloadedFile}
                />
              ) : (
                <FileDownload
                  key={file?.fileKey}
                  file={{ ...file, fileName: getPlatformName(file?.platform) }}
                  setDownloadedFile={setDownloadedFile}
                />
              )}
            </Button>
          </Box>
        ))}
        {/* {files?.detectedFile?.instructions?.trim() && (
          <Alert sx={{ mt: 4 }} severity="info" icon={false}>
            <Typography variant="h6">
              Usage Instructions for SIFT -{" "}
              {getPlatformName(files?.detectedFile?.platform)}
            </Typography>
            <ReactMarkdown>
              {base64ToString(files?.detectedFile?.instructions?.trim())}
            </ReactMarkdown>
          </Alert>
        )} */}
        {/**
         * Two columns in one row with a title and description
         */}
        <Box>
          <Grid container sx={{ mx: "auto", mt: 4 }} md={10} columnSpacing={4}>
            <Grid item xs={12} md={6}>
              <Typography variant="h5">What is SIFT?</Typography>
              <Typography variant="body1">
              Sift is a cross-platform CLI tool for stateful intelligent fuzz testing. Sift runs in your CI pipeline or the development environment of your choice (Mac, Linux, Windows) to autonomously analyze an API for defects.

              </Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h5">
                API Testing with SIFT
              </Typography>
              <Typography variant="body1">
              Given an API definition and URL, sift autonomously generates and executes requests to an API to discover security and functional faults. Sift leverages a semantic graph to ensure the tests are effective, extensive, and efficient.


              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

const FileDonwloadOmnibus: React.FC<any> = ({
  file,
  setDownloadedFile,
  highlighted = false,
}) => {
  return (
    <Box
      onClick={() => {
        setDownloadedFile(file);
        window.open(file.fileKey, "_self");
      }}
      sx={{ minWidth: highlighted ? "15em" : "5em" }}
    >
      {highlighted && (
        <Typography variant="h6" sx={{ mt: 1 }}>
          Download SIFT
        </Typography>
      )}
      <IconButton disableRipple>
        <Download sx={{ color: highlighted ? "white" : "primary.main" }} />
      </IconButton>
      <Typography variant="body2" sx={{display: "inline"}}>{file?.fileName}</Typography>
    </Box>
  );
};

const FileDownload: React.FC<any> = ({
  file,
  setDownloadedFile,
  highlighted = false,
}) => {
  const [isDownloading, setIsDownloading] = useState(false);

  const { setMessage, clearMessage } = useAlertState(
    (state) => state
  ) as IAlertState;

  const downloadFile = async (fileObject: any) => {
    setIsDownloading(true);
    try {
      const element = document.createElement("a");
      const response = await axiosInstance.get(
        `${getConfigs().baseApiUrl}/downloads/get/${encodeURIComponent(
          fileObject?.fileKey
        )}`,
        {
          responseType: "blob",
          timeout: 30000,
        }
      );
      const blob = response?.data;
      const file = new Blob([blob], {
        type: "application/octet-stream",
      });
      element.href = URL.createObjectURL(file);
      element.download = basename(fileObject?.fileKey);
      document.body.appendChild(element);
      element.click();
      setDownloadedFile(fileObject);
    } catch (error: any) {
      clearMessage();
      setMessage({ title: "File download failed", type: "error" });
    }
    setIsDownloading(false);
  };

  return (
    <Box
      onClick={() => downloadFile(file)}
      sx={{ minWidth: highlighted ? "15em" : "5em" }}
    >
      {highlighted && (
        <Typography variant="h6" sx={{ mt: 1 }}>
          Download SIFT
        </Typography>
      )}
      <IconButton disableRipple>
        {isDownloading ? (
          <CircularProgress disableShrink size="1em" sx={{ color: highlighted ? "white" : "primary.main" }}  />
        ) : (
          <Download sx={{ color: highlighted ? "white" : "primary.main" }} />
        )}
      </IconButton>
      <Typography variant="body2" sx={{display: "inline"}}>{file?.fileName}</Typography>
    </Box>
  );
};

const getPlatformName = (platform: string) => {
  switch (platform) {
    case "windows":
      return "Windows";
    case "linux":
      return "Linux";
    case "macos_amd64":
      return "MacOS (Intel)";
    case "macos_arm64":
      return "MacOS (Apple Silicon)";
    default:
      return platform;
  }
};

export default withRouter(DownloadList);
