import { Close } from "@mui/icons-material";
import { Box, Container, Paper } from "@mui/material";
import { isEmpty } from "lodash";
import { FC, useEffect, useState } from "react";
import { API_SLUGS } from "../../constants";
import { axiosInstance } from "../../services/axios";
import RevisionsTable from "./Revisions/table";
var format = require("string-template");

interface IRevisionModal {
  assetId: string;
  onClose: () => void;
}

const RevisionModal: FC<IRevisionModal> = ({ assetId, onClose }) => {
  const [api, setApi] = useState({} as any);

  useEffect(() => {
    fetchApi();
  }, []);

  const fetchApi = async () => {
    try {
      let apiResponse = await axiosInstance.get(
        format(API_SLUGS.GET_API, {
          //@ts-ignore
          uuid: assetId,
        })
      );
      setApi(apiResponse.data);
    } catch (error: any) { }
  };

  return (
    <Box
      sx={{
        minHeight: "100%",
        p: 3,
      }}
    >
      <Container maxWidth="md">
        <Paper elevation={12} sx={{ p: 3 }}>
          <Box>
            <Close className="hand" onClick={onClose} />
          </Box>
          <Box sx={{ padding: "auto", minHeight: "60vh", overflow: "scroll" }}>
            {!isEmpty(api) ? (
              <RevisionsTable assetId={api?.id} />
            ) : null}
          </Box>
        </Paper>
      </Container>
    </Box>
  );
};

export default RevisionModal;
