import { Check } from "@mui/icons-material";
import { Button, Chip, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { find, isEmpty } from "lodash";
import { FC, useEffect, useMemo, useState } from "react";
import useFetch from "../../hooks/http/useFetch";
import useFetchOperations from "../../hooks/process/useFetchOperations";
import { axiosInstance } from "../../services/axios";
import FileComponent, { IFile } from "../assets/AddApi/FileComponent";
import { GENERATOR_OPTIONS } from "./OperationView";
import { operationFunctions } from "./operationFunctions";
import { flattenIOTree } from "./treeFunctions";

const PostmanOperations: FC<any> = ({ assetId }) => {
  const { operations } = useFetchOperations("api", assetId);
  const [existingGenerators, setExistingGenerators] = useState<any>({});
  const [generators, setGenerators] = useState<any>({});

  const operationsWithFlatInputs = useMemo(
    () =>
      operations?.map((o: any) => ({
        ...o,
        flattenedInputs: flattenIOTree(o?.inputs),
      })),
    [operations]
  );

  const filteredOperationsByOperationId = useMemo(
    () =>
      operationsWithFlatInputs?.filter(
        (o: any) =>
          Object.keys(generators).includes(o?.operationID) &&
          //@ts-ignore
          !isEmpty(generators[o?.operationID])
      ),
    [operationsWithFlatInputs, generators]
  );
  const { data: apiData } = useFetch(`/apis/${assetId}`);
  const [formValues, setFormValues] = useState<any>({
    data: [],
  });
  const [uploaded, setUploaded] = useState(false);

  const handleUpload = async () => {
    const file = formValues?.data[0]?.uri.split(",")[1];
    try {
      const response = await axiosInstance.post(`/convert`, {
        input: file,
        assetId: assetId,
        inputType: "postman",
        apiKind: apiData.type,
      });
      setGenerators(response.data);
      setUploaded(true);
    } catch (error) { }
  };
  useEffect(() => {
    getGenerators();
  }, [assetId]);

  const getGenerators = async () => {
    let newGenerators = await operationFunctions.getAssignedGenerators(assetId!);
    setExistingGenerators(newGenerators);
  };

  return (
    <Box sx={{ display: "block", width: "100%" }}>
      {!uploaded && (
        <Box sx={{ pt: 2 }}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Upload Postman Collection
          </Typography>
          <FileComponent
            files={(formValues?.data as IFile[]) || []}
            onChangeHandler={(files: any) =>
              setFormValues({ ...formValues, data: files })
            }
          />
          <Button onClick={handleUpload} disabled={isEmpty(formValues?.data)}>
            Upload
          </Button>
        </Box>
      )}
      {uploaded &&
        filteredOperationsByOperationId?.map((o: any) => {
          const inputs = o?.flattenedInputs?.filter((i: any) => {
            return Object.keys(generators[o?.operationID]).includes(
              i?.parentPath
            );
          });
          return (
            <Box>
              <Typography variant="h6">
                <Chip
                  variant="outlined"
                  color="success"
                  size="small"
                  sx={{ mr: 1 }}
                  label={o?.method}
                ></Chip>
                {o?.path}
              </Typography>
              <p>
                {inputs?.map((i: any) => (
                  <InputGenerator
                    existingGenerator={
                      existingGenerators?.[assetId]?.[o?.operationID]?.[i?.parentPath]
                    }
                    assetId={assetId}
                    operation={o}
                    input={i}
                    generator={generators[o?.operationID][i?.parentPath]}
                  />
                ))}
              </p>
            </Box>
          );
        })}
    </Box>
  );
};

const InputGenerator: FC<any> = ({
  assetId,
  operation,
  input,
  generator,
  existingGenerator,
}) => {
  const [saved, setSaved] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    setIsSaving(true);
    try {
      if (isEmpty(existingGenerator)) {
        await operationFunctions.postGenerator(assetId, operation?.operationID, {
          ...generator,
          keyPath: input?.parentPath,
        });
      } else {
        await operationFunctions.patchGenerator(
          assetId!,
          operation?.operationID,
          { ...generator, keyPath: input?.parentPath, id: existingGenerator?.id }
        );
      }
      setIsSaving(false);
      setSaved(true);
    } catch (error) { }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        maxWidth: "40em",
        ml: 2,
        mb: 1,
        p: 1,
        borderRadius: 1,
        backgroundColor: "background.default",
      }}
    >
      <Box>
        Field: <strong>{input?.parentPath}</strong>
        <Box sx={{ ml: 2 }}>
          <Typography variant="body2" color="text.secondary">
            {
              find(GENERATOR_OPTIONS[input?.type] as any[], {
                key: generator?.type,
              })?.label
            }
          </Typography>
          {/** show key value pair from generator */}
          {Object.keys(generator?.data)?.map((key) => (
            <Box sx={{ display: "flex", justifyContent: "start" }}>
              <Typography variant="body2" color="text.secondary" sx={{ mr: 2 }}>
                {key}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {typeof generator?.data[key] === "object"
                  ? JSON.stringify(generator?.data[key])
                  : generator?.data[key]}
              </Typography>
            </Box>
          ))}
        </Box>
      </Box>

      {saved ? (
        <Chip label="Done" icon={<Check />} size="small" color="success" />
      ) : (
        <Chip
          color="primary"
          label={isSaving ? "Saving..." : "Use this"}
          disabled={isSaving}
          size="small"
          onClick={handleSave}
        ></Chip>
      )}
    </Box>
  );
};

export default PostmanOperations;
