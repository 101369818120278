import {
  AccountTree,
  Article,
  AutoAwesome,
  Download,
  Key,
  PieChart
} from "@mui/icons-material";
import { getConfigs } from "../../../configs";
import { ChartSquareBar } from "../../../icons/chart-square-bar";
import { DocumentText } from "../../../icons/document-text";
import { ExclamationCircle } from "../../../icons/exclamation-circle";
import { Users } from "../../../icons/users";
import { PermissionTargets, PermissionTypes } from "../../../store/userState";

export interface INavBarItem {
  title: string;
  path: string;
  info?: string;
  icon: JSX.Element;
  isNotRelativePath?: boolean;
  queryParams?: () => void;
  experimental?: boolean;
  hideOmnibus?: boolean;
  blacklistCheckParams?: { type: PermissionTypes; target: PermissionTargets };
}
export interface INavBarData {
  title: string;
  experimental?: boolean;
  items: INavBarItem[];
}

const NavBarData: INavBarData[] = [
  {
    title: "General",
    experimental: false,
    items: [
      {
        title: "Overview",
        path: "/",
        info: "",
        icon: <PieChart fontSize="small" />,
        experimental: false,
      },

      {
        title: "Projects",
        path: "/projects/",
        info: "",
        icon: <AccountTree fontSize="small" />,
        experimental: false,
      },
    ],
  },
  {
    title: "Analysis",
    experimental: false,
    items: [
      {
        title: "Issues",
        path: "/issues",
        info: "",
        icon: <ExclamationCircle fontSize="small" />,
        experimental: false,
      },
      {
        title: "Runs",
        path: "/runs",
        info: "",
        icon: <ChartSquareBar fontSize="small" />,
        experimental: false,
      },

    ],
  },
  {
    title: "Manage",
    experimental: false,
    items: [
      
      {
        title: "Configurations",
        path: "/configurations",
        icon: <DocumentText fontSize="small" />,
        experimental: false,
        blacklistCheckParams: {
          type: PermissionTypes.read,
          target: PermissionTargets.job,
        }
      },
      {
        title: "Jobs",
        path: "/jobs",
        icon: <DocumentText fontSize="small" />,
        experimental: true,
        hideOmnibus: true,
        blacklistCheckParams: {
          type: PermissionTypes.read,
          target: PermissionTargets.job,
        }
      },
      {
        title: "Automations",
        path: "/automations",
        icon: <AutoAwesome fontSize="small" />,
        experimental: false,
      },
    ],
  },
  // {
  //   title: "Tools",
  //   experimental: false,
  //   items: [
  //     {
  //       title: "Convert HAR",
  //       path: "/har-converter",
  //       icon: <ChangeCircle fontSize="small" />,
  //       experimental: false,
  //     },
  //     {
  //       title: "Convert Postman",
  //       path: "/postman-converter",
  //       icon: <ChangeCircle fontSize="small" />,
  //       experimental: true,
  //     },
  //     {
  //       title: "Merge OpenAPI",
  //       path: "/openapi-merger",
  //       icon: <CallMerge fontSize="small" />,
  //       experimental: false,
  //     },
  //   ],
  // },
  {
    title: "References",
    experimental: false,
    items: [
      {
        title: "Documentation",
        isNotRelativePath: true,
        path: `${getConfigs().swrlDocsUrl}`,
        icon: <Article fontSize="small" />,
        experimental: false,
      },
      {
        title: "Downloads",
        path: "/downloads",
        icon: <Download fontSize="small" />,
        experimental: false,
        hideOmnibus: false,
      },
    ],
  },
];

export default NavBarData;
