import { Button, Card, Grid, Link, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { isEmpty } from "lodash";
import { FC } from "react";
import ReactMarkdown from "react-markdown";
import { RouteComponentProps, withRouter } from "react-router-dom";
import useFetch from "../../../../hooks/http/useFetch";
import { ExternalLink } from "../../../../icons/external-link";
import ModalWrapper from "../../../common/modal/wrapper";
import Loaders from "../../../dashboard/Loaders";
import ActionButton from "../shared/button";
import { IssueData, IssueFixButton } from "./IssueView";
import SequenceStep from "./SequenceStep";

interface IIssuePreviewPopup extends RouteComponentProps {

  finding: any;
  trigger?: React.ReactNode | undefined;
}
const IssuePreviewPopup: FC<IIssuePreviewPopup> = ({
  history,
  finding,
  trigger = undefined,
}) => {


  return (
    <>
      <ModalWrapper
        maxWidth="md"
        title={""}
        trigger={
          trigger || (
            <Button variant="outlined">Issue #{finding?.issueNumber}</Button>
          )
        }
        child={<IssueContent finding={finding} history={history} />}
      />
    </>
  );
};

const IssueContent: FC<any> = ({ finding, history }) => {
  const { data: issue } = useFetch(`/issues/${finding?.issueId}`);
  const { data: findingDetails, loading: findingDetailsLoading } = useFetch(`/findings/${finding?.id}`);

  if (findingDetailsLoading) return <Loaders />;
  return (
    <Box sx={{ maxHeight: "80vh", overflow: "auto", mt:2 }}>
      
      {!isEmpty(issue) ? <Box sx={{ display: "flex", justifyContent: "space-between" }}>

        <Box>
          <ActionButton
            issueId={issue?.id}
            buttons="BOTH"
            state={issue?.state}
            risk={issue?.risk}

            user={issue?.assignee}
            jira={{
              url: issue?.jiraUrl,
              number: issue?.jiraNumber,
            }}
          />
        </Box>
        {issue?.id && <IssueFixButton issueId={issue?.id} />}
      </Box> : null}
      {!isEmpty(issue) && <IssueData
        id={issue?.id}
        history={history}
        findingId={finding?.id}

      />}
      <Box sx={{ display: "flex", justifyContent: "space-between" , pb:2}}>
        <Typography variant="h6" sx={{mt:2}}>
          <Link href={`/findings?issueId=${issue?.id}&findingId=${finding?.id}`} >
            <ExternalLink fontSize={"inherit"} />
            More Details
          </Link>
        </Typography>
      </Box>


      
    </Box>
  );
};

export default withRouter(IssuePreviewPopup);
