import { Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";

const IntConstant: React.FC<any> = ({ data, onSubmit, onChange, allowSave = false }) => {
  const [hasChanged, setHasChanged] = useState(false);
  const [formValues, setFormValues] = useState({ ...data } as any);

  useEffect(() => {
    setFormValues({ ...data });
  }, [data]);

  const handleSubmit = () => {
    onSubmit();
    setHasChanged(false);
  };

  const handleChange = (value: any, type: "num") => {
    let newFormValues = { ...formValues, [type]: value };
    setFormValues(newFormValues);
    onChange(newFormValues);
    setHasChanged(true);
  };

  return (
    <Box sx={{ display: "inline", width: '100%' }}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <TextField
            fullWidth
            label={"Number"}
            size="small"
            sx={{ mr: 1, minWidth: "10em" }}
            margin="normal"
            name="email"
            type="number"
            value={formValues?.num}
            variant="outlined"
            onChange={(e: any) => {
              handleChange(e.target.value, "num");
            }}
            inputProps={{ "data-testid": "email" }}
          />

          <Box sx={{ mt: 1 }}>
            <Button
              color="primary"
              fullWidth
              type="submit"
              size="small"
              disabled={!allowSave && (!hasChanged || formValues?.num === undefined)}
              variant="contained"
            >
              Save
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  );
};
export default IntConstant;
